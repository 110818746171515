import { FC } from 'react';
import { SubTitle, TitleH1 } from 'theme';

import { Container, StyledPlans } from './Header-styles';

export const Header: FC = () => {
    return (
        <Container>
            <TitleH1>Find Your Perfect Plan</TitleH1>
            <SubTitle>100% satisfaction guaranteed.</SubTitle>
            <StyledPlans />
        </Container>
    );
};
