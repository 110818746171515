import './style.scss';

import { Loader, Page } from 'components';
import { Col, Container, Row } from 'react-bootstrap';

import content from 'content.json';
import { MessagesComponent } from './messages-component';
import { useGetMessagesQuery } from 'store/inbox-service/inbox-api-slice';

const { inbox: texts } = content;

export const Inbox = () => {
    const { data: messages = [], isFetching } = useGetMessagesQuery();

    return (
        <Page footer={false}>
            {isFetching && <Loader />}
            <Container className={`inbox`} fluid>
                <Row>
                    <Col className="title">{texts.title}</Col>
                </Row>
                <Row>
                    <MessagesComponent messages={messages} />
                </Row>
            </Container>
        </Page>
    );
};
