import { Link } from 'react-router-dom';
import checkIcon from 'assets/check.svg';
import styled, { css } from 'styled-components';

const primaryButton = css`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 14px;
    border: 1px solid ${getColor('primaryDark')};
    background: ${getColor('primaryDark')};
    font-size: 16px;
    color: ${getColor('primaryLight')};
    border-radius: 4px;
    transition: all 0.3s;
    cursor: pointer;

    path {
        transition: all 0.3s;
    }

    @media (hover: hover) {
        &:hover {
            color: ${getColor('primaryLight')};
            background-color: ${getColor('gray700')};
            border-color: ${getColor('gray700')};

            path {
                stroke: ${getColor('primaryLight')};
                fill: ${getColor('primaryLight')};
            }
        }
    }

    &:active {
        color: ${getColor('primaryLight')};
        background-color: ${getColor('gray900')};
        border-color: ${getColor('gray900')};
    }

    &:disabled {
        background-color: ${getColor('gray200')};
        border-color: ${getColor('gray200')};
        color: ${getColor('gray400')};

        path {
            stroke: ${getColor('gray400')};
            fill: ${getColor('gray400')};
        }
    }
`;

import { getBreakpoint, getColor, getFont } from './theme';

const title = css`
    font-family: ${getFont('title')}, serif;
    font-size: 40px;
    font-weight: normal;
    letter-spacing: -1.2px;
    line-height: 140%;
    text-align: center;

    @media screen and (${getBreakpoint('mobile')}) {
        font-size: 30px;
    }
`;

const secondaryButton = css`
    ${primaryButton};

    color: ${getColor('primaryDark')};
    background: transparent;
    border: 1px solid ${getColor('gray800')};

    @media (hover: hover) {
        &:hover {
            color: ${getColor('primaryLight')};
            background-color: ${getColor('gray800')};
            border-color: ${getColor('gray800')};

            path {
                stroke: ${getColor('primaryLight')};
                fill: ${getColor('primaryLight')};
            }
        }
    }

    &:active {
        color: ${getColor('primaryLight')};
        background-color: ${getColor('gray700')};
        border-color: ${getColor('gray700')};
    }
`;

export const TitleH1 = styled.h1`
    ${title};
`;
export const TitleH2 = styled.h2`
    ${title};
`;

export const SubTitle = styled.h2`
    margin-top: 12px;
    text-align: center;
    font-size: 18px;
    line-height: 140%;
`;

export const Paragraph = styled.p`
    font-size: 18px;
    line-height: 140%;

    @media screen and (${getBreakpoint('mobile')}) {
        font-size: 16px;
    }
`;

export const PrimaryButton = styled.button`
    ${primaryButton};
`;

export const PrimaryButtonLink = styled(Link)`
    ${primaryButton};
`;

export const SecondaryButton = styled.button`
    ${secondaryButton};
`;

export const SecondaryButtonLink = styled(Link)`
    ${secondaryButton};
`;

export const ToggleButton = styled.button<{ $on: boolean }>`
    display: block;
    padding: 10px 14px;
    border: 1px solid ${getColor('primaryDark')};
    background: ${({ $on }) => getColor($on ? 'primaryDark' : 'primaryLight')};
    font-size: 18px;
    color: ${({ $on }) => getColor($on ? 'primaryLight' : 'primaryDark')};
    border-radius: 4px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;

    @media (hover: hover) {
        //
    }

    &:active {
        background-color: ${({ $on }) => getColor($on ? 'gray900' : 'gray700')};
    }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    position: relative;
    appearance: none;
    border: none;
    width: 20px;
    height: 20px;

    &:before {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        border: 1px solid ${getColor('gray500')};
        background: ${getColor('gray100')};
        border-radius: 5px;
        cursor: pointer;
        content: '';
    }

    &:checked {
        &:before {
            background: ${getColor('gray500')} url(${checkIcon}) center no-repeat;
        }
    }

    &:focus {
        outline: none;
    }
`;
