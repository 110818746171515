import styled from 'styled-components';
import * as shared from 'theme';
import { getBreakpoint, TitleH1 } from 'theme';

import { StylingButton } from 'components';

import { Section } from './styles';

export const Container = styled(Section)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding: 100px 0;

    @media screen and (${getBreakpoint('desktop')}) {
        flex-direction: column;
        gap: 64px;
        padding: 80px 0 40px 0;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        gap: 40px;
        flex-direction: column-reverse;
        padding: 24px 16px 40px 16px;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 365px;
`;

export const Title = styled(TitleH1)`
    @media screen and (${getBreakpoint('mobile')}) {
        font-size: 40px;
    }
`;

export const SubTitle = styled(shared.SubTitle)`
    margin-top: 24px;
    text-align: left;

    @media screen and (${getBreakpoint('desktop')}) {
        text-align: center;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        margin-top: 12px;
    }
`;

export const Cta = styled(StylingButton)`
    margin-top: 40px;
    width: 290px;

    @media screen and (${getBreakpoint('tablet')}) {
        margin-top: 24px;
        width: 320px;
    }
`;

export const Image = styled.img`
    width: 750px;
    height: 690px;

    @media screen and (${getBreakpoint('tablet')}) {
        width: 353px;
        height: 323px;
    }
`;
