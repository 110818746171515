import { connect } from 'react-redux';

import { toggleMobileCollapse } from 'redux/reducers/payments/actions';
import { loadCampaignFilters, loadCampaignItems } from 'redux/reducers/style/actions';
import Gifts from './Gifts';
import _Landing from './Landing';
import _MobileFilters from './MobileFilters';
import _Shopping from './Shopping';

const mapStateToProps = ({
    user: { user },
    payments: { mobileCollapse },
    style: { loading, campaignFilters, campaignItems, nextPage }
}) => ({ loading, user, campaignFilters, campaignItems, mobileCollapse, nextPage });

const mapDispatchToProps = (dispatch) => ({
    loadCampaignItems: (filters) => dispatch(loadCampaignItems(filters)),
    loadCampaignFilters: () => dispatch(loadCampaignFilters()),
    toggleMobileCollapse: (content) => dispatch(toggleMobileCollapse(content))
});

export default Gifts;
export const Landing = connect(mapStateToProps, mapDispatchToProps)(_Landing);
export const Shopping = connect(mapStateToProps, mapDispatchToProps)(_Shopping);
export const MobileFilters = connect(mapStateToProps, mapDispatchToProps)(_MobileFilters);
