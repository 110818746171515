import './style.scss';

import { useCart, useIsMobile } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import { MP_EVENTS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getOutfitProperties } from 'services/mixpanel/utils';
import { isShopableItem } from 'services/utils/item-utils';
import { itemToProps } from 'services/utils/mixpanel-utils';

import { Lightbox, Loader, Page } from 'components';
import content from 'content.json';
import { Formatter } from 'services';
import { ItemType } from 'types/item';
import { ItemDetails } from './ItemDetails';
import { MoreItems } from './MoreItems';
import { ExpressCheckout } from 'components';
import { useGetOutfitQuery } from 'store/collections-service/collections-api-slice';
import { useModal } from 'store/ui';
import { useGetItemQuery } from 'store/style/styleApiSlice';
import { useUserId } from 'store/auth-service';

const texts = content.item;

interface PatchedItem extends ItemType {
    allPictures: string[];
}

export const Item: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { showModal } = useModal();

    const [searchParams] = useSearchParams();
    const { outfit_uuid, itemId: item_uuid } = useParams<{
        outfit_uuid: string;
        itemId: string;
    }>();
    const userId = useUserId();
    const { data: item, isFetching: isFetchingItem } = useGetItemQuery(item_uuid!, { skip: !item_uuid });
    const { data: outfit, isFetching: isFetchingOutfit } = useGetOutfitQuery(
        { outfitId: outfit_uuid!, userId },
        { skip: !outfit_uuid }
    );

    const [context, setContext] = useState('feed');
    const [mainItem, setMainItem] = useState<PatchedItem>();
    const [size, setSize] = useState<string>();
    const [lightbox, setLightbox] = useState(false);
    const [isExpressCheckoutReady, setIsExpressCheckoutReady] = useState(false);
    const sliderRef = useRef<Slider>(null);
    const moreItems = useMemo(() => outfit?.items?.filter(({ uuid }) => uuid !== item_uuid) || [], [outfit, item_uuid]);
    const isMobile = useIsMobile();
    const { addToCart, removeFromCart, ...cart } = useCart();
    const priceFormatter = (price: string) => {
        if (typeof price !== 'string') {
            price = JSON.stringify(price);
        }
        return price ? JSON.stringify(parseInt(price.replace(',', ''), 10)) : price;
    };

    const getItemSource = () => {
        const context = searchParams.get('context');
        if (context) return context;
        if (location.pathname.includes('shopping-list')) return 'shopping-list';
        return 'feed';
    };

    const isGift = searchParams.get('context') === 'gifts';
    const itemSource = getItemSource();
    const slickMove = (ref: React.RefObject<Slider>) => ref?.current?.slickGoTo(0);

    const itemCheckoutEvent = () => {
        trackEvent({
            name: MP_EVENTS.ITEM_CHECKOUT_CLICKS,
            properties: itemToProps(item!.item_uuid, itemSource, item!.brand_name)
        });
    };

    useEffect(() => {
        if (location) {
            const path = location.pathname.split('/')[1];
            setContext(path === 'item' ? '' : path);
        }
    }, [location]);

    useEffect(() => {
        if (sliderRef.current) {
            slickMove(sliderRef);
        }
    }, [mainItem, sliderRef, slickMove]);

    useEffect(() => {
        if (outfit) {
            trackEvent({
                name: MP_EVENTS.LOOK_VIEWS,
                properties: getOutfitProperties(outfit, itemSource)
            });
            if (item) {
                trackEvent({
                    name: MP_EVENTS.ITEM_VIEWS,
                    properties: itemToProps(item.item_uuid, itemSource, item.brand_name)
                });
            }
        }
    }, [item, outfit]);

    useEffect(() => {
        if (item && item.uuid) {
            setMainItem(patchItem(item));
        }
    }, [item]);

    const patchItem = (item: ItemType): PatchedItem => {
        const allPictures = [item.picture, ...(item.additional_pictures ?? [])].filter((pic) => pic);
        const retail_price = priceFormatter(
            typeof (item?.retail_price == 'string') ? item?.retail_price : item?.retail_price.toString()
        );
        return {
            ...item,
            sizes: item.sizes.filter((size) => size.length > 0) ?? [],
            retail_price,
            sale_price: priceFormatter(item.sale_price?.toString() ?? ''),
            isGift,
            allPictures,
            description: item?.description || item?.shortDescription || ''
        };
    };

    const openItem = ({
        uuid,
        item_uuid,
        unique,
        sku
    }: {
        uuid: string;
        item_uuid: string;
        unique: string;
        sku: string | string[];
    }) => {
        const itemId = uuid || item_uuid || unique || sku[0];
        const newLocation = `/${context ? `${context}/` : ''}${
            outfit_uuid ? `outfit/${outfit_uuid}/` : ''
        }item/${itemId}${location.search}`;
        navigate(newLocation);
    };

    const openLink = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const onCartClick = (item: ItemType) => {
        if (!userId) {
            showModal('Signup', {
                redirectUrl: `${location.pathname}${location.search}`,
                source: MP_VALUES.PDP_PAGE,
                element: MP_VALUES.CART_CLICK
            });
        } else {
            item.is_in_cart ? removeFromCart(item) : addToCart(item);

            if (item.uuid === mainItem?.uuid) {
                setMainItem({
                    ...mainItem,
                    is_in_cart: !mainItem.is_in_cart
                });
            }
        }
    };

    const onCheckoutClick = () => {
        const url = `${context ? `/${context}` : ''}/${
            outfit_uuid ? `outfit/${outfit_uuid}/` : ''
        }item/${mainItem?.uuid ? mainItem?.uuid : mainItem?.unique}/checkout?size=${size}`;
        userId
            ? navigate(url)
            : showModal('Signup', {
                  redirectUrl: url,
                  source: MP_VALUES.PDP_PAGE,
                  element: MP_VALUES.CHECKOUT_CLICK
              });
    };

    const onSizeChange = (e: React.FormEvent) => {
        setSize((e.target as HTMLInputElement).value);
    };

    const hasSizes = mainItem?.sizes && mainItem.sizes.length > 0;

    const showCartIcon =
        mainItem && mainItem.has_sizes_information && isShopableItem(mainItem) && !mainItem?.is_in_closet;

    const ShopAtLink = () => (
        <a href={mainItem?.buy_url} target="_blank" className="link" rel="noreferrer">
            {mainItem?.buy_url_short && texts.shop.replace('%brand%', mainItem?.buy_url_short)}
        </a>
    );

    const ShopExternal = () => (
        <Button variant="dark" onClick={() => openLink(mainItem?.buy_url ?? '')}>
            {texts.checkoutExternal.replace('%brand%', mainItem?.buy_url_short ?? '')}
        </Button>
    );

    const DescriptionContainer = () => {
        const hasGiftNotes = mainItem?.isGift && mainItem?.stylist_name && mainItem?.notes;

        return (
            <>
                {hasGiftNotes ? (
                    <div className="why">
                        <p className="title">{texts.stylist.replace('%stylist%', mainItem.stylist_name)}</p>
                        <p>{mainItem.notes}</p>
                    </div>
                ) : (
                    hasSizes && <div className="returns">{texts.returns}</div>
                )}
                <div className="description">{mainItem?.description}</div>
            </>
        );
    };

    return (
        <Page footer={false} header={!isMobile} className="item-page">
            {isFetchingItem || isFetchingOutfit ? (
                <Loader />
            ) : (
                <>
                    {lightbox && (
                        <Lightbox
                            variant="light"
                            opacity={1}
                            photos={mainItem!.allPictures}
                            selected={0}
                            closePosition="right"
                            onClose={() => setLightbox(false)}
                        />
                    )}
                    <Container>
                        <Row className="header">
                            <Col>
                                <div className="back-btn" onClick={() => navigate(-1)} />
                                <Link to="/shopping-list" className="cart-link d-flex d-sm-none">
                                    <div className="cart-icon">{cart?.itemsCount ? cart.itemsCount : ''}</div>
                                </Link>
                            </Col>
                        </Row>
                        <Row className="item">
                            <Col xs={12} className="item-details d-block d-sm-none">
                                <Container fluid>{<ItemDetails item={mainItem} />}</Container>
                            </Col>
                            <Col xs={12} sm={6}>
                                {showCartIcon && (
                                    <div
                                        className={`cart-icon ${mainItem?.is_in_cart ? 'added' : 'add'}`}
                                        onClick={() => onCartClick(mainItem)}
                                    />
                                )}
                                {(mainItem?.uuid || mainItem?.unique) && (
                                    <Slider
                                        className={`item-carousel ${
                                            mainItem.additional_pictures && mainItem.additional_pictures.length > 0
                                                ? 'with-dots'
                                                : ''
                                        }`}
                                        ref={sliderRef}
                                        dots={isMobile}
                                        arrows={!isMobile}
                                        infinite={false}
                                        speed={500}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        autoplay={false}
                                        adaptiveHeight={true}
                                    >
                                        {mainItem.allPictures ? (
                                            mainItem.allPictures.map((picture) => (
                                                <Image
                                                    key={picture}
                                                    src={picture}
                                                    onClick={() => !isMobile && setLightbox(true)}
                                                />
                                            ))
                                        ) : (
                                            <Image
                                                key={mainItem.picture}
                                                src={mainItem.picture}
                                                onClick={() => setLightbox(true)}
                                            />
                                        )}
                                    </Slider>
                                )}
                                <div className="desktop-container">
                                    <DescriptionContainer />
                                </div>
                            </Col>
                            <Col xs={12} sm={6} className="item-details">
                                <Container fluid>
                                    <div className="d-none d-sm-block">{<ItemDetails item={mainItem} />}</div>
                                    <div>
                                        {mainItem?.has_sizes_information ? (
                                            <>
                                                {mainItem?.color && (
                                                    <div className="color">
                                                        {texts.color}: <span>{mainItem.color}</span>
                                                    </div>
                                                )}

                                                <div className="sizes">
                                                    <Form.Control
                                                        as="select"
                                                        value={size || texts.selectSize}
                                                        onChange={(e) => onSizeChange(e)}
                                                    >
                                                        <option key="default" disabled>
                                                            {texts.selectSize}
                                                        </option>
                                                        {mainItem.sizes.map((size) => (
                                                            <option key={size} value={size}>
                                                                {size}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </div>

                                                <div className="btns">
                                                    <Button
                                                        variant="dark"
                                                        disabled={!size}
                                                        onClick={() => {
                                                            onCheckoutClick();
                                                            itemCheckoutEvent();
                                                        }}
                                                    >
                                                        {texts.checkout}
                                                    </Button>
                                                    {mainItem.price ? (
                                                        <>
                                                            {isExpressCheckoutReady && <div className="separator" />}
                                                            <ExpressCheckout
                                                                item={mainItem}
                                                                itemSource={itemSource}
                                                                lookId={outfit_uuid}
                                                                itemSize={size}
                                                                onReady={() => setIsExpressCheckoutReady(true)}
                                                            />
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <p className="out-of-stock d-block">
                                                {mainItem?.is_shoppable && texts.outOfStock}
                                            </p>
                                        )}

                                        <div className="d-none d-sm-block">
                                            {hasSizes ? (
                                                <ShopAtLink />
                                            ) : mainItem?.isGift ? (
                                                <p className="out-of-stock">{texts.outOfStock}</p>
                                            ) : (
                                                mainItem?.buy_url_short && <ShopExternal />
                                            )}
                                        </div>
                                    </div>
                                    {mainItem?.isGift && mainItem?.tags && (
                                        <div className="tags">
                                            {mainItem?.tags.map((filter) => (
                                                <Badge variant="dark" key={filter}>
                                                    {filter}
                                                </Badge>
                                            ))}
                                        </div>
                                    )}
                                </Container>
                            </Col>
                            <Col xs={12}>
                                <div className="mobile-container">
                                    <DescriptionContainer />
                                </div>

                                <div className="d-block d-sm-none">
                                    {hasSizes ? (
                                        <ShopAtLink />
                                    ) : mainItem?.isGift ? (
                                        <p className="out-of-stock d-none d-sm-block">{texts.outOfStock}</p>
                                    ) : (
                                        mainItem?.buy_url_short && <ShopExternal />
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {!!moreItems.length && <MoreItems items={moreItems} onClick={openItem} onCart={onCartClick} />}
                    </Container>
                    <div className="disclosure">{texts.disclosure}</div>
                </>
            )}
        </Page>
    );
};
