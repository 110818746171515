import './style.scss';

import { FC, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { useToast } from 'store/ui';

export const Toast: FC = connect()(() => {
    const {
        data: { content, onUndo },
        isVisible,
        hideToast
    } = useToast();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const onAnimationEnd = () => {
        !isVisible && hideToast();
    };

    const pause = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
    }, []);

    const unpause = useCallback(() => {
        timeout.current = setTimeout(hideToast, 3500);
    }, [hideToast]);

    useEffect(() => {
        if (isVisible) {
            unpause();

            return pause;
        }
    }, [isVisible, content, pause, unpause]);

    if (!content) {
        return <></>;
    }

    return (
        <div
            className={`not-bootstrap-toast ${isVisible ? 'visible' : ''}`}
            onMouseEnter={pause}
            onMouseLeave={unpause}
            onAnimationEnd={onAnimationEnd}
        >
            <div className="content">{content}</div>
            {onUndo && (
                <div className="undo" onClick={onUndo}>
                    Undo
                </div>
            )}
        </div>
    );
});
