import './style.scss';

import { Modal } from 'react-bootstrap';
import { ReviewBody } from 'components/Review';
import { useModal } from 'store/ui';
import { ReviewItem } from 'types/utils';

interface ReviewData {
    review: ReviewItem;
}

export const ReadReview = () => {
    const { data } = useModal<ReviewData>();

    return (
        <span className="reviewReader-modal">
            <Modal.Header closeButton />
            <Modal.Body>{data && <ReviewBody review={data.review} type={'reviewModal'} />}</Modal.Body>
        </span>
    );
};
