import styled from 'styled-components';
import { getBreakpoint, getColor, getFont } from 'theme';

import { StylingButton } from 'components';

import { Section } from './styles';

export const Container = styled(Section)`
    gap: 48px;
    padding: 80px 0;
    background-color: ${getColor('beige')};

    @media screen and (${getBreakpoint('desktop')}) {
        padding: 40px;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        padding: 40px 16px;
    }
`;

export const Title = styled.h1`
    font-family: ${getFont('primary')};
    font-size: 20px;
`;

export const CrossFadeContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 1140px;

    @media screen and (${getBreakpoint('desktop')}) {
        max-width: 890px;
    }

    @media screen and (${getBreakpoint('mobile')}) {
        height: 170px;
    }

    @media screen and (${getBreakpoint('smallMobile')}) {
        height: 252px;
    }
`;

export const TestimonialItem = styled.div<{ $isShowing: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: ${({ $isShowing }) => ($isShowing ? 'static' : 'absolute')};
    opacity: ${({ $isShowing }) => ($isShowing ? 1 : 0)};
    left: 0;
    top: 0;
    transition: opacity 1s;
`;

export const Quote = styled.div`
    font-size: 24px;
    text-align: center;
    white-space: pre-wrap;
    line-height: 40px;

    @media screen and (${getBreakpoint('tablet')}) {
        line-height: 140%;
    }
`;

export const Name = styled.div`
    font-family: ${getFont('title')};
    font-size: 30px;
    margin-top: 24px;
`;

export const Cta = styled(StylingButton)`
    width: 335px;

    @media screen and (${getBreakpoint('tablet')}) {
        display: none;
    }
`;
