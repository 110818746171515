import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getBreakpoint, getColor } from 'theme';

import star from '../assets/star.svg';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 440px;
    height: 300px;
    padding: 24px;
    margin: 0 12px;
    border: 1px solid ${getColor('darkBeige')};
    border-radius: 4px;
    background: ${getColor('primaryLight')};

    @media screen and (${getBreakpoint('smallMobile')}) {
        width: calc(100dvw - 90px);
        height: 282px;
        min-width: 335px;
        font-size: 14px;
    }
`;

export const ReviewHeader = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:last-child {
            flex-shrink: 0;
            align-items: end;
        }
    }
`;

export const ClientName = styled.div`
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.24px;
    margin-bottom: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const StylistName = styled(Link)`
    text-decoration: underline;
`;

export const Date = styled.div`
    color: ${getColor('gray600')};
`;

export const Rating = styled.div<{ $rating: string }>`
    background: url(${star}) left center repeat-x;
    height: 16px;
    width: calc(21px * ${({ $rating }) => $rating});
`;

export const ReviewBody = styled.div`
    line-height: 140%;
    max-height: 88px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    overflow: hidden;
`;

export const ContinueReadingButton = styled.button`
    width: fit-content;
    border: none;
    padding: 0;
    background: none;
    text-decoration: underline;
    font-weight: 500;
`;
