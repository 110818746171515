import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Tracking } from 'services';
import { giftPlans } from '../consts';

const gifts = [
    {
        label: '$500',
        price: 500
    },
    {
        label: '$1,000',
        price: 1000
    }
];

export const Details = ({ giftCard, setGiftCard, errors, setErrors, onSubmit }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [custom, setCustom] = useState(false);
    const customInputRef = useRef(null);
    const plan = searchParams.get('plan');
    const { amount, additionalAmount = '', recipientName, recipientEmail, senderName, senderEmail } = giftCard;

    const updateGiftCard = useCallback(
        (data) => {
            setGiftCard((giftCard) => ({ ...giftCard, ...data }));
            setErrors({});
        },
        [setGiftCard, setErrors]
    );

    const onShoppingCreditClick = (price) => {
        setCustom(false);

        updateGiftCard({
            additionalAmount: !custom && price === additionalAmount ? '' : price
        });
    };

    const onCustomAmountChange = ({ target: { value } }) => {
        const additionalAmount = Math.abs(parseInt(value)) || '';

        updateGiftCard({ additionalAmount });
    };

    const onPackageSelect = (packageName) => {
        Tracking.tag({
            event: 'eec.add',
            ecommerce: {
                add: {
                    actionField: { list: 'Shopping cart' },
                    products: [
                        {
                            name: 'Gift Card',
                            category: '/category/gift-card/',
                            variant: `Gift Card - ${amount}`,
                            quantity: 1,
                            dimension3: 'Ecommerce'
                        }
                    ]
                }
            }
        });

        setSearchParams(
            (params) => {
                params.set('plan', packageName);
                return params;
            },
            { replace: true }
        );
    };

    useEffect(() => {
        if (custom && customInputRef.current) {
            customInputRef.current.focus();
        }
    }, [custom]);

    return (
        <Container className="details" fluid>
            <Row>
                <Col>
                    <p className="title">Choose an Amount</p>
                </Col>
            </Row>
            <Row className="amounts">
                {Object.keys(giftPlans).map((pkg) => (
                    <Col key={pkg}>
                        <Button
                            variant="light"
                            className={plan === pkg ? 'selected' : ''}
                            onClick={() => onPackageSelect(pkg)}
                        >
                            <span>${giftPlans[pkg].price}</span>
                            <label>{giftPlans[pkg].label}</label>
                        </Button>
                    </Col>
                ))}
            </Row>

            <Row>
                <Col>
                    <p className="title">Spoil your loved ones with shopping credit (optional)</p>
                </Col>
            </Row>
            <Row className="amounts">
                {gifts.map(({ price, label }) => (
                    <Col key={price}>
                        <Button
                            variant="light"
                            className={!custom && price === additionalAmount ? 'selected' : ''}
                            onClick={() => onShoppingCreditClick(price)}
                        >
                            {label}
                        </Button>
                    </Col>
                ))}
                <Col className="custom">
                    {custom ? (
                        <InputGroup className="selected">
                            <Form.Control
                                as="input"
                                ref={customInputRef}
                                onChange={onCustomAmountChange}
                                value={additionalAmount}
                            />
                        </InputGroup>
                    ) : (
                        <Button variant="light" onClick={() => setCustom(true)}>
                            Custom
                        </Button>
                    )}
                </Col>
            </Row>

            <Row>
                <Container className="input-fields" fluid>
                    <Row>
                        <Col>
                            <Form.Label>To:</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="text"
                                value={recipientName || ''}
                                placeholder="Recipient Name"
                                onChange={(e) => updateGiftCard({ recipientName: e.target.value })}
                            />
                            {errors?.recipientName && (
                                <p className="error">{errors.recipientName.name || errors.recipientName.lastname}</p>
                            )}
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="email"
                                value={recipientEmail || ''}
                                placeholder="Recipient Email"
                                onChange={(e) => updateGiftCard({ recipientEmail: e.target.value })}
                            />
                            {errors?.recipientEmail && <p className="error">{errors.recipientEmail.email}</p>}
                        </Col>
                    </Row>
                </Container>
            </Row>

            <Row>
                <Container className="input-fields" fluid>
                    <Row>
                        <Col>
                            <Form.Label>From:</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="text"
                                value={senderName || ''}
                                placeholder="Sender Name"
                                onChange={(e) => {
                                    updateGiftCard({ senderName: e.target.value });
                                }}
                            />
                            {errors?.senderName && (
                                <p className="error">{errors.senderName.name || errors.senderName.lastname}</p>
                            )}
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="email"
                                value={senderEmail || ''}
                                placeholder="Sender Email"
                                onChange={(e) => updateGiftCard({ senderEmail: e.target.value })}
                            />
                            {errors?.senderEmail && <p className="error">{errors.senderEmail.email}</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control
                                as="textarea"
                                placeholder="Write a personal message (optional)"
                                onChange={(e) => updateGiftCard({ message: e.target.value })}
                            />
                            <p className="comment">We’ll include it on the email to your recipient</p>
                        </Col>
                    </Row>
                    <Row className="d-none d-sm-flex">
                        <Col>
                            <Button variant="dark" onClick={onSubmit}>
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    );
};
