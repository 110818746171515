import styled from 'styled-components';
import { getBreakpoint, getColor, getFont, Paragraph } from 'theme';

import { Section } from './styles';

export const Container = styled(Section)`
    padding: 125px 0;
    background-color: ${getColor('primaryLight')};

    @media screen and (${getBreakpoint('desktop')}) {
        padding: 40px;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        padding: 40px 16px;
    }
`;

export const Features = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1520px;
    margin-top: 60px;

    @media screen and (${getBreakpoint('desktop')}) {
        gap: 32px;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        flex-direction: column;
        margin-top: 24px;
    }
`;

export const FeatureItem = styled.div`
    display: flex;
    gap: 16px;
    max-width: 452px;

    @media screen and (${getBreakpoint('desktop')}) {
        flex-direction: column;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        align-items: center;
        text-align: center;
        max-width: none;
        gap: 10px;
    }
`;

export const Image = styled.img`
    width: fit-content;
    height: 100px;
    flex-shrink: 0;
    object-fit: contain;

    @media screen and (${getBreakpoint('tablet')}) {
        height: 85px;
    }
`;

export const Title = styled.h2`
    font-family: ${getFont('primary')};
    font-weight: 500;
    font-size: 24px;
    white-space: nowrap;

    @media screen and (${getBreakpoint('tablet')}) {
        font-size: 20px;
    }
`;
export const Description = styled(Paragraph)`
    margin-top: 8px;

    @media screen and (${getBreakpoint('desktop')}) {
        max-width: 600px;
    }
`;
