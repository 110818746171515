import styled from 'styled-components';

export const Container = styled.div`
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Shadow = styled.div`
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 236px;
    height: 23px;
    background: url(//media-cf.wishi.me/react/lux-new/shadow.webp) center/contain no-repeat;
    z-index: 0;
    content: '';
`;
