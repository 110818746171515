import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import content from 'content.json';

const {
    stylist: { stylingExperience }
} = content;

export const StylingExperience: FC = () => (
    <>
        <div className="title">{stylingExperience.title}</div>
        <Row className="styling-experience">
            {stylingExperience.items.map((item) => (
                <Col key={item.title} xs={12} md={4}>
                    <p className="subtitle">{item.title}</p>
                    <p className="experience">{item.text} </p>
                </Col>
            ))}
        </Row>
    </>
);
