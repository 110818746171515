import { FC } from 'react';
import { TitleH1 } from 'theme';

import { buyWhatYouLoveFeatures } from '../content';

import { Container, Description, FeatureItem, Features, Image, Title } from './BuyWhatYouLove-styles';

export const BuyWhatYouLove: FC = () => {
    return (
        <Container>
            <TitleH1>Buy What You Love</TitleH1>
            <Features>
                {buyWhatYouLoveFeatures.map(({ title, description, imageUrl }, index) => (
                    <FeatureItem key={index}>
                        <Image src={imageUrl} alt={title} />
                        <div>
                            <Title>{title}</Title>
                            <Description>{description}</Description>
                        </div>
                    </FeatureItem>
                ))}
            </Features>
        </Container>
    );
};
