import styled from 'styled-components';
import { getBreakpoint, getColor } from 'theme';

import { Section } from '../components';

export const Container = styled(Section)`
    background-color: ${getColor('beige')};
`;

export const Features = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 50px;
`;

export const FeatureItem = styled.div`
    display: flex;
    gap: 150px;
    justify-content: center;
    align-items: center;

    @media screen and (${getBreakpoint('desktop')}) {
        gap: 40px;
    }

    @media screen and (${getBreakpoint('mobile')}) {
        flex-direction: column;
    }

    &:nth-child(odd) {
        flex-direction: row-reverse;

        @media screen and (${getBreakpoint('mobile')}) {
            flex-direction: inherit;
        }
    }
`;

export const Image = styled.img`
    max-width: 530px;
    min-width: calc(50% - 20px);
    width: 100%;
    height: 300px;
    object-fit: cover;
`;

export const Text = styled.div`
    max-width: 530px;
    min-width: calc(50% - 20px);
`;

export const Title = styled.h3`
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.28px;
`;

export const Description = styled.p`
    font-size: 18px;
    margin-top: 16px;
`;
