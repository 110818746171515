import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getChatUrl, getMatchPlansPageUrl, getStylistProfileUrl } from 'services/utils/url-utils';
import { useUserId } from 'store/auth-service';
import { useGetRecommendationQuery, useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { useModal } from 'store/ui';
import { StylistId } from 'types/user';

import { Loader } from 'components';

import {
    AdditionalInfo,
    BasicInfo,
    BottomSection,
    ButtonContainer,
    ContactButton,
    Container,
    ContinueButton,
    Description,
    Location,
    Match,
    MobileContactButton,
    Name,
    Picture,
    Rating,
    RecommendationSkeleton,
    Stats,
    StyleboardsCarousel,
    StylistPane,
    Tag,
    Tags,
    Why
} from './BestMatch-style';
import { skipToken } from '@reduxjs/toolkit/query';

interface StylistDetailsProps {
    stylistId: StylistId;
    matchPercentage: number;
}

const bookingSource = { source: 'match' };

export const BestMatch: FC<StylistDetailsProps> = ({ stylistId: bestMatchStylistId, matchPercentage }) => {
    const [searchParams] = useSearchParams();
    const stylistId = searchParams.get('stylistId') || bestMatchStylistId;
    const navigate = useNavigate();
    const { showModal } = useModal();
    const userId = useUserId()!;
    const { data: stylistData, isFetching } = useGetStylistQuery(stylistId ?? skipToken);
    const { data: personalizedRecommendation, isFetching: isFetchingRecommendation } = useGetRecommendationQuery(
        stylistId ?? skipToken
    );

    const {
        profile_picture,
        name,
        first_name,
        average_star_rating,
        reviews_count,
        location,
        looks,
        why_we_recommend: defaultRecommendation,
        match_tags,
        info,
        has_active_session
    } = stylistData || {};
    const whyWeRecommend = (!isFetchingRecommendation && personalizedRecommendation) ?? defaultRecommendation;
    const mpProps = {
        [MP_PROPS.STYLIST_NAME]: name,
        [MP_PROPS.SOURCE]: MP_VALUES.SINGLE_MATCH
    };

    const contactStylist = () => {
        showModal('Contact', { stylistId: stylistData?.uuid });
        trackEvent({
            name: MP_EVENTS.CONTACT_STYLIST_CLICK,
            properties: mpProps
        });
    };

    const trackBasicInfoClick = () => {
        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: {
                ...mpProps,
                [MP_PROPS.IS_STYLIST_MATCH]: true,
                [MP_PROPS.IS_TOP_MATCH]: true
            }
        });
    };

    const trackCarouselInteraction = () => {
        trackEvent({
            name: MP_EVENTS.LOOK_CAROUSEL_CLICK,
            properties: mpProps
        });
    };

    const trackReviewsClick = () => {
        trackEvent({
            name: MP_EVENTS.REVIEWS_CLICK,
            properties: mpProps
        });
    };

    const bookStylist = () => {
        if (has_active_session) {
            navigate(getChatUrl(stylistId, userId));
        } else {
            navigate(getMatchPlansPageUrl({ stylistId, bookingSource }));
            trackEvent({
                name: MP_EVENTS.BOOK_STYLIST,
                properties: {
                    ...mpProps,
                    ...bookingSourceToProps(bookingSource)
                }
            });
        }
    };

    return (
        <Container>
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <StylistPane>
                        <BasicInfo to={getStylistProfileUrl(stylistId)} onClick={trackBasicInfoClick}>
                            <Picture src={profile_picture} />
                            <div>
                                <Name>{name}</Name>
                                {location && <Location>{location}</Location>}
                            </div>
                        </BasicInfo>
                        <ContactButton onClick={contactStylist}>Contact {first_name}</ContactButton>
                        <Stats>
                            {matchPercentage && <Match>{matchPercentage}% Match</Match>}
                            {average_star_rating && (
                                <Rating>
                                    <span>{average_star_rating}</span>
                                    {reviews_count && (
                                        <span>
                                            (
                                            <HashLink
                                                smooth
                                                to={getStylistProfileUrl(stylistId) + '#reviews'}
                                                onClick={trackReviewsClick}
                                            >
                                                {reviews_count} Reviews
                                            </HashLink>
                                            )
                                        </span>
                                    )}
                                </Rating>
                            )}
                        </Stats>
                        <AdditionalInfo>
                            {isFetchingRecommendation ? (
                                <RecommendationSkeleton itemsCount={4} />
                            ) : whyWeRecommend ? (
                                <>
                                    <Why>Why {first_name}</Why>
                                    <Description>{whyWeRecommend}</Description>
                                </>
                            ) : null}
                            <Tags>{match_tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}</Tags>
                            <BottomSection>
                                {info && <div>{info}</div>}
                                <ButtonContainer>
                                    <ContinueButton onClick={bookStylist}>Continue with {first_name}</ContinueButton>
                                </ButtonContainer>
                            </BottomSection>
                        </AdditionalInfo>
                    </StylistPane>
                    <StyleboardsCarousel onSlideChange={trackCarouselInteraction}>
                        {looks?.data.map(({ picture }, index) => <img key={index} src={picture} alt={picture} />)}
                    </StyleboardsCarousel>
                    <MobileContactButton onClick={contactStylist}>Contact {first_name}</MobileContactButton>
                </>
            )}
        </Container>
    );
};
