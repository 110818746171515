import './style.scss';

import { Modal as BootstrapModal } from 'react-bootstrap';

import ModalContent from '../ModalContent';
import { useModal } from 'store/ui';

export const Modal = () => {
    const { type, isVisible, hideModal } = useModal();
    const Content = type && ModalContent[type];

    // animation={false} is added to prevent chrome from throwing a warning
    // Warning: findDOMNode is deprecated and will be removed in the next major release.
    return (
        <BootstrapModal show={isVisible} onHide={hideModal} className={`modal ${type}`} animation={false}>
            {Content && <Content />}
        </BootstrapModal>
    );
};
