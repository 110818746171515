import { FC } from 'react';
import { PlanType } from 'types/plans';

import { PlanDetails } from '../types';

import { BestMatchLabel, Container, Cta, LearnMore, Price, StyledAccordion, Subtitle, Title } from './PlanItem-styles';

interface PlanItemProps {
    plan: PlanDetails;
    recommendedPlan?: PlanType;
}

export const PlanItem: FC<PlanItemProps> = ({ plan, recommendedPlan }) => {
    const { planId, color, title, goal, price, learnMoreLink, features } = plan;
    return (
        <Container $color={color}>
            <Title>{title}</Title>
            <Subtitle>{goal}</Subtitle>
            {recommendedPlan === planId && <BestMatchLabel $color={color} />}
            <Price>${price}</Price>
            <Cta trackingElement="pricing details" plan={planId} goal={recommendedPlan} />
            {learnMoreLink && <LearnMore to={learnMoreLink}>Learn More</LearnMore>}
            <StyledAccordion items={features}></StyledAccordion>
        </Container>
    );
};
