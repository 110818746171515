import { FC } from 'react';

import { StickyContainer } from '../components';

import { Container, Cta, Image, SubTitle, Text, Title } from './Hero-styles';

export const Hero: FC = () => {
    return (
        <Container>
            <Text>
                <Title>The LUX Experience</Title>
                <SubTitle>The most highly curated digital styling experience in the industry.</SubTitle>
                <StickyContainer />
                <Cta trackingElement="lux hero">Get Started</Cta>
            </Text>
            <Image src="https://media-cf.wishi.me/react/lux-new/hero.webp" alt="Wishi Lux" />
        </Container>
    );
};
