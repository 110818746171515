import { FC } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loader, Outfit } from 'components';
import { useGetFavoriteLooksQuery } from 'store/user-service/user-api-slice';
import { MP_EVENTS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { isExternalItem } from 'services/utils/item-utils';
import { getFavoriteItemUrl } from 'services/utils/url-utils';
import { itemToProps } from 'services/utils/mixpanel-utils';

import content from 'content.json';
const { favorites: texts } = content;

export const FavoriteLooks: FC = () => {
    const navigate = useNavigate();
    const { data: favoriteLooks = [], isFetching: isFetchingLooks } = useGetFavoriteLooksQuery();

    const onOutfitItemClick = (item: any, lookId: string) => {
        trackEvent({
            name: MP_EVENTS.ITEM_CLICKS,
            properties: itemToProps(item.uuid, MP_VALUES.FAVORITES, item.brand_name, lookId)
        });

        if (isExternalItem(item)) {
            window.open(item.buy_url, '_blank');
        }

        navigate(getFavoriteItemUrl(lookId, item.uuid));
    };

    return (
        <span>
            {isFetchingLooks && <Loader />}
            {favoriteLooks.length > 0 ? (
                favoriteLooks.map((look) => (
                    <Outfit
                        key={look.uuid}
                        outfit={look}
                        isFavorite={true}
                        onItemSelect={(item: any) => onOutfitItemClick(item, look.uuid)}
                        isMainOutfit={true}
                        outfitSource={'favorites'}
                    />
                ))
            ) : (
                <Col className="empty">
                    <p>{texts.empty}</p>
                    <Button variant="dark" onClick={() => navigate('/feed')}>
                        {texts.browseLooks}
                    </Button>
                </Col>
            )}
        </span>
    );
};
