import { FC } from 'react';

import { YouTubeVideo } from '../YouTubeVideo';

import { Container, Description, MobileTitle, Text, Title, VideoContainer } from './OurServices-styles';

const videoId = '92ErFLJyJCk';
const title = 'Wishi Styling Experience in a Flash';

export const OurServices: FC = () => {
    return (
        <Container>
            <MobileTitle>{title}</MobileTitle>
            <VideoContainer>
                <YouTubeVideo videoId={videoId} isActive={true} />
            </VideoContainer>
            <Text>
                <Title>{title}</Title>
                <Description>
                    See how effortless personal styling can be! Take a deep fashion quiz, chat with your stylist, get
                    curated looks, and shop seamlessly. Hit play to see it in action!
                </Description>
            </Text>
        </Container>
    );
};
