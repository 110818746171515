import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';

import campaigns from 'campaigns';
import { Page, RadioButton } from 'components';
import content from 'content.json';
import { GoalsHeader } from '.';
import { urlParamToJson, getPlanstUrl } from 'services/utils/url-utils';
import { useIsLoggedIn } from 'store/auth-service';
import { useUser } from 'store/user/reducer';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';

const { goals } = content;

export const StylingSessionGoalsPage = ({ campaign, clearResult, mixpanelStore }) => {
    const [searchParams] = useSearchParams();
    const { stylistId, bookingSource } = urlParamToJson(searchParams.get('booking'));
    const isLoggedIn = useIsLoggedIn();
    const navigate = useNavigate();
    const [content, setContent] = useState();
    const [checked, setChecked] = useState(null);
    const user = useUser();

    useEffect(() => {
        klaviyoTrack(KlaviyoEvent.GOAL_VIEW);
    }, []);

    useEffect(() => {
        clearResult();
        if (stylistId && isLoggedIn)
            trackEvent({
                name: MP_EVENTS.STYLING_GOALS_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding
                }
            });
    }, [stylistId, isLoggedIn]);

    useEffect(() => {
        setContent(campaign ? campaigns[campaign].goals : goals);
    }, [campaign]);

    const onSelect = (goal, idx) => {
        klaviyoTrack(KlaviyoEvent.GOAL_CLICK, { goal });
        trackEvent({
            name: MP_EVENTS.STYLING_GOAL_SELECTION,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.SELECTED_GOAL]: idx,
                ...bookingSourceToProps(bookingSource)
            }
        });
        setTimeout(() => navigate(getPlanstUrl({ stylistId, goal, bookingSource })), 500);
    };

    const onBack = () => {
        navigate(-1);
    };

    return (
        <Page footer={false} header={false} fixedHeader={false}>
            {content && (
                <Container className="goals-select">
                    <GoalsHeader stylistId={stylistId} onBack={onBack} />
                    {user && (
                        <>
                            <div className="d-none d-sm-block">
                                <Row className="chat-row">
                                    <Col>{content.question.replace('%user%', user.first_name)}</Col>
                                </Row>
                            </div>
                            <div className="d-block d-sm-none">
                                <Row className="chat-row">
                                    <Col>{content.question.split('\n')[0].replace('%user%', user.first_name)}</Col>
                                    <Col>{content.question.split('\n')[1]}</Col>
                                </Row>
                            </div>
                        </>
                    )}
                    <Row className="answers">
                        {content.answers.map((answer, idx) => {
                            return (
                                <RadioButton
                                    type="dark"
                                    key={answer.value}
                                    id={`${answer.value}Goal`}
                                    value={answer.value}
                                    isChecked={checked === answer.value}
                                    onClick={() => {
                                        setChecked(answer.value);
                                        onSelect(answer.value, idx);
                                    }}
                                    label={answer.text}
                                    fill={false}
                                />
                            );
                        })}
                    </Row>
                </Container>
            )}
        </Page>
    );
};
