import './style.scss';

import { FC, useState } from 'react';

interface RatingObject {
    score: number;
    label: string;
}

interface RatingProps {
    onSelect: (rating: RatingObject) => void;
    ratings?: { score: number; label: string }[];
    withLabel?: boolean;
}

export const Rating: FC<RatingProps> = ({ onSelect, ratings = [], withLabel = true }) => {
    const [hovered, setHovered] = useState<RatingObject>();
    const [selected, setSelected] = useState<RatingObject>();

    const select = (rating: RatingObject) => {
        setSelected(rating);
        onSelect(rating);
    };

    const isSelected = (score: number) =>
        (selected?.score ?? 0 >= 0) ? score <= (selected?.score ?? 0) : score <= (hovered?.score ?? 0);

    return (
        <div className="rating">
            <div className="stars">
                {ratings.map((rating) => (
                    <div
                        key={rating.score}
                        className={`star ${isSelected(rating.score) ? 'on' : 'off'}`}
                        onMouseEnter={() => setHovered(rating)}
                        onMouseLeave={() => setHovered(undefined)}
                        onClick={() => select(rating)}
                    >
                        <div className="empty" />
                        <div className="filled" />
                    </div>
                ))}
            </div>
            {withLabel && <p className="label">{selected?.label || hovered?.label}</p>}
        </div>
    );
};
