import { FC } from 'react';
import { useGetLatestReviewsQuery } from 'store/stylist-service/stylist-api-slice';
import { TitleH1 } from 'theme';

import { ReviewItem } from '../components';

import { Container, ReviewsCarousel } from './Reviews-styles';

export const Reviews: FC = () => {
    const { data: latestReviews = [] } = useGetLatestReviewsQuery();
    return (
        <Container>
            <TitleH1>See Why People Love Wishi</TitleH1>
            <ReviewsCarousel slidesToAdvanceOnPaging={2}>
                {latestReviews.map((review, index) => (
                    <ReviewItem key={index} {...review} />
                ))}
            </ReviewsCarousel>
        </Container>
    );
};
