import { BookBar } from './BookBar';
import { Experience } from './Experience';
import { Expertise } from './Expertise';
import { Matches } from './matches';
import { Recommendation } from './Recommendation';
import { Reviews } from './reviews';
import { StylingExperience } from './StylingExperience';
import { StylistHeader } from './StylistHeader';
import { Work } from './Work';
import { PageNavbar } from './PageNavbar';

export default {
    Recommendation,
    Reviews,
    Experience,
    Expertise,
    StylistHeader,
    BookBar,
    Work,
    StylingExperience,
    Matches,
    PageNavbar
};
