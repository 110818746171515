import React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import content from 'content.json';
import dayjs from 'dayjs';
import { getChatUrl, getGoalstUrl, getStylistProfileUrl } from 'services/utils/url-utils';
import { Message } from 'store/inbox-service/inbox-types';

import './style.scss';
import { useUserId } from 'store/auth-service';

const { inbox: texts } = content;

const reviewable = ['moodboard', 'check_out_your_outfit', 'items_collection'];
interface MessageComponentProps {
    message: Message;
}

/**
 * Message CTA Requirements: P - primary, S - secondary CTA
 * bot								('S chat')
 * inquiry sent  					('P book', 'S chat|reply')
 * single styling ongoing 			('S chat|reply|review')
 * single styling ended     		('P go to session', 'S book again')
 * subscription session ongoing  	('S chat|reply|review')
 * subscription session ended 		('P go to session')
 * subscription ended				('P go to session', 'S book again')
 *
 * @param param0
 * @returns
 */
export const MessageComponent: React.FC<MessageComponentProps> = ({ message }) => {
    const navigate = useNavigate();
    const userId = useUserId();

    const getMessageText = () => {
        const last_message = message.last_message;
        switch (last_message.type) {
            case 'love':
                return <Image src={texts.icons.heart} />;
            case 'like':
                return <Image src={texts.icons.like} />;
            case 'media_image':
                return <Image src={texts.icons.photo} />;
            default:
                return last_message.text;
        }
    };

    const onBookClick = () => navigate(getGoalstUrl({ stylistId: message.stylist.uuid }));
    const onChatClick = () => navigate(getChatUrl(message.stylist.uuid, userId!));

    const messageTimestamp = message.last_message.created
        ? dayjs(message.last_message.created).format('MMMM DD, YYYY')
        : '';

    const isInquiry = message.can_book && !message.current_session_status;
    const isRebookAvailable = message.can_book && message.current_session_status === 'ended';
    const isSessionEnded = message.current_session_status === 'ended';
    const isSessionActionable =
        message.stylist.is_bot ||
        isInquiry ||
        (!message.can_book && message.current_session_status && message.current_session_status !== 'ended');

    const BookButton = () => {
        const firstName = message?.stylist?.first_name;
        const buttonText = firstName ? `Book ${firstName}` : 'Book';
        return (
            <Button variant="warning" className="d-none d-md-block" onClick={onBookClick}>
                {buttonText}
            </Button>
        );
    };

    const BookAgainButton = () => {
        const firstName = message?.stylist?.first_name;
        const buttonText = firstName ? `Book ${firstName} Again` : 'Book Again';
        return (
            <Button variant="light" onClick={onBookClick}>
                {buttonText}
            </Button>
        );
    };

    const GoToSessionButton = () => (
        <Button variant="warning" className="d-none d-md-block" onClick={onChatClick}>
            {texts.button.session}
        </Button>
    );

    const SessionCtaButton = () => {
        const { sender, type } = message.last_message;
        const defaultText = texts.button[sender === 'stylist' ? 'reply' : 'chat'];
        const buttonText = reviewable.includes(type) ? texts.button.review : defaultText;
        return (
            <Button variant="light" onClick={onChatClick}>
                {buttonText}
            </Button>
        );
    };

    return (
        <Row className="message">
            <Col className="stylist-info">
                <Link to={message.stylist.is_bot ? '/inbox' : getStylistProfileUrl(message.stylist.uuid)}>
                    <Image src={message.stylist.picture} />
                </Link>
                {message.plan && texts.plans[message.plan] && (
                    <p className={`d-none d-sm-block ${message.plan === 'cleanout' ? 'cleanout' : ''}`}>
                        {texts.plans[message.plan]}
                    </p>
                )}
            </Col>
            <Col>
                <div className="text" onClick={onChatClick}>
                    <p className="name">{message.stylist.first_name}</p>
                    <p className="content">{getMessageText()}</p>
                    <p className="date">{messageTimestamp}</p>
                </div>
                <div className="buttons">
                    {isInquiry && <BookButton />}
                    {isSessionEnded && <GoToSessionButton />}
                    {isRebookAvailable && <BookAgainButton />}
                    {isSessionActionable && <SessionCtaButton />}
                </div>
            </Col>
        </Row>
    );
};
