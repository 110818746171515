import { FC } from 'react';

import { ReactComponent as GiftIcon } from '../assets/gift-icon.svg';

import { Container, Label, Image, Paragraph, Text, Title } from './Gift-styles';

export const Gift: FC = () => {
    return (
        <Container>
            <Text>
                <Label>
                    <GiftIcon />A Gift From Us!
                </Label>
                <Title>
                    The Wishi Lux Bag:
                    <br />
                    An Exclusive Perk
                </Title>
                <Paragraph>
                    When you book a Wishi Lux Plan, you’ll receive an exclusive bag filled with Wishi essentials—because
                    great style deserves a little something extra.
                </Paragraph>
            </Text>
            <Image src="https://media-cf.wishi.me/react/lux-new/gift.webp" alt="Wishi Lux" />
        </Container>
    );
};
