import styled from 'styled-components';
import { getBreakpoint, getColor, TitleH1 } from 'theme';

import { Section } from './styles';

export const Container = styled(Section)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;

    @media screen and (${getBreakpoint('desktop')}) {
        flex-direction: column;
        gap: 0;
        align-items: start;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 530px;

    @media screen and (${getBreakpoint('desktop')}) {
        padding: 52px 40px 36px 40px;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        align-items: center;
        text-align: center;
        padding: 40px 36px 36px 36px;
    }
`;

export const Title = styled(TitleH1)`
    text-align: left;
`;

export const Paragraph = styled.p`
    font-size: 18px;
    line-height: 140%;
`;

export const Label = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    padding: 12px 24px;
    color: ${getColor('primaryLight')};
    background-color: ${getColor('wishiMajor')};
    border-radius: 50px;
`;

export const Image = styled.img`
    width: 100%;
    height: 580px;
    max-width: 855px;
    object-fit: cover;

    @media screen and (${getBreakpoint('desktop')}) {
        max-width: none;
        height: 560px;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        height: 344px;
    }
`;
