import { FC } from 'react';
import { Config } from 'services';
import { TitleH1 } from 'theme';

import { ButtonContainer, Container, GoToClosetButton, Subtitle } from './styles';

export const NoResults: FC = () => {
    return (
        <Container>
            <TitleH1>Nothing to Add to Closet</TitleH1>
            <Subtitle>You’ve already added all available items to your closet.</Subtitle>
            <ButtonContainer>
                <GoToClosetButton to={Config.get('appsFlyerClosetUrl')}>View My Closet</GoToClosetButton>
            </ButtonContainer>
        </Container>
    );
};
