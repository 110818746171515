import { FC, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import content from 'content.json';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';
import { Client, Stylist } from 'types/user';
import { getStylistSearchUrl } from 'services/utils/url-utils';
import { OtherMatches } from './OtherMatches';

interface MatchesProps {
    stylist: Stylist;
    user: Client | null;
}

export const Matches: FC<MatchesProps> = ({ user, stylist }) => {
    const navigate = useNavigate();
    const { data: stylistsMatches = [] } = useBestMatchesQuery();
    const otherMatches = useMemo(() => {
        return stylistsMatches.filter(({ uuid }) => uuid !== stylist.uuid);
    }, [stylistsMatches]);

    const onViewMoreClick = () => {
        trackEvent({
            name: MP_EVENTS.VIEW_MORE_STYLISTS_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.STYLIST_PROFILE_PAGE
            }
        });
        navigate(getStylistSearchUrl());
    };

    if (!user) {
        return <></>;
    }

    return (
        <div className="matches-section">
            {otherMatches.length > 0 && <OtherMatches matches={otherMatches} />}
            <Row>
                <Col className="button-container">
                    <button className="more-btn" onClick={onViewMoreClick} data-test-id="load-more">
                        {content.match.more}
                    </button>
                </Col>
            </Row>
        </div>
    );
};
