import axios from 'axios';
import { getUserToken } from 'services/utils/user-utils';

import Config from './Config';

const proxyRoot = Config.get('apiroot2');

const Style = {
    getMoodBoards: (gender) =>
        axios.get(`${proxyRoot}user/onboarding/moodboards`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),

    getBodyTypes: (gender) =>
        axios.get(`${proxyRoot}tag/active`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),

    getNeeds: () => axios.get(`${proxyRoot}goal`),
    updateStyle: (user_id, style) =>
        axios.put(
            `${proxyRoot}user/${user_id}/stylingPreferences`,
            { ...style },
            { headers: { token: getUserToken() } }
        ),

    getFeed: (params) =>
        axios.get(`${proxyRoot}feed/all`, {
            params: { ...params, types: 'styled_you' }
        }),

    filters: () => axios.get(`${Config.get('apiroot2')}giftItem/filters?client=web`),
    giftItems: (filters) => axios.get(`${Config.get('apiroot2')}giftItem`, { params: filters })
};

export default Style;
