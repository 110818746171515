import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PlanParams } from 'routes/Goals/types';
import { urlParamToJson } from 'services/utils/url-utils';
import { useIsLoggedIn } from 'store/auth-service';

import { StylingButtonProps } from './types';
import { CheckoutFlowButton, OnboardingFlowButton, SelectStylistFlowButton } from './variants';

export const StylingButton: FC<StylingButtonProps> = ({ children = 'Let’s Get Styling', ...props }) => {
    const [searchParams] = useSearchParams();
    const { stylistId } = urlParamToJson<PlanParams>(searchParams.get('booking'));
    const isLoggedIn = useIsLoggedIn();
    const isCheckoutFlow = stylistId;
    const getButton = () => {
        if (isLoggedIn) {
            if (isCheckoutFlow) {
                return CheckoutFlowButton;
            } else {
                return SelectStylistFlowButton;
            }
        } else {
            return OnboardingFlowButton;
        }
    };

    const Button = getButton();

    return <Button {...props}>{children}</Button>;
};
