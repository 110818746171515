import styled from 'styled-components';
import { getBreakpoint, getColor } from 'theme';

import { PlansNew } from 'components';

import { Section } from '../components';

export const Container = styled(Section)`
    padding-top: 60px;
    background-color: ${getColor('beige')};

    @media screen and (${getBreakpoint('mobile')}) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (${getBreakpoint('smallMobile')}) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const StyledPlans = styled(PlansNew)`
    margin-top: 40px;
`;
