export const allowedShippingCountries = [
    'US',
    'BE',
    'BR',
    'CA',
    'CY',
    'DK',
    'DO',
    'EE',
    'FR',
    'GE',
    'DE',
    'GR',
    'HK',
    'IN',
    'IE',
    'IT',
    'JP',
    'KR',
    'KW',
    'LI',
    'LU',
    'MX',
    'MC',
    'MA',
    'NL',
    'NZ',
    'NO',
    'PT',
    'PR',
    'QA',
    'RU',
    'SA',
    'SG',
    'ZA',
    'ES',
    'SE',
    'CH',
    'TW',
    'TH',
    'TR',
    'AE',
    'GB',
    'VG'
];
