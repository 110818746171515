import { FC } from 'react';
import { TitleH1 } from 'theme';

import { Container, StyledFaqAccordion } from './Faq-styles';

export const Faq: FC = () => {
    return (
        <Container>
            <TitleH1>Your Questions, Answered</TitleH1>
            <StyledFaqAccordion />
        </Container>
    );
};
