import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { StylistId } from 'types/user';

import { BackButton, Container, StylistImage, StylistName } from './SessionCheckoutHeader-styles';

interface SessionCheckoutHeaderProps {
    stylistId: StylistId;
}

export const SessionCheckoutHeader: FC<SessionCheckoutHeaderProps> = ({ stylistId }) => {
    const navigate = useNavigate();
    const { data: stylist } = useGetStylistQuery(stylistId || '', { skip: !stylistId });

    if (!stylist) {
        return <></>;
    }

    return (
        <Container>
            <BackButton className="back-btn" onClick={() => navigate(-1)} />
            <StylistImage>
                <img src={stylist.profile_picture} alt={stylist.name} />
            </StylistImage>
            <StylistName>{stylist.name}</StylistName>
        </Container>
    );
};
