import { CheckoutParams, GoalParams, PlanParams, StylistParams } from 'routes/Goals/types';
import { MP_VALUES } from 'services/mixpanel';

const getPrefix = (relative = true) => (relative ? '' : window.origin);

export const getOutfitUrl = (uuid: string) => `${window.origin}/feed/outfit/${uuid}`;

export const getOnboardingUrl = (relative = true) => `${getPrefix(relative)}/onboarding`;

export const getStylistSearchUrl = (relative = true) => `${getPrefix(relative)}/stylistSearch`;

export const getMatchPageUrl = (relative = true) => `${getPrefix(relative)}/match`;

export const getMatchPlansPageUrl = (params: GoalParams, relative = true) =>
    `${getPrefix(relative)}/match/plans?booking=${jsonToUrlParam(params)}`;

export const getChatUrl = (stylistId: string, userId: string, relative = true) =>
    `${getPrefix(relative)}/chat/${stylistId}/${userId}`;

export const getStylistProfileUrl = (stylistId: string, params?: StylistParams, relative = true) => {
    const path = `${getPrefix(relative)}/stylist/${stylistId}/profile`;
    return params ? `${path}?booking=${jsonToUrlParam(params)}` : path;
};

export const getGoalstUrl = (params: GoalParams, relative = true) =>
    `${getPrefix(relative)}/goals?booking=${jsonToUrlParam(params)}`;

export const getPlanstUrl = (params: PlanParams, relative = true) =>
    `${getPrefix(relative)}/plans?booking=${jsonToUrlParam(params)}`;

export const getSessionCheckoutUrl = (params: CheckoutParams, relative = true) =>
    `${getPrefix(relative)}/checkout?booking=${jsonToUrlParam(params)}`;

export const getFavoriteItemUrl = (outfitId: string, itemId: string, relative = true) =>
    `${getPrefix(relative)}/favorites/outfit/${outfitId}/item/${itemId}?context=favorites`;

export const getGiftItemUrl = (itemId: string, relative = true) =>
    `${getPrefix(relative)}/item/${itemId}?context=gifts`;

export const getSessionItemUrl = (itemId: string, relative = true) =>
    `${getPrefix(relative)}/item/${itemId}?context=session`;

export const getPageType = () => {
    // order of checks is important. first item then outfit and then feed
    // otherwise need to use regex to match the correct path
    const path = window.location.pathname;
    let ret = undefined;
    if (path === '/') {
        ret = MP_VALUES.LANDING_PAGE;
    } else if (path.startsWith('/stylistSearch')) {
        ret = MP_VALUES.STYLISTS_PAGE;
    } else if (path.includes('/item/')) {
        ret = MP_VALUES.PDP_PAGE;
    } else if (path.includes('/outfit/')) {
        ret = MP_VALUES.OUTFIT_PAGE;
    } else if (path.includes('/feed')) {
        ret = MP_VALUES.FEED_PAGE;
    } else if (path.startsWith('/stylist/')) {
        ret = MP_VALUES.STYLIST_PROFILE_PAGE;
    } else if (path === '/pricing') {
        ret = MP_VALUES.PRICING_PAGE;
    } else if (path === '/how-it-works') {
        ret = MP_VALUES.HOW_IT_WORKS_PAGE;
    } else if (path === '/gift-cards') {
        ret = MP_VALUES.GIFT_CARDS_PAGE;
    }
    return ret;
};

export const jsonToUrlParam = <T>(value: T): string => encodeURIComponent(btoa(JSON.stringify(value)));

export const urlParamToJson = <T>(value: string | null): T => {
    try {
        return value ? JSON.parse(atob(decodeURIComponent(value))) : {};
    } catch (e) {}
    return {} as T;
};
