import styled from 'styled-components';
import { getBreakpoint, getColor } from 'theme';

import { FaqAccordion } from 'components';

import { Section } from './styles';

export const Container = styled(Section)`
    background-color: ${getColor('primaryLight')};
    padding: 80px 0;

    @media screen and (${getBreakpoint('desktop')}) {
        padding: 40px;
    }
`;

export const StyledFaqAccordion = styled(FaqAccordion)`
    margin-top: 40px;
`;
