import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectNewOnboarding, setNewOnboarding } from './features-slice';
import { MP_EXP_VARIANTS, MP_PROPS, registerPropertiesOnce } from 'services/mixpanel';
import { useEffect } from 'react';

export const useNewOnboardingFlag = (fallback = false): boolean => {
    const dispatch = useDispatch();
    const { newOnboardingFlowSwitch: masterSwitch } = useFlags();
    const flag = useSelector(selectNewOnboarding);
    const isOn = masterSwitch && flag;

    useEffect(() => {
        if (flag !== undefined) {
            const variant = isOn ? MP_EXP_VARIANTS.NEW : MP_EXP_VARIANTS.CONTROL;
            registerPropertiesOnce({
                [MP_PROPS.EXP_NEW_ONBOARDING]: variant
            });
        }
    }, [isOn, flag]);

    if (flag === undefined) {
        dispatch(setNewOnboarding(Math.random() > 0.5));
        return fallback;
    }

    return isOn;
};
