import styled from 'styled-components';

import { Accordion } from '../ Accordion';

export const StyledAccordion = styled(Accordion)`
    max-width: 1178px;
    font-size: 18px;

    li {
        margin-bottom: 32px;
        padding-bottom: 32px;

        > div {
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 0;

            &:first-child {
                font-weight: 500;
            }

            &:last-child > div {
                padding-top: 12px;
            }
        }
    }
`;
