import axios from 'axios';
import dayjs from 'dayjs';
import TagManager from 'react-gtm-module';

import { Config, Cookies } from './';
import { getUserToken } from './utils/user-utils';

const regexList = [
    {
        regex: /^\/stylist\/(.+)\/profile$/,
        pathname: '/stylist/:id/profile'
    }
];

const eventMappings = {
    '/onboarding': {
        event: 'Onboarding'
        // params: {
        //     content_name: 'Quiz Start',
        //     content_category: 'Onboarding'
        // }
    },
    '/onboarding/bodyTypes/': {
        event: 'BodyTypes'
        // params: {
        //     content_name: 'Body Type Selection',
        //     content_category: 'Quiz Funnel'
        // }
    },
    '/onboarding/styles/': {
        event: 'Styles Step 0'
        // params: {
        //     content_name: 'Style Quiz Step',
        //     step: 'Start'
        // }
    },
    '/onboarding/styles/1': {
        event: 'Styles Step 1'
    },
    '/onboarding/styles/2': {
        event: 'Styles Step 2'
    },
    '/onboarding/styles/3': {
        event: 'Styles Step 3'
    },
    '/onboarding/styles/4': {
        event: 'Styles Step 4'
    },
    '/onboarding/styles/5': {
        event: 'Styles Step 5'
    },
    '/onboarding/styles/6': {
        event: 'Styles Step 6'
    },
    '/stylistSearch': {
        event: 'StylistSearch'
        // params: {
        //     content_name: 'Stylist Search',
        //     content_category: 'Quiz Funnel'
        // }
    },
    '/goals': {
        event: 'Goals'
        // params: {
        //     content_name: 'Goals Page',
        //     content_category: 'Quiz Funnel'
        // }
    },
    '/plans': {
        event: 'AddToCart'
        // params: {
        //     content_name: 'Plans Page',
        //     content_category: 'Quiz Funnel'
        // }
    },
    '/stylist/:id/profile': {
        event: 'Profile'
    }
};

const Tracking = {
    facebook: (type, event, data) => Tracking.send('facebook', { type, event, data }),
    google: (event) => Tracking.send('google', event),
    tag: (event) => Tracking.send('tag', { event }),
    track: (data, token) => Tracking.send('track', { data, token }),
    send: (platform, { type, event, data, token }) => {
        if (process.env.REACT_APP_ENV === 'production') {
            switch (platform) {
                case 'facebook':
                    if (window.fbq) {
                        window.fbq(type, event, data);
                    } else {
                        console.log('fb track', { type, event, data });
                    }
                    break;
                case 'google':
                    window.gtag && window.gtag(type, event, data);
                    break;
                case 'tag':
                    TagManager.dataLayer({ dataLayer: event });
                    break;
                case 'track':
                    // What is this for:
                    // According to Eldad on 06/02/2025
                    // The session management doesn't use this anymore it seems,
                    // but the admin is using this to count logins.
                    // Maybe we can move this to the login APIs instead, and then you can cut this
                    const date = Cookies.get('tracking');
                    if (isNaN(date) || dayjs().diff(dayjs(date), 'days') >= 1) {
                        Cookies.set('tracking', dayjs().valueOf());
                        return axios.post(
                            `${Config.get('apiroot2')}user/${data.id}/trackSession`,
                            { client: data.device },
                            { headers: { token: getUserToken() } }
                        );
                    }
                    break;
                default:
                    console.log(type, event, data);
            }
        }
    }
};

export default Tracking;

export const fbPageTrack = (pathname) => {
    const key = eventMappings[pathname] ? pathname : regexList.find(({ regex }) => pathname.match(regex))?.pathname;

    if (key) {
        const eventData = eventMappings[key];
        Tracking.facebook('track', eventData.event, eventData?.params || {});
    }
};
