import { ItemType } from 'types/item';
import { me, rootSplitApi } from '../root-api-slice';

type ItemId = string;

const styleApi = rootSplitApi
    .enhanceEndpoints({
        addTagTypes: ['Item']
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getItem: build.query<ItemType, ItemId>({
                query: (itemId) => ({
                    url: `proxy/item/catalog/${itemId}`,
                    params: { user_uuid: me }
                }),
                providesTags: (_, __, arg) => [{ type: 'Item', id: arg }]
            })
        })
    });

export const { useGetItemQuery } = styleApi;
