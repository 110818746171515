import { FC } from 'react';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { getStylistSearchUrl } from 'services/utils/url-utils';
import { PrimaryButtonLink } from 'theme';

import { ButtonVariantProps } from '../types';

export const SelectStylistFlowButton: FC<ButtonVariantProps> = ({
    children,
    className,
    trackingSource,
    trackingElement
}) => {
    const navPath = getStylistSearchUrl();

    const track = () => {
        trackEvent({
            name: MP_EVENTS.STYLISTS_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: trackingSource,
                [MP_PROPS.ELEMENT]: trackingElement
            }
        });
    };

    return (
        <PrimaryButtonLink to={navPath} state={{ mpSource: trackingSource }} onClick={track} className={className}>
            {children}
        </PrimaryButtonLink>
    );
};
