import { FC, useEffect } from 'react';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';

import { StyledPage } from './Pricing-styles';
import { Faq, Header, HowItWorks, Reviews, Schedule, WhatsIncluded } from './sections';

export const Pricing: FC = () => {
    useEffect(() => {
        klaviyoTrack(KlaviyoEvent.PLAN_VIEW);
    }, []);

    return (
        <StyledPage>
            <Header />
            <Schedule />
            <Reviews />
            <HowItWorks />
            <WhatsIncluded />
            <Faq />
        </StyledPage>
    );
};
