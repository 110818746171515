/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const Formatter = {
    price: (price: number | string = 0, fractionDigits = 0, currency = 'USD') => {
        const tmp = {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        };
        const options: Intl.NumberFormatOptions = currency ? { ...tmp, style: 'currency', currency } : tmp;

        const formatter = new Intl.NumberFormat('en-US', options);
        if (typeof price === 'string') price = price.replace(',', '');

        const formatted = typeof price === 'string' ? parseInt(price.replace(',', ''), 10) : price;

        return formatter.format(formatted);
    },
    time: (timestamp: number | string | Date, format = 'MMMM DD, YYYY') => {
        const date = dayjs(new Date(timestamp));
        if (date.isValid() && timestamp !== null) {
            return dayjs().diff(date, 'days') <= 7 ? date.fromNow() : date.format(format);
        } else {
            return '';
        }
    }
};

export default Formatter;
