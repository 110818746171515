import './style.scss';

import { useIsMobile } from 'hooks';
import { Col, Container, Row } from 'react-bootstrap';

import content from 'content.json';
import { ShoppingListFilter } from './ShoppingList';
import { FC } from 'react';

const {
    shopping: { filter: texts }
} = content;

interface ShoppingFilterProps {
    filter?: ShoppingListFilter;
    setFilter: (filter: ShoppingListFilter) => void;
}

export const ShoppingFilter: FC<ShoppingFilterProps> = ({ filter, setFilter }) => {
    const isMobile = useIsMobile();

    return (
        <Container className="shopping-list-filter">
            <Row>
                <Col className="filter-btns">
                    {texts.title}
                    {texts.filters.map(({ name, mobileName, key, value }) => (
                        <div
                            key={key}
                            className={`filter-btn ${filter?.key === key ? 'active' : ''}`}
                            onClick={() =>
                                setFilter({ key, value: { ...value, order: value.order as 'asc' | 'desc' } })
                            }
                        >
                            {isMobile && mobileName ? mobileName : name}
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default ShoppingFilter;
