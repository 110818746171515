import styled from 'styled-components';
import { getBreakpoint } from 'theme';

import { FaqAccordion } from 'components';

export const StyledFaqAccordion = styled(FaqAccordion)`
    margin-top: 24px;

    @media screen and (${getBreakpoint('mobile')}) {
        margin-top: 8px;
    }
`;
