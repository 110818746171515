import './style.scss';

import { FavoriteButton } from 'components';
import content from 'content.json';
import { useCart, useIsMobile } from 'hooks';
import { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Formatter } from 'services';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getOutfitProperties } from 'services/mixpanel/utils';
import { getItemType, isExternalItem, isShopableItem } from 'services/utils/item-utils';
import { useIsLoggedIn, useUserId } from 'store/auth-service';
import { useModal } from 'store/ui';
import { useFavoriteLookMutation, useUnfavoriteLookMutation } from 'store/user-service/user-api-slice';

const { feed } = content;

export const Outfit = ({ outfit: extOutfit, isFavorite = false, onItemSelect, isMainOutfit = false, outfitSource }) => {
    const location = useLocation();
    const isMobile = useIsMobile();
    const { showModal } = useModal();
    const [favoriteLook] = useFavoriteLookMutation();
    const [unfavoriteLook] = useUnfavoriteLookMutation();
    const { addToCart, removeFromCart } = useCart();
    const isLoggedIn = useIsLoggedIn();
    const userId = useUserId();
    const [outfit, setOutfit] = useState(extOutfit);

    useEffect(() => {
        if (outfit?.items && outfitSource && isMainOutfit) {
            trackEvent({
                name: MP_EVENTS.LOOK_VIEWS,
                properties: getOutfitProperties(outfit, outfitSource)
            });
        }
    }, [outfit, outfitSource, isMainOutfit]);

    const onCartClick = (item) => {
        if (!isLoggedIn) {
            showModal('Signup', {
                redirectUrl: `${location.pathname}${location.search}`,
                source: MP_VALUES.OUTFIT_PAGE,
                element: MP_VALUES.CART_CLICK
            });
        } else {
            if (!item.is_in_cart) {
                addToCart(item, outfitSource);
            } else {
                removeFromCart(item);
            }

            const items = outfit.items.map((i) =>
                i.unique === item.unique ? { ...item, is_in_cart: !item.is_in_cart } : i
            );
            setOutfit({ ...outfit, items });
        }
    };

    const onFavoriteLookClick = () => {
        const { uuid: lookId, owner } = outfit;

        if (isFavorite) {
            unfavoriteLook({ userId, lookId });
        } else {
            favoriteLook({ userId, lookId });
            trackEvent({
                name: MP_EVENTS.LOOK_ADDED_TO_FAVORITE,
                properties: {
                    [MP_PROPS.LOOK_UUID]: lookId,
                    [MP_PROPS.LOOK_SOURCE]: outfitSource,
                    [MP_PROPS.STYLIST_NAME]:
                        outfitSource == 'favorites' ? owner.name : `${owner.first_name} ${owner.last_name}`,
                    [MP_PROPS.STYLIST_UUID]: owner.uuid
                }
            });
        }
    };

    return (
        <div className="outfit">
            <Col xs={12} md={6} className="look">
                {outfit.attributes?.name ? (
                    <p>{outfit.attributes.name}</p>
                ) : (
                    outfit.outfit_name && <p>{outfit.outfit_name}</p>
                )}
                <FavoriteButton isFavorite={isFavorite} onClick={onFavoriteLookClick} />
                <img className="outfit-image" src={outfit.picture} alt="Outfit" />
            </Col>
            {outfit.items?.length && (
                <Col xs={12} md={6} className={`items ${outfit.items.length <= 4 ? 'no-scroll' : ''}`}>
                    <Container fluid={isMobile}>
                        <p className="shop-board d-block d-sm-none">{feed.board}</p>
                        <Row>
                            {outfit.items.map((item, index) => (
                                <Col xs={6} key={index} className={getItemType(item)}>
                                    {isShopableItem(item) && !item.is_in_closet && (
                                        <div
                                            className={`cart-icon ${item.is_in_cart ? 'added' : 'add'}`}
                                            onClick={() => onCartClick(item)}
                                        />
                                    )}
                                    <div
                                        className="item"
                                        onClick={() => (isShopableItem(item) ? onItemSelect(item) : '')}
                                    >
                                        <Image src={item.picture ? item.picture : item.pictureUrl} />
                                        <p className="brand">{item.brand_name || item.itemBrand}</p>
                                        {item.price > 0 && <p className="price bold">{Formatter.price(item.price)}</p>}

                                        {isExternalItem(item) && (
                                            <div className="shop-externally">Shop at {item.retailer_name}</div>
                                        )}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Col>
            )}
        </div>
    );
};
