import styled from 'styled-components';
import { getColor } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Tabs = styled.div`
    display: flex;
    background: ${getColor('primaryLight')};
    border: 4px solid ${getColor('primaryLight')};
    border-radius: 6px;
    box-shadow:
        0 86px 35px rgba(84, 84, 84, 0.01),
        0 49px 29px rgba(84, 84, 84, 0.03),
        0 22px 22px rgba(84, 84, 84, 0.05),
        0 5px 12px rgba(84, 84, 84, 0.06);
`;

export const Tab = styled.div<{ $selected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    background-color: ${({ $selected }) => ($selected ? getColor('lightBrown') : 'transparent')};
    color: ${({ $selected }) => ($selected ? getColor('primaryLight') : 'inherit')};
    border-radius: 4px;
    cursor: pointer;
`;
