export const handleViewPortScrolling = (viewPort: HTMLDivElement, callback: (index: number) => void) => {
    const containerElement = viewPort.parentElement!;

    requestAnimationFrame(() => {
        const slides = [...viewPort.children] as HTMLDivElement[];
        const { scrollLeft, clientWidth, scrollWidth } = viewPort;

        const nearestSlideIndex = slides.reduce((closestSlideIndex, currentSlide, currentIndex) => {
            const closestSlideOffset = Math.abs(slides[closestSlideIndex].offsetLeft - scrollLeft);
            const currentSlideOffset = Math.abs(currentSlide.offsetLeft - scrollLeft);

            return closestSlideOffset < currentSlideOffset ? closestSlideIndex : currentIndex;
        }, 0);

        const halfFrameWidth = clientWidth / 2;
        const scrollEnd = scrollWidth - clientWidth;
        const calcOpacity = (position: number) => position / (halfFrameWidth / 100) / 100;
        const prevNavOpacity = scrollLeft <= halfFrameWidth ? calcOpacity(scrollLeft) : 1;
        const nextNavOpacity = scrollLeft >= scrollEnd - halfFrameWidth ? calcOpacity(scrollEnd - scrollLeft) : 1;

        containerElement.style.setProperty('--prevNavOpacity', prevNavOpacity + '');
        containerElement.style.setProperty('--nextNavOpacity', nextNavOpacity + '');

        callback(nearestSlideIndex);
    });
};

export const scrollToSlideElement = (viewPort: HTMLDivElement, slideIndex: number) => {
    const slides = viewPort.children;

    if (slides.length) {
        const actualSlideIndex = Math.min(slides.length - 1, Math.max(0, slideIndex));
        const slide = slides[actualSlideIndex] as HTMLElement;
        const slideLeft = slide.offsetLeft;

        viewPort.scrollTo({ left: slideLeft, behavior: 'smooth' });
    }
};
