import { FC, useMemo } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import content from 'content.json';
import { Stylist } from 'types/user';

interface ExpertiseProps {
    stylist: Stylist;
}

export const Expertise: FC<ExpertiseProps> = ({ stylist: { expertise_tags = [] } }) => {
    const tags = useMemo(
        () => Array.from(new Map(expertise_tags.map((tag) => [tag.tag_uuid, tag])).values()),
        [expertise_tags]
    );

    if (!tags.length) return <></>;

    return (
        <>
            <div className="title">{content.stylist.expertise}</div>
            <Row>
                <Col className="expertise">
                    {tags.map(({ tag_uuid, tag_picture, tag_name }) => (
                        <div className="expertise-item" key={tag_uuid}>
                            <Image src={tag_picture} />
                            <div>{tag_name}</div>
                        </div>
                    ))}
                </Col>
            </Row>
            <hr />
        </>
    );
};
