import styled from 'styled-components';
import { getBreakpoint } from 'theme';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 60px;
    max-width: 1392px;

    @media screen and (${getBreakpoint('desktop')}) {
        gap: 14px;
    }
`;
