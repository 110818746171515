import styled from 'styled-components';
import { getColor } from 'theme';

import collapseIcon from './assets/collapse.svg';
import expandIcon from './assets/expand.svg';

export const Container = styled.ul`
    list-style: none;
    width: 100%;
`;

export const Item = styled.li`
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${getColor('gray300')};

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }

    a {
        text-decoration: underline;
    }
`;
export const ItemTitle = styled.div<{ $isCollapsed: boolean }>`
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    &:before {
        flex-shrink: 0;
        display: block;
        width: 20px;
        height: 20px;
        background: url(${({ $isCollapsed }) => ($isCollapsed ? expandIcon : collapseIcon)}) center no-repeat;
        transform: rotate(${({ $isCollapsed }) => ($isCollapsed ? '90deg' : '0deg')});
        transition: all 0.2s;
        content: '';
    }

    > div {
        max-width: 900px;
    }
`;
export const ItemDescription = styled.div`
    padding-left: 32px;
    color: ${getColor('gray700')};
    overflow: hidden;
    height: 0;
    transition: height 0.3s;

    > div {
        padding-top: 8px;
        max-width: 900px;
    }
`;
