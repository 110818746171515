import { FC, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useNewOnboardingFlag } from 'store/features-service/features-hooks';

import * as Elements from './routes/';
import * as LazyElements from './routes/lazyElements';
import { Loader } from './components';

export const AppRoutes: FC = () => {
    const newOnboardingFlag = useNewOnboardingFlag(false);

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/" element={<Elements.Landing />} />
                <Route path="how-it-works" element={<Elements.HowItWorks />} />
                <Route
                    path="onboarding/:step?/:innerStepIndex?"
                    element={newOnboardingFlag ? <Elements.OnboardingExperimentA /> : <Elements.Onboarding />}
                />
                <Route path="stylist/:id/profile" element={<Elements.Stylist />} />
                <Route path="terms" element={<LazyElements.Terms />} />
                <Route path="privacy" element={<LazyElements.Privacy />} />
                <Route path="pricing" element={<Elements.Pricing />} />
                <Route path="our-story" element={<Elements.OurStory />} />
                <Route path="lux" element={<Elements.Lux />} />
                <Route path="login" element={<Elements.LoginPage />} />

                <Route path="gift-cards">
                    <Route index element={<Elements.GiftCardsLanding />} />
                    <Route path="choose" element={<Elements.GiftCardChoose />} />
                    <Route path="checkout" element={<Elements.GiftCardCheckout />} />
                    <Route path="confirmation" element={<Elements.GiftCardConfirmation />} />
                </Route>

                <Route element={<Elements.ProtectedRoute />}>
                    <Route path="match">
                        <Route index element={<Elements.Match />} />
                        <Route path="plans" element={<Elements.MatchPlans />} />
                    </Route>
                    <Route path="goals" element={<Elements.StylingSessionGoalsPage />} />
                    <Route path="plans" element={<Elements.BookingPlans />} />

                    <Route path="checkout" element={<Elements.StylingSessionCheckout />} />
                    <Route path="checkout/confirmation" element={<Elements.StylingSessionConfirmation />} />
                    <Route path="quiz" element={<Elements.Quiz />} />
                    <Route path="thank-you" element={<Elements.Welcome />} />
                    <Route path="inbox" element={<Elements.Inbox />} />
                    <Route path="chat/:stylist_id/:user_id" element={<Elements.Chat />} />
                    <Route path="stylistSearch" element={<Elements.StylistsList />} />
                    <Route path="favorites">
                        <Route index element={<Elements.Favorites />} />
                        <Route path="outfit/:outfit_uuid" element={<Navigate to="favorites" />} />
                        <Route path="outfit/:outfit_uuid/item/:itemId">
                            <Route index element={<Elements.Item />} />
                            <Route path="checkout" element={<Elements.Checkout />} />
                            <Route path="confirmation" element={<Elements.Confirmation source={'favorites'} />} />
                        </Route>
                    </Route>
                    <Route path="shopping-list">
                        <Route index element={<Elements.ShoppingList />} />
                        <Route path="shopping-list/outfit/:outfit_uuid" element={<Navigate to="shopping-list" />} />
                        <Route path="item/:itemId">
                            <Route index element={<Elements.Item />} />
                            <Route path="checkout" element={<Elements.Checkout />} />
                            <Route path="confirmation" element={<Elements.Confirmation source={'shopping-list'} />} />
                        </Route>
                    </Route>
                    <Route path="orders" element={<Elements.Orders />} />
                    <Route path="profile/:tab?" element={<Elements.Profile />} />
                    <Route path="settings/:tab?" element={<Elements.Settings />} />
                    <Route path="session/:sessionId/add-to-closet" element={<Elements.SessionAddToCloset />} />
                </Route>

                <Route path="feed">
                    <Route index element={<Elements.Feed />} />
                    <Route path="outfit/:outfit_uuid">
                        <Route index element={<Elements.Outfit />} />
                        <Route path="item/:itemId">
                            <Route index element={<Elements.Item />} />
                            <Route element={<Elements.ProtectedRoute />}>
                                <Route path="checkout" element={<Elements.Checkout />} />
                                <Route path="confirmation" element={<Elements.Confirmation source={'feed'} />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path="item/:itemId">
                    <Route index element={<Elements.Item />} />
                    <Route element={<Elements.ProtectedRoute />}>
                        <Route path="checkout" element={<Elements.Checkout />} />
                        <Route path="confirmation" element={<Elements.Confirmation source={'session'} />} />
                    </Route>
                </Route>

                <Route path="gifts" element={<Elements.Campaign campaign="gifts" />} />
                <Route path="wedding" element={<Elements.Campaign campaign="wedding" />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Suspense>
    );
};
