import styled from 'styled-components';
import { getColor } from 'theme';

import { FaqAccordion } from 'components';

import { Section } from '../components';

export const Container = styled(Section)`
    background-color: ${getColor('primaryLight')};
`;

export const StyledFaqAccordion = styled(FaqAccordion)`
    margin-top: 40px;
`;
