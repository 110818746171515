import { FC, useState } from 'react';

import { PlansViewProps } from '../types';

import { Container, Tab, Tabs } from './MobileView-styles';
import { PlanItem } from './PlanItem';

export const MobileView: FC<PlansViewProps> = ({ plans, className, recommendedPlan }) => {
    const [selectedPlanIndex, setSelectedPlanIndex] = useState(
        recommendedPlan ? plans.findIndex(({ planId }) => planId === recommendedPlan) : 0
    );
    const selectedPlan = plans[selectedPlanIndex];

    return (
        <Container className={className}>
            <Tabs>
                {plans.map(({ name }, index) => (
                    <Tab
                        key={index}
                        $selected={index === selectedPlanIndex}
                        onClick={() => setSelectedPlanIndex(index)}
                    >
                        {name}
                    </Tab>
                ))}
            </Tabs>
            <PlanItem recommendedPlan={recommendedPlan} plan={selectedPlan} />
        </Container>
    );
};
