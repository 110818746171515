import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface PageNavbarProps {
    anchors: { ref: React.RefObject<HTMLDivElement>; label: string }[];
    onSelect: (ref: React.RefObject<HTMLDivElement>) => void;
    onBack: () => void;
    className?: string;
}

export const PageNavbar: FC<PageNavbarProps> = ({ anchors, onSelect, className, onBack }) => (
    <Container className={`page-navbar ${className}`} fluid>
        <div className="back" onClick={onBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <Row>
            {anchors.map(({ ref, label }) => (
                <Col key={label} className="anchor" onClick={() => onSelect(ref)}>
                    {label}
                </Col>
            ))}
        </Row>
    </Container>
);
