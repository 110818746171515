import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import content from 'content.json';
import { Stylist } from 'types/user';
const { stylist: texts } = content;

interface RecommendationProps {
    stylist: Stylist;
}

export const Recommendation: FC<RecommendationProps> = ({ stylist }) => {
    const { uuid, first_name, why_we_recommend } = stylist;
    return (
        <Row className="why">
            {why_we_recommend && (
                <Col>
                    <div className="title">{uuid && first_name && texts.why.replace('%name%', first_name)}</div>
                    <p>{why_we_recommend}</p>
                </Col>
            )}
            <hr />
        </Row>
    );
};
