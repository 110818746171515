import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contact } from 'redux/reducers/stylists/actions';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';

import { Loader } from 'components';

import {
    ContactButton,
    Container,
    Location,
    ModalBody,
    ModalHeader,
    Name,
    Note,
    Profile,
    ProfilePicture,
    SuccessMessage,
    Textarea,
    Title
} from './Contact-style';
import { useModal } from 'store/ui';

type ContactStatus = 'loading' | 'success' | 'failed';

interface ContactModalData {
    stylistId: string;
}

export const Contact: FC = () => {
    const { data } = useModal<ContactModalData>();
    const stylistId = data.stylistId;
    const { data: stylist } = useGetStylistQuery(stylistId, { skip: !stylistId });
    const [status, setStatus] = useState<ContactStatus>();
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    const onSubmit = async () => {
        setStatus('loading');
        try {
            await dispatch(contact(stylistId, message));
            setStatus('success');
        } catch (e) {
            setStatus('failed');
        }
    };

    return (
        <Container>
            {status === 'loading' && <Loader />}
            {status === 'success' ? (
                <>
                    <Modal.Header closeButton={true} />
                    <Modal.Body>
                        <SuccessMessage>
                            <FontAwesomeIcon icon={faCheck} />
                            <div>
                                <strong>Message Sent!</strong>
                            </div>
                            <div>You should get a response within {stylist?.average_response_time || 24} hours.</div>
                        </SuccessMessage>
                    </Modal.Body>
                </>
            ) : (
                stylist && (
                    <>
                        <ModalHeader closeButton={true}>
                            <Profile>
                                <ProfilePicture className="profile-picture" src={stylist.profile_picture} />
                                <div>
                                    <Name>{stylist.name}</Name>
                                    <Location>{stylist.location}</Location>
                                </div>
                            </Profile>
                        </ModalHeader>
                        <ModalBody>
                            <Title>Message {stylist.first_name}</Title>
                            <Textarea
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type your message here"
                            />
                            {stylist.average_response_time && (
                                <Note>Typically responds within {stylist.average_response_time} hours.</Note>
                            )}
                            {status === 'failed' && <div className="error">Could not send message</div>}
                            <ContactButton disabled={!message.length} onClick={onSubmit}>
                                Send Message
                            </ContactButton>
                        </ModalBody>
                    </>
                )
            )}
        </Container>
    );
};
