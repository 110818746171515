import { FC } from 'react';
import { SubTitle, TitleH1 } from 'theme';

import { startYourJourneyFeatures } from '../content';

import { Container, Cta, Description, FeatureItem, Features, Image, Title } from './StartYourJourney-styles';

export const StartYourJourney: FC = () => {
    return (
        <Container>
            <TitleH1>Start Your Styling Journey</TitleH1>
            <SubTitle>We begin by understanding your unique aesthetic and lifestyle through:</SubTitle>
            <Features>
                {startYourJourneyFeatures.map(({ title, description, imageUrl }, index) => (
                    <FeatureItem key={index}>
                        <Image src={imageUrl} alt={title} />
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </FeatureItem>
                ))}
            </Features>
            <Cta trackingElement="lux start your journey">Get Started</Cta>
        </Container>
    );
};
