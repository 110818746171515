import { plans } from 'consts';

import { PlanDetails } from './types';

export const extendedPlans: PlanDetails[] = [
    {
        planId: 'mini',
        features: [
            {
                title: '1:1 chat with your stylist',
                description:
                    'Talk to your stylist in your own personal chat room during your session to discuss all of your styling needs, ask for style advice, and receive all of your Style Boards. We support text, voice (great for when you need to multitask!), and media.'
            },
            {
                title: 'A personalized mood board',
                description:
                    'To kick off your session, your Stylist will create a Mood Board for you to set the tone/vibe of the direction they want to go in. It’s also a great way to see if you and your Stylist are perfectly in sync with each other. If you don’t feel like there’s a connection, you can still cancel your session at this point and switch to a different stylist, but only if you booked the Monthly Plan.'
            },
            {
                title: '2 Style Boards with curated picks and shopping links',
                description:
                    'Once you approve the style direction, you’ll start receiving Style Boards. Style Boards can be anything from complete outfits styled with clothes and accessories your stylist hand picks for you, items from your closet, a mix of both, or single items you may be looking for (ex: black purses, high waisted jeans, essentials for Fall etc).'
            },
            {
                title: 'Revisions',
                description:
                    'Not 100% in love with something your Stylist created? No worries! You can ask them to revise up to 2 Style Boards ( 1 revision per every board). So if you like the direction they’re heading in, let your Stylist know what you like/don’t like so they can revise it to your liking.'
            },
            {
                title: 'Access and discovery to brands worldwide',
                description:
                    'We pride ourselves on introducing you to new brands (but only if you want). We developed a technology that enables our stylists to curate their own stores where they can pull clothes and accessories from literally any brand that’s available to shop online. We don’t compensate our stylists with sales commissions to ensure their suggestions are 100% genuine and in your best interest.'
            }
        ],
        ...plans.mini
    },
    {
        planId: 'major',
        features: [
            {
                title: '1:1 chat with your stylist',
                description:
                    'Talk to your stylist in your own personal chat room during your session to discuss all of your styling needs, ask for style advice, and receive all of your Style Boards. We support text, voice (great for when you need to multitask!), and media.'
            },
            {
                title: 'A personalized mood board',
                description:
                    'To kick off your session, your Stylist will create a Mood Board for you to set the tone/vibe of the direction they want to go in. It’s also a great way to see if you and your Stylist are perfectly in sync with each other. If you don’t feel like there’s a connection, you can still cancel your session at this point and switch to a different stylist, but only if you booked the Monthly Plan.'
            },
            {
                title: 'Up to 5 Style Boards with curated picks and shopping links',
                description:
                    'Once you approve the style direction, you’ll start receiving Style Boards. Style Boards can be anything from complete outfits styled with clothes and accessories your stylist hand picks for you, items from your closet, a mix of both, or single items you may be looking for (ex: black purses, high waisted jeans, essentials for Fall etc).'
            },
            {
                title: 'Revisions',
                description:
                    'Not 100% in love with something your Stylist created? No worries! You can ask them to revise up to 5 Style Boards ( 1 revision per every board). So if you like the direction they’re heading in, let your Stylist know what you like/don’t like so they can revise it to your liking.'
            },
            {
                title: 'Access and discovery to brands worldwide',
                description:
                    'We pride ourselves on introducing you to new brands (but only if you want). We developed a technology that enables our stylists to curate their own stores where they can pull clothes and accessories from literally any brand that’s available to shop online. We don’t compensate our stylists with sales commissions to ensure their suggestions are 100% genuine and in your best interest.'
            },
            {
                title: 'Style your closet',
                description:
                    'We believe in sustainability, so we recommend to put your closet in the work! Have pieces in your closet you’ve never worn? We can fix that! Ask your Stylist to incorporate them in new looks so you can start wearing them! You can add a few items (or your entire closet).'
            },
            {
                title: 'Style and beauty advice',
                description:
                    'Your Stylist is also a lifestyle expert and can help you with various dilemmas -- need to know the best way to style something to fit your work party’s dress code, or what lipstick and nail polish best compliments your skin tone or matches your outfit best? Your Stylist has got you covered.'
            }
        ],
        ...plans.major
    },
    {
        planId: 'lux',
        learnMoreLink: '/lux',
        features: [
            {
                title: '30 minute intro call with your stylist',
                description:
                    'Get to know your stylist in real-time via an online phone call. Review your lifestyle, goals, schedule, and anything else you want your stylist to know so they can think of all the things you need.'
            },
            {
                title: 'A personalized mood board',
                description:
                    'To kick off your session, your Stylist will create a Mood Board for you to set the tone/vibe of the direction they want to go in. It’s also a great way to see if you and your Stylist are perfectly in sync with each other.'
            },
            {
                title: 'Up to 8 Style Boards with curated picks and shopping links',
                description:
                    'Once you approve the style direction, you’ll start receiving Style Boards. Style Boards can be anything from complete outfits styled with clothes and accessories your stylist hand picks for you, items from your closet, a mix of both, or single items you may be looking for (ex: black purses, high waisted jeans, essentials for Fall etc).'
            },
            {
                title: 'Revisions',
                description:
                    'Not 100% in love with something your Stylist created? No worries! You can ask them to revise up to 8 Style Boards ( 1 revision per every board). So if you like the direction they’re heading in, let your Stylist know what you like/don’t like so they can revise it to your liking.'
            },
            {
                title: '2 Seasonal Capsules',
                description:
                    'A seasonal capsule wardrobe is a collection of clothing that is composed of interchangeable items only, to maximize the number of outfits that can be created. The aim is to have an outfit suitable for any occasion without owning excessive items of clothing.'
            },
            {
                title: 'Virtual fitting room',
                description:
                    'Optional: 30 minute call to virtually review your new looks with your stylist and discuss any remaining questions about your session. Wondering how to keep your new investment piece pristine? Not sure if an item needs tailoring? Our stylists will let you know, if you do as well as how and where to do it!'
            },
            {
                title: 'Unlimited chat with your stylist',
                description:
                    'Another major perk is that your personal chat room will remain open for the entire month of the booking! You’ll be able to ask for general style advice, and send photos and get feedback.'
            },
            {
                title: 'Expedited shipping from retail partners',
                description:
                    'Can’t wait to receive your new looks? You don’t have to--we offer expedited shipping from our retail partners with our Lux plan. *Subject to third-party terms and conditions.'
            },
            {
                title: 'Style your closet',
                description:
                    'We believe in sustainability, so we recommend to put your closet in the work! Have pieces in your closet you’ve never worn? We can fix that! Ask your Stylist to incorporate them in new looks so you can start wearing them! You can add a few items (or your entire closet).'
            }
        ],
        ...plans.lux
    }
];
