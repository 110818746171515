import { FC } from 'react';

import { AccordionItem } from './AccordionItem';
import { Container } from './styles';
import { AccordionItemProps } from './types';

interface AccordionProps {
    items: AccordionItemProps[];
    className?: string;
}

export const Accordion: FC<AccordionProps> = ({ items, className }) => {
    return (
        <Container className={className}>
            {items.map((item, index) => (
                <AccordionItem key={index} {...item}></AccordionItem>
            ))}
        </Container>
    );
};
