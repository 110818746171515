export const startYourJourneyFeatures = [
    {
        title: 'Personal Consultation Call',
        description:
            'Get to know your stylist and have a conversation about your lifestyle, style goals, preferences, and the looks you want to achieve.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/start-your-journey-1.webp'
    },
    {
        title: 'Share Your Closet',
        description: 'Add items to your closet for your\nstylist to review and use in style boards.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/start-your-journey-2.webp'
    },
    {
        title: 'Complement Your Color & Shape',
        description:
            'Your stylist will advise on the colors and shapes that suit you best, using your preferences and inspiration photos.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/start-your-journey-3.webp'
    }
];

export const stylingProcessSteps = [
    {
        title: '1. Mood Board',
        description:
            'Your stylist creates a Mood Board with inspiration images to set the tone and aesthetics of the session.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/styling-process-1.webp'
    },
    {
        title: '2. Style Boards',
        description:
            'Based on your feedback, your stylist curates shoppable style boards, including items from your closet!',
        imageUrl: '//media-cf.wishi.me/react/lux-new/styling-process-2.webp'
    },
    {
        title: '3. Capsule Wardrobe',
        description: 'Build a streamlined, elevated wardrobe with versatile and seasonal options.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/styling-process-3.webp'
    },
    {
        title: '4. Revisions',
        description:
            'Provide feedback on your style boards, and your stylist will make thoughtful adjustments to refine the selection.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/styling-process-4.webp'
    }
];

export const buyWhatYouLoveFeatures = [
    {
        title: 'Personal Concierge Service',
        description: 'Our concierge guides you through the process, make sure your expectations and needs are met.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/buy-what-you-love-1.webp'
    },
    {
        title: 'Free & Priority Shipping',
        description: 'Your must-have pieces, always delivered with priority shipping.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/buy-what-you-love-2.webp'
    },
    {
        title: 'Any Brand, Any Budget',
        description: 'Stylists can source from luxury to contemporary to high street depending on your preferences.',
        imageUrl: '//media-cf.wishi.me/react/lux-new/buy-what-you-love-3.webp'
    }
];

export const testimonials = [
    {
        quote: 'Adriana is excellent at finding exactly what you want but also empowering to push your style!\nI’ve done multiple sessions and keep coming back.',
        name: 'Naomi C.'
    },
    {
        quote: 'Ashley read my closet tea leaves, accurately tracked my vibe, and recommend great elevated pieces. \nShe is always positive, always professional and easy to work with.',
        name: 'Dave M.'
    },
    {
        quote: 'The 30-minute call with Alicia made me feel more confident about the process and reassured me that I’m actually working with a real person. She has amazing taste and provides excellent service.',
        name: 'Stacey'
    }
];

export const wishiForEveryStage = [
    {
        image: '//media-cf.wishi.me/react/lux-new/wishi-for-every-stage-1.webp',
        title: 'Busy Moms',
        description: 'Your lifestyle shapes your wardrobe.\n We simplify dressing so you can focus on what matters!'
    },
    {
        image: '//media-cf.wishi.me/react/lux-new/wishi-for-every-stage-2.webp',
        title: 'Boss Ladies',
        description: 'No time to waste! Your Wishi capsule delivers effortless, polished, and confident workwear!'
    },
    {
        image: '//media-cf.wishi.me/react/lux-new/wishi-for-every-stage-3.webp',
        title: 'Life Updates ',
        description:
            "Wishi  adapts your wardrobe for life's big changes: promotions, motherhood, body changes, and more!"
    }
];
