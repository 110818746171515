import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import content from 'content.json';
import { Client, Stylist } from 'types/user';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';

interface StylistHeaderProps {
    stylist: Stylist;
    user: Client | null;
}

export const StylistHeader: FC<StylistHeaderProps> = ({ stylist, user }) => {
    const { data: matches = [] } = useBestMatchesQuery();
    const matchPercentage = useMemo(() => {
        return matches.find(({ uuid }) => uuid === stylist.uuid)?.match_percentage;
    }, [matches, stylist.uuid]);

    const moodboard = useMemo(() => {
        if (stylist.moodboard_male || stylist.moodboard) {
            if (user && user?.gender === 'male' && stylist.moodboard_male) {
                return stylist.moodboard_male;
            } else {
                return stylist.moodboard;
            }
        } else {
            return content.stylist.defaultMoodboard;
        }
    }, [stylist, user]);

    const followersFormatter = (val = 0) => {
        if (!val) {
            return 0;
        }
        return Math.abs(val) > 999
            ? (Math.sign(val) * (Math.abs(val) / 1000)).toFixed(1) + 'K'
            : Math.sign(val) * Math.abs(val);
    };

    return (
        <Row>
            <Col xs={12} md={6} className="stylist-moodboard">
                <Image src={moodboard} className={moodboard ? 'show' : 'hide'} />
            </Col>
            <Col xs={12} md={6}>
                <Container className="details">
                    <Row className="d-flex d-sm-none">
                        <Col className="profile-image">
                            <Image src={stylist.profile_picture} />
                        </Col>
                    </Row>
                    <Row className="name">
                        <Col>{stylist.name}</Col>
                    </Row>
                    {stylist.location && (
                        <Row className="location">
                            <Col>{stylist.location}</Col>
                        </Row>
                    )}
                    {matchPercentage && (
                        <Row className="match-percentage">
                            <Col>
                                {matchPercentage}% {content.stylist.match}
                            </Col>
                        </Row>
                    )}
                    {stylist.instagram_followers_count > 0 && stylist.instagram_url && (
                        <Row className="instagram">
                            <Col>
                                <a href={stylist.instagram_url} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="instagram-icon" icon={faInstagram} />
                                    <span>{followersFormatter(stylist.instagram_followers_count)}</span>
                                </a>
                            </Col>
                        </Row>
                    )}
                    <Row className="description">
                        <Col>{stylist.service}</Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
};
