import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { ToastData, ModalData, UiElement } from './ui-slice-types';

interface UiState {
    toast: Partial<ToastData> & UiElement;
    modal: Partial<ModalData> & UiElement;
}

const initialState: UiState = {
    toast: { isVisible: false },
    modal: { isVisible: false }
};

const uiSlice = createSlice({
    name: 'uiSlice',
    initialState,
    reducers: {
        showToast(state, action: PayloadAction<ToastData>) {
            state.toast = { ...action.payload, isVisible: true };
        },
        hideToast(state) {
            state.toast = { isVisible: false };
        },
        showModal(state, action: PayloadAction<ModalData>) {
            state.modal = { ...action.payload, isVisible: true };
        },
        hideModal(state) {
            state.modal = { isVisible: false };
        }
    }
});

export const { showToast, hideToast, showModal, hideModal } = uiSlice.actions;

export const selectToastData = ({ uiSlice }: RootState) => uiSlice.toast;
export const selectModalData = ({ uiSlice }: RootState) => uiSlice.modal;

export default uiSlice.reducer;
