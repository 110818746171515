import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 40px;
    flex-wrap: wrap;
    padding: 0 40px;
    margin-top: 40px;

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        max-width: 836px;
    }
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 226px;
`;

export const StepNumber = styled.div`
    font-family: LinotypeDidotRoman, serif;
    font-size: 40px;
`;

export const StepText = styled.div`
    margin: 20px -40px 24px -40px;
`;

export const StepImage = styled.div`
    position: relative;
    width: 226px;
    height: 466px;
    border-radius: 40px;
    box-shadow:
        0 162px 65px rgba(143, 143, 143, 0.01),
        0 91px 55px rgba(143, 143, 143, 0.05),
        0 40px 40px rgba(143, 143, 143, 0.09),
        0 10px 22px rgba(143, 143, 143, 0.1);

    img {
        position: absolute;
        left: -2px;
        top: 0;
        width: 230px;
        height: 466px;
        object-fit: contain;
    }
`;
