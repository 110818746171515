import './style.scss';

import { Button, Modal } from 'react-bootstrap';

import content from 'content.json';
import { useModal } from 'store/ui';
import { FC, useEffect } from 'react';
import { useDeactivateMutation } from 'store/user-service/user-api-slice';
import { logout } from 'store/user/actions';
import { useDispatch } from 'react-redux';

const { modal } = content;
const defaultErrorMessage = 'Something went wrong. Please retry or contact support.';

export const DeactivateAccount: FC = () => {
    const dispatch = useDispatch();
    const { hideModal } = useModal();
    const [deactivate, { isSuccess, error }] = useDeactivateMutation();

    useEffect(() => {
        if (isSuccess) {
            hideModal();
            logout(dispatch);
        }
    }, [isSuccess, hideModal, logout, dispatch]);

    const getErrorMessage = (error: any) => ('message' in error ? error.message : defaultErrorMessage);
    const message = error ? getErrorMessage(error) : modal.deactivate.text;

    const onClick = () => (error ? hideModal() : deactivate());

    const buttonText = error ? modal.deactivate.cancel : modal.deactivate.button;

    return (
        <span className="deactivate-account">
            <Modal.Header closeButton>
                <p>{modal.deactivate.title}</p>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={onClick}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </span>
    );
};
