import './style.scss';

import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Page, ToggleButton } from 'components';
import { FavoriteLooks } from './FavoriteLooks';
import { FavoriteStylists } from './FavoriteStylists';

import content from 'content.json';
const { favorites: texts } = content;

export const Favorites: FC = () => {
    const [section, setSection] = useState('looks');

    return (
        <Page footer={false}>
            <Container className="favorites">
                <Row>
                    <Col>
                        <h2 className="title">{texts.title}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ToggleButton toggles={texts.toggle} onChange={setSection} selected={section} />
                    </Col>
                </Row>
                {section === 'looks' && <FavoriteLooks />}
                {section === 'stylists' && <FavoriteStylists />}
            </Container>
        </Page>
    );
};
