import styled from 'styled-components';
import { getBreakpoint, getColor } from 'theme';

export const Container = styled.div`
    background: ${getColor('primaryLight')};
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 16px;
`;

export const HeaderSection = styled(Section)`
    gap: 24px;
    padding-top: 50px;

    @media screen and (${getBreakpoint('mobile')}) {
        padding-top: 16px;
    }
`;

export const SuggestionMessage = styled.div`
    width: 550px;
    padding: 24px 50px;
    border-radius: 24px;
    border: 1px solid ${getColor('gray400')};
    font-size: 18px;
    line-height: 140%;

    @media screen and (${getBreakpoint('mobile')}) {
        width: auto;
        padding: 24px;
    }

    span {
        display: block;

        @media screen and (${getBreakpoint('smallMobile')}) {
            display: inline;
        }
    }
`;

export const ScheduleSection = styled(Section)`
    background-color: ${getColor('primaryDark')};
`;

export const OurServicesSection = styled(Section)`
    background-color: ${getColor('beige')};
    padding: 0;
`;
