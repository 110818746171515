import styled from 'styled-components';
import { TitleH1 } from 'theme';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    width: 100%;
`;

export const BackButton = styled.div`
    left: 14px;
`;

export const StylistImage = styled.div`
    width: 78px;
    height: 78px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const StylistName = styled(TitleH1)`
    font-family: SabonLTPro-Italic;
    font-size: 24px;
    letter-spacing: 0.24px;
    text-align: center;
`;
