import styled, { keyframes } from 'styled-components';
import { getColor } from 'theme';

const anim = keyframes`
    from {
		background-position: -20% 0;
    }
    to {
        background-position: 200% 0;
    }
`;

export const Container = styled.div`
    div {
        background-color: ${getColor('gray100')};
        background-image: linear-gradient(90deg, transparent, ${getColor('primaryLight')} 50%, transparent 100%);
        background-size: 10% 100%;
        background-position: 0 0;
        background-attachment: fixed;
        background-repeat: no-repeat;
        animation: ${anim} 2.5s linear infinite;
    }
`;
