import styled from 'styled-components';
import { getBreakpoint, getColor, getFont, Paragraph } from 'theme';

import { Section } from './styles';

export const Container = styled(Section)`
    gap: 22px;
    padding: 100px 0;
    color: ${getColor('primaryLight')};
    background-color: ${getColor('primaryDark')};
    text-align: center;

    @media screen and (${getBreakpoint('desktop')}) {
        padding: 40px;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        padding: 40px 16px;
    }
`;

export const Title = styled.h1`
    font-family: ${getFont('primary')};
    font-size: 20px;
`;

export const Quote = styled(Paragraph)`
    font-family: ${getFont('title')};
    font-size: 30px;
    margin: 22px 0;
`;

export const OutletLogo = styled.img`
    height: 28px;
`;
