import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { plans } from 'consts';
import { useWithDispatch } from 'hooks';
import { GoalParams } from 'routes/Goals/types';
import { Config, Tracking } from 'services';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getSessionCheckoutUrl, urlParamToJson } from 'services/utils/url-utils';
import { setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { PrimaryButtonLink, SecondaryButtonLink } from 'theme';

import { ButtonVariantProps } from '../types';

export const CheckoutFlowButton: FC<ButtonVariantProps> = ({
    className,
    plan = 'major',
    goal = 'major',
    trackingSource
}) => {
    const [searchParams] = useSearchParams();
    const { stylistId, bookingSource } = urlParamToJson<GoalParams>(searchParams.get('booking'));
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);
    const { data: stylist } = useGetStylistQuery(stylistId || '', { skip: !stylistId });
    const navPath = getSessionCheckoutUrl({ stylistId, goal, plan, bookingSource });
    const planTitle = plans[plan].title;
    const planPrice = plans[plan].price;
    const Button = plan === goal ? PrimaryButtonLink : SecondaryButtonLink;

    const track = () => {
        setMixpanelStoreAction({
            onboarding: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: plan
            }
        });
        trackEvent({
            name: MP_EVENTS.PLAN_SELECTION,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: plan,
                ...bookingSourceToProps(bookingSource)
            }
        });

        Tracking.google({
            type: 'event',
            event: 'Funnel',
            data: { event_category: 'initiateCheckout' }
        });

        Tracking.facebook('track', 'InitiateCheckout', {
            device: Config.isMobile() ? 'mobile' : 'desktop',
            content_type: 'product',
            content_category: plan,
            content_ids: stylistId,
            currency: 'USD',
            num_items: 1,
            value: planPrice
        });

        klaviyoTrack(KlaviyoEvent.PLAN_CLICK, {
            goal,
            plan,
            stylistId: stylistId,
            stylistName: stylist?.name,
            stylistProfileImage: stylist?.profile_picture,
            targetUrl: `${location.origin}/login?target=${encodeURIComponent(navPath)}`
        });
    };

    return (
        <Button to={navPath} state={{ mpSource: trackingSource }} onClick={track} className={className}>
            Select {planTitle}
        </Button>
    );
};
