import { plans } from 'consts';

export const giftPlans = {
    mini: {
        title: `${plans.mini.title} Session`,
        label: plans.mini.name,
        image: '//media-cf.wishi.me/react/giftcards/mini_card.webp',
        content: '1:1 Chat with Stylist, 2 Style Boards, and Revisions',
        price: plans.mini.price
    },
    major: {
        title: `${plans.major.title} or Closet Clean Out`,
        label: plans.major.name,
        image: '//media-cf.wishi.me/react/giftcards/major_card.webp',
        content: '1:1 Chat with Stylist, 5 Style Boards, and Revisions',
        price: plans.major.price
    },
    lux: {
        title: `${plans.lux.title} Session`,
        label: plans.lux.name,
        image: '//media-cf.wishi.me/react/giftcards/lux_card.webp',
        content: 'Unlimited Chat, 8 Style Boards, and Revisions',
        price: plans.lux.price
    }
};
