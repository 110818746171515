import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWithDispatch } from 'hooks';
import { Style } from 'services';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { getMatchPageUrl } from 'services/utils/url-utils';
import { useIsLoggedIn, useUserId } from 'store/auth-service';
import { setMixpanelStore } from 'store/common/actions';
import { updateUser } from 'store/user/actions';

import { OnboardingState, StyleId } from './types';
import { useModal } from 'store/ui';

export const useOnboardingComplete = () => {
    const { showModal } = useModal();
    const isCompletedOnce = useRef(false);
    const userId = useUserId();
    const isLoggedIn = useIsLoggedIn();
    const updateUserAction = useWithDispatch(updateUser);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);
    const navigate = useNavigate();
    const [completeOnboardingState, setCompleteOnboardingState] = useState<OnboardingState>();

    // This function will run once on completion of onboarding
    // This is to prevent consecutive reporting on possible history.back or deferred completion
    const trackCompletionEvents = useCallback(
        (styles: [StyleId, number][]) => {
            if (!isCompletedOnce.current && styles.length) {
                const stylePreferredList = styles.filter(([, value]) => value === 2).map(([name]) => name);

                setMixpanelStoreAction({
                    onboarding: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                });

                trackEvent({
                    name: MP_EVENTS.MEET_MY_MATCH,
                    properties: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                });

                isCompletedOnce.current = true;
            }
        },
        [setMixpanelStoreAction]
    );

    const complete = useCallback(
        (onboardingState: OnboardingState) => {
            const { styles: stylesMap, source } = onboardingState;
            const styles = [...(stylesMap || [])];

            trackCompletionEvents(styles);
            setCompleteOnboardingState(onboardingState);

            if (!isLoggedIn) {
                showModal('Unlock', { source, element: 'take quiz click' });
            }
        },
        [isLoggedIn, showModal, trackCompletionEvents]
    );

    const updateUserAndNavigate = useCallback(async () => {
        if (completeOnboardingState && isLoggedIn) {
            const { needs: needsMap, department: gender, bodyTypes, styles: stylesMap } = completeOnboardingState;
            const needs = [...(needsMap?.keys() ?? [])];
            const styles = [...(stylesMap || [])];
            const tags = [...(bodyTypes?.values() || [])].map(({ uuid, name }) => ({
                tag_uuid: uuid,
                is_set: 1,
                name
            }));

            await Style.updateStyle(userId, {
                needs,
                gender,
                tag_uuids: tags,
                styles: styles.map(([name, rate]) => ({ name, rate }))
            });

            updateUserAction({ gender }); // This is to allow gendered stylist matches without re-fetching the user
            navigate(getMatchPageUrl());
        }
    }, [completeOnboardingState, isLoggedIn, updateUserAction, navigate, userId]);

    useEffect(() => {
        if (completeOnboardingState && isLoggedIn) {
            updateUserAndNavigate();
        }
    }, [completeOnboardingState, isLoggedIn, updateUserAndNavigate]);

    return complete;
};
