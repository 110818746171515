import './style.scss';

import { Button, Modal } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import content from 'content.json';
import { useDispatch } from 'react-redux';
import { removeItem } from 'redux/reducers/users/actions';
import { useModal } from 'store/ui';

const { modal } = content;

interface RemoveItemData {
    itemId: string;
}

export const RemoveItem = () => {
    const dispatch = useDispatch();
    const {
        data: { itemId },
        hideModal
    } = useModal<RemoveItemData>();

    const onSubmit = () => {
        try {
            dispatch(removeItem(itemId));
            hideModal();
        } catch (e) {
            sentryException(e as Error, "Couldn't remove item");
        }
    };

    return (
        <span className="delete-item">
            <Modal.Header>
                <p>{modal.deleteItem.title}</p>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="light" onClick={hideModal}>
                    {modal.deleteItem.cancel}
                </Button>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.deleteItem.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};
