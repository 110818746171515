import { FC } from 'react';
import { TitleH1 } from 'theme';

import { Container, Description, FeatureItem, Features, Image, Text, Title } from './WhatsIncluded-styles';

const includedFeatures = [
    {
        title: 'Any Brand & Your Closet',
        description:
            'Let’s make this clear: we work for you, not for any brand! You can ask your stylist to incorporate items from your closet or use brands you like, or let them introduce you to new brands.',
        imageUrl: 'https://media-cf.wishi.me/react/pricing/packages-feature-1.webp'
    },
    {
        title: 'Lifestyle Experts',
        description:
            'Our style sessions are not just about what to buy. It’s the confidence from an expert helping you every step of the way. From a hairstyle to a lipstick shade to getting rid of a worn out sweater, you can chat with our stylists via our app on all your decisions.',
        imageUrl: 'https://media-cf.wishi.me/react/pricing/packages-feature-2.webp'
    },
    {
        title: 'Choice to Switch It Up!',
        description:
            'Our style match has a high success rate, but if you’re not happy with your stylist you can switch at any time with no additional cost.',
        imageUrl: 'https://media-cf.wishi.me/react/pricing/packages-feature-3.webp'
    }
];

export const WhatsIncluded: FC = () => {
    return (
        <Container>
            <TitleH1>All Plans Include</TitleH1>
            <Features>
                {includedFeatures.map(({ title, description, imageUrl }, index) => (
                    <FeatureItem key={index}>
                        <Image src={imageUrl} />
                        <Text>
                            <Title>{title}</Title>
                            <Description>{description}</Description>
                        </Text>
                    </FeatureItem>
                ))}
            </Features>
        </Container>
    );
};
