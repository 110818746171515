import { FC } from 'react';

import { Container, Shadow } from './ImageWithShadow-styles';

interface ImageWithShadowProps {
    className?: string;
    src: string;
    alt?: string;
}

export const ImageWithShadow: FC<ImageWithShadowProps> = ({ className, src, alt }) => (
    <Container className={className}>
        <img src={src} alt={alt} />
        <Shadow />
    </Container>
);
