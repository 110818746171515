import './style.scss';

import { Button, Modal } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import content from 'content.json';
import { removeLook } from 'redux/reducers/users/actions';
import { useModal } from 'store/ui';

const { modal } = content;

interface RemoveLookData {
    lookId: string;
    type: string;
}

export const RemoveLook = () => {
    const { data, hideModal } = useModal<RemoveLookData>();

    const onSubmit = () => {
        try {
            removeLook(data.lookId, { type: data.type });
            hideModal();
        } catch (e) {
            sentryException(e as Error, "Couldn't remove look");
        }
    };

    return (
        <span className="remove-look">
            <Modal.Header>
                <p>{modal.removeLook.title}</p>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="light" onClick={hideModal}>
                    {modal.removeLook.cancel}
                </Button>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.removeLook.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};
