import { FC } from 'react';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getStylistProfileUrl, getStylistSearchUrl } from 'services/utils/url-utils';
import { MatchedStylist } from 'store/user-service/user-types';
import { TitleH1 } from 'theme';

import {
    Container,
    Location,
    Match,
    MeetButton,
    Name,
    OtherStylistsButton,
    Picture,
    Stylist,
    StylistsContainer
} from './OtherStylists-styles';

interface OtherStylistsProps {
    bestMatch: MatchedStylist;
    otherMatches: MatchedStylist[];
}

export const OtherStylists: FC<OtherStylistsProps> = ({ bestMatch, otherMatches }) => {
    const trackMeetStylistClick = (stylistName: string) => {
        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: {
                [MP_PROPS.STYLIST_NAME]: stylistName,
                [MP_PROPS.IS_STYLIST_MATCH]: true,
                [MP_PROPS.SOURCE]: MP_VALUES.SINGLE_MATCH
            }
        });
    };

    const trackOtherStylistsClick = () => {
        trackEvent({
            name: MP_EVENTS.VIEW_MORE_STYLISTS_CLICK,
            properties: {
                [MP_PROPS.STYLIST_NAME]: bestMatch.name,
                [MP_PROPS.SOURCE]: MP_VALUES.SINGLE_MATCH
            }
        });
    };

    return (
        <Container>
            <TitleH1>Other Stylists Recommended for You</TitleH1>
            <StylistsContainer>
                {otherMatches.map(({ uuid, name, first_name, profile_picture, location, match_percentage = 0 }) => (
                    <Stylist key={uuid} to={getStylistProfileUrl(uuid)} onClick={() => trackMeetStylistClick(name)}>
                        <Picture src={profile_picture} />
                        <div>
                            <Name>{first_name}</Name>
                            <Location>{location}</Location>
                            {match_percentage >= 90 && <Match>{match_percentage}%</Match>}
                        </div>
                        <MeetButton>Meet {first_name}</MeetButton>
                    </Stylist>
                ))}
            </StylistsContainer>
            <OtherStylistsButton to={getStylistSearchUrl()} onClick={trackOtherStylistsClick}>
                View More Stylists
            </OtherStylistsButton>
        </Container>
    );
};
