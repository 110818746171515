import content from 'content.json';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'store/ui';

const texts = content.settings;

export const Main: React.FC = () => {
    const navigate = useNavigate();
    const { showModal } = useModal();

    const onDeactivateClick = () => {
        showModal('DeactivateAccount');
    };

    return (
        <Container className="settings-main" fluid>
            <Row>
                <Col>
                    <h3>{texts.title}</h3>
                </Col>
            </Row>
            <Container>
                <Row>
                    {texts.sections.map(({ key, title, description }) => (
                        <Col xs={12} md={4} key={key}>
                            <Card onClick={() => navigate(`/settings/${key}`)}>
                                <Card.Title>
                                    <div>{title}</div>
                                    <div className="arrow right" />
                                </Card.Title>
                                <Card.Body>{description}</Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div className="deactivate" onClick={onDeactivateClick}>
                    {texts.deactivate}
                </div>
            </Container>
        </Container>
    );
};
