import { ActionTypes as ErrorActionTypes, Actions as ErrorActions } from './error/types';
import { ActionTypes as CommonActionTypes, Actions as CommonActions } from './common/types';
import { ActionTypes as UserActionTypes, Actions as UserActions } from './user/types';
import { ActionTypes as BookingActionTypes, Actions as BookingActions } from './booking/types';
import { ActionTypes as PaymentsActionTypes, Actions as PaymentsActions } from './payments/types';
import { ActionTypes as ServicesActionTypes, Actions as ServicesActions } from './services/types';

export const ActionTypes = {
    ...ErrorActionTypes,
    ...CommonActionTypes,
    ...UserActionTypes,
    ...BookingActionTypes,
    ...PaymentsActionTypes,
    ...ServicesActionTypes
};

export type Actions = ErrorActions | CommonActions | UserActions | BookingActions | PaymentsActions | ServicesActions;
