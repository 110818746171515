import { rootSplitApi } from 'store/root-api-slice';

import {
    GetClosetSuggestionsRequest,
    GetClosetSuggestionsResponse,
    GetClosetSuggestionsTransformedResponse,
    GetStylingSessionPropsRequest,
    GetStylingSessionPropsResponse,
    GetStylingSessionPropsTransformedResponse
} from './styling-session-api-types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['ClosetSuggestions', 'SessionProps'] });

const extendedApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getClosetSuggestions: build.query<GetClosetSuggestionsTransformedResponse, GetClosetSuggestionsRequest>({
            query: ({ sessionId, count = 250 }) => `session/${sessionId}/closet-suggestions?count=${count}`,
            transformResponse: ({ items, pageInfo }: GetClosetSuggestionsResponse) => ({
                items: items.map(({ unique, title, pictureUrl }) => ({ id: unique, title, pictureUrl })),
                totalItemsCount: pageInfo.totalItems
            }),
            providesTags: ['ClosetSuggestions']
        }),
        getStylingSessionProps: build.query<GetStylingSessionPropsTransformedResponse, GetStylingSessionPropsRequest>({
            query: ({ sessionId }) => `session/${sessionId}`,
            transformResponse: ({ sessionInfo }: GetStylingSessionPropsResponse) => ({
                stylistId: sessionInfo.stylistUuid,
                stylistName: sessionInfo.stylist,
                plan: sessionInfo.plan
            }),
            providesTags: ['SessionProps']
        })
    })
});

export const { useGetClosetSuggestionsQuery, useGetStylingSessionPropsQuery } = extendedApi;
