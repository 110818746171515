import { FC, useCallback, useEffect, useRef } from 'react';
import { useMediaQuery } from 'hooks';
import { getBreakpoint } from 'theme';

import { ButtonContainer, Container, Cta } from './StickyContainer-styles';

export const StickyContainer: FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery(getBreakpoint('tablet'));

    const onScroll = useCallback(() => {
        const container = containerRef.current;
        const buttonContainer = buttonContainerRef.current;

        if (container && buttonContainer) {
            const containerTop = container.getBoundingClientRect().y;
            buttonContainer.style.transform = `translateY(${Math.max(containerTop + 30, 0)}px)`;
        }
    }, []);

    useEffect(() => {
        if (isMobile) {
            document.addEventListener('scroll', onScroll);

            return () => document.removeEventListener('scroll', onScroll);
        }
    }, [isMobile, onScroll]);

    return (
        <Container ref={containerRef}>
            <ButtonContainer ref={buttonContainerRef}>
                <Cta trackingElement="lux mobile sticky">Get Started</Cta>
            </ButtonContainer>
        </Container>
    );
};
