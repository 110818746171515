import './style.scss';

import React from 'react';
import { ItemType } from 'types/item';

import content from 'content.json';
import { Formatter } from 'services';

const texts = content.item;

interface ItemDetailsProps {
    item?: ItemType;
}

export const ItemDetails: React.FC<ItemDetailsProps> = ({ item }) => {
    if (!item) {
        return <></>;
    }

    const hasDiscount = item.sale_price && parseInt(item.sale_price) < parseInt(item.retail_price);
    const discountText = texts.payment.discount.replace(
        '%price%',
        Formatter.price(parseInt(item.retail_price) - parseInt(item.sale_price ?? ''))
    );

    return (
        <div className="details">
            <div className="brand">{item.brand_name}</div>
            <div className="name">{item.name}</div>
            <div className="prices">
                {item.retail_price && parseInt(item.retail_price) > 0 && (
                    <p className={`price ${item.sale_price ? ' line-through' : ''}`}>
                        {Formatter.price(item.retail_price)}
                    </p>
                )}
                {hasDiscount && (
                    <>
                        <p className="price">{Formatter.price(item.sale_price)}</p>
                        <p className="price discount">{discountText}</p>
                    </>
                )}
            </div>
        </div>
    );
};
