import { FC } from 'react';

import { SectionContainer, SectionContent, SectionTitle } from '../components';

import { StyledFaqAccordion } from './Faq-styles';

export const Faq: FC = () => {
    return (
        <SectionContainer>
            <SectionContent>
                <SectionTitle>Your Questions, Answered</SectionTitle>
                <StyledFaqAccordion />
            </SectionContent>
        </SectionContainer>
    );
};
