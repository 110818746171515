import './style.scss';

import { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Page, Cart } from 'components';
import content from 'content.json';
import { ShoppingFilter } from './ShoppingFilter';
import { useCart } from 'hooks';
import { getStylistSearchUrl } from 'services/utils/url-utils';

const { shopping } = content;

export interface ShoppingListFilter {
    key: string;
    value: { sort: string; order: 'asc' | 'desc' };
}

export const ShoppingList = () => {
    const [filter, setFilter] = useState<ShoppingListFilter>();
    const { sort: orderBy, order } = filter?.value || {};
    const { itemsCount } = useCart();

    return (
        <Page className="shopping-list" footer={false}>
            <Container fluid>
                <Row>
                    <Col>
                        <p className="title">{shopping.title}</p>
                    </Col>
                </Row>
                <Row>
                    {itemsCount === 0 ? (
                        <Col className="empty">
                            <Image src={shopping.icons.emptyCart} />
                            <p className="nothing">{shopping.nothing}</p>
                            <p>{shopping.let}</p>
                            <Link to={getStylistSearchUrl()}>
                                <Button variant="dark">{shopping.browse}</Button>
                            </Link>
                        </Col>
                    ) : (
                        <ShoppingFilter filter={filter} setFilter={setFilter} />
                    )}
                </Row>

                <Cart orderBy={orderBy} order={order} />
            </Container>
        </Page>
    );
};
