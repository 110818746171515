import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { useGetClosetSuggestionsQuery } from 'store/styling-session-service';

import { NoResults, Results } from './components';
import { Container, StyledPage } from './SessionAddToCloset-styles';

export const SessionAddToCloset: FC = () => {
    const { sessionId = '' } = useParams<{ sessionId: string }>();
    const { data: suggestionsData, isFetching, isError } = useGetClosetSuggestionsQuery({ sessionId });
    const showResults = isFetching || isError || suggestionsData?.items.length;

    useEffect(() => {
        if (sessionId) {
            trackEvent({
                name: MP_EVENTS.ADD_TO_CLOSET_PAGE_VIEW,
                properties: { [MP_PROPS.SESSION_ID]: sessionId }
            });
        }
    }, [sessionId]);

    return (
        <StyledPage>
            <Container>{showResults ? <Results sessionId={sessionId} /> : <NoResults />}</Container>
        </StyledPage>
    );
};
