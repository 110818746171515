import styled from 'styled-components';
import { getBreakpoint, getColor } from 'theme';

import { StylingButton } from 'components';

export const Container = styled.div``;

export const ButtonContainer = styled.div`
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: ${getColor('primaryLight')};
    border-top: 1px solid ${getColor('gray200')};
    z-index: 2;
    transform: translateY(70px);

    @media screen and (${getBreakpoint('tablet')}) {
        display: flex;
    }
`;

export const Cta = styled(StylingButton)`
    width: 320px;
`;
