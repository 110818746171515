import './ExpressCheckout.scss';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FC } from 'react';
import { Payments } from 'services';
import { useIsLoggedIn } from 'store/auth-service';

import { ExpressCheckoutButton } from './ExpressCheckoutButton';
import { getElementsOptions } from './helpers';
import { ExpressCheckoutProps } from './types';
import { useModal } from 'store/ui';

const stripe = loadStripe(Payments.getStripeKey() as string);

export const ExpressCheckout: FC<ExpressCheckoutProps> = (props) => {
    const { showModal } = useModal();
    const { item, itemSize } = props;
    const isDisabled = !item || !itemSize;
    const isLoggedIn = useIsLoggedIn();
    const options = getElementsOptions(props);

    const showSignupDialog = () => {
        showModal('Signup', {
            redirectUrl: `${location.pathname}${location.search}`,
            element: 'pdp express checkout'
        });
    };

    return (
        <div className={`express-checkout-container ${isDisabled ? 'disabled' : ''}`}>
            <Elements stripe={stripe} options={options}>
                <ExpressCheckoutButton {...props} />
            </Elements>
            {!isLoggedIn && <div className="login-opener" onClick={showSignupDialog}></div>}
        </div>
    );
};
