import styled from 'styled-components';
import { getBreakpoint, getColor, Paragraph, TitleH2 } from 'theme';

import { StylingButton } from 'components';

import { ImageWithShadow } from '../components';

import { Section } from './styles';

export const Container = styled(Section)`
    padding: 80px 0;
    background-color: ${getColor('primaryLight')};

    @media screen and (${getBreakpoint('desktop')}) {
        padding: 40px;
    }
`;

export const Features = styled.div`
    display: flex;
    width: 100%;
    max-width: 1520px;
    margin: 60px 0;

    @media screen and (${getBreakpoint('desktop')}) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 60px;
    }
`;

export const FeatureItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 3);
    text-align: center;

    @media screen and (${getBreakpoint('desktop')}) {
        width: calc(50% - 30px);
    }

    @media screen and (${getBreakpoint('tablet')}) {
        width: 100%;
    }
`;

export const Image = styled(ImageWithShadow)`
    width: 196px;
    height: 398px;
    object-fit: cover;
`;
export const Title = styled(TitleH2)`
    font-size: 30px;
    margin-top: 32px;

    @media screen and (${getBreakpoint('tablet')}) {
        margin-top: 12px;
    }
`;
export const Description = styled(Paragraph)`
    margin-top: 12px;
    max-width: 400px;
    white-space: pre-wrap;

    @media screen and (${getBreakpoint('desktop')}) {
        max-width: none;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        margin-top: 4px;
    }
`;

export const Cta = styled(StylingButton)`
    width: 335px;

    @media screen and (${getBreakpoint('tablet')}) {
        display: none;
    }
`;
