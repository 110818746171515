import './style.scss';

import { Image } from 'react-bootstrap';

import content from 'content.json';
import { FC } from 'react';

const { logo } = content;

export const Loader: FC = () => (
    <div className="loader">
        <Image src={logo.w} />
        <div className="spinner" />
    </div>
);
