import { FC } from 'react';
import { TitleH1 } from 'theme';

import { stylingProcessSteps } from '../content';

import { Container, Description, StepItem, Steps, Image, Title } from './StylingProcess-styles';

export const StylingProcess: FC = () => {
    return (
        <Container>
            <TitleH1>The Styling Process</TitleH1>
            <Steps>
                {stylingProcessSteps.map(({ title, description, imageUrl }, index) => (
                    <StepItem key={index}>
                        <Image src={imageUrl} alt={title} />
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </StepItem>
                ))}
            </Steps>
        </Container>
    );
};
