import { me, rootSplitApi } from 'store/root-api-slice';

import { AddItemsToClosetRequest, AddItemsToClosetResponse } from './closet-api-types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['ClosetSuggestions'] });

const extendedApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        addItemsToCloset: build.mutation<AddItemsToClosetResponse, AddItemsToClosetRequest>({
            query: ({ ids, source }) => ({
                url: `proxy/user/${me}/items`,
                method: 'POST',
                body: {
                    item_uuids: ids,
                    source
                }
            })
        })
    })
});

export const { useAddItemsToClosetMutation } = extendedApi;
