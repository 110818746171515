import { connect } from 'react-redux';
import { setModel } from 'store/payments/actions';

import { clearResult } from 'redux/reducers/payments/actions';
import { GoalsHeader } from './GoalsHeader';
import { StylingSessionGoalsPage as Goals } from './StylingSessionGoalsPage';

const mapStateToProps = ({ booking: { campaign }, payments: { mobileCollapse }, common: { mixpanelStore } }) => ({
    mobileCollapse,
    campaign,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    clearModel: () => setModel(dispatch),
    clearResult: () => dispatch(clearResult()),
    setModel: (model) => setModel(dispatch, model)
});

export { GoalsHeader };
export const StylingSessionGoalsPage = connect(mapStateToProps, mapDispatchToProps)(Goals);
export * from './StylingSessionCheckout';
