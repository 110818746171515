import { FC, useState } from 'react';
import { useIsMobile } from 'hooks';
import { Config } from 'services';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { useAddItemsToClosetMutation } from 'store/closet-service';
import { useGetClosetSuggestionsQuery, useGetStylingSessionPropsQuery } from 'store/styling-session-service';
import { TitleH1 } from 'theme';

import { Loader } from 'components';

import {
    AddToClosetButton,
    ButtonContainer,
    Checkbox,
    Container,
    ErrorMessage,
    ErrorSkeleton,
    Item,
    ItemImage,
    ItemsContainer,
    ItemTitle,
    Skeleton,
    Subtitle
} from './styles';

interface ResultsProps {
    sessionId: string;
}

export const Results: FC<ResultsProps> = ({ sessionId }) => {
    const [selectedItemIds, setSelectedItemIds] = useState<Set<string>>(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const {
        data: suggestionsData,
        isFetching: isFetchingSuggestions,
        isError: isErrorFetchingSuggestions
    } = useGetClosetSuggestionsQuery({ sessionId });
    const { data: sessionProps } = useGetStylingSessionPropsQuery({ sessionId });
    const [addItemsToCloset, { isError: isErrorAddItemsToCloset }] = useAddItemsToClosetMutation();
    const addToClosetButtonText = `Add ${selectedItemIds.size || ''} Item${selectedItemIds.size !== 1 ? 's' : ''} to Closet`;
    const skeletonItemsCount = useIsMobile() ? 4 : 10;
    const isError = isErrorFetchingSuggestions || isErrorAddItemsToCloset;

    const toggleSelected = (itemId: string) => {
        setSelectedItemIds((prevState) => {
            const newState = new Set(prevState);
            prevState.has(itemId) ? newState.delete(itemId) : newState.add(itemId);

            return newState;
        });
    };

    const addToCloset = async () => {
        setIsLoading(true);
        const { error } = await addItemsToCloset({ ids: [...selectedItemIds], source: 'session_recap' });

        if (error) {
            setIsLoading(false);
        } else {
            trackEvent({
                name: MP_EVENTS.ADD_TO_CLOSET_CLICK,
                properties: {
                    [MP_PROPS.STYLIST_NAME]: sessionProps?.stylistName,
                    [MP_PROPS.PLAN_TYPE]: sessionProps?.plan,
                    [MP_PROPS.ITEM_COUNT]: suggestionsData?.totalItemsCount,
                    [MP_PROPS.SELECTION_COUNT]: selectedItemIds.size,
                    [MP_PROPS.SOURCE]: 'session recap'
                }
            });

            window.location.href = Config.get('appsFlyerClosetUrl');
        }
    };

    return (
        <Container>
            {isLoading && <Loader />}
            <TitleH1>Keep Your Closet Up to Date with Your Purchases</TitleH1>
            <Subtitle>Select the items you purchased (or similar ones):</Subtitle>
            {isError && <ErrorMessage>An error occurred, please try again later.</ErrorMessage>}
            {isFetchingSuggestions ? (
                <Skeleton itemsCount={skeletonItemsCount} />
            ) : isErrorFetchingSuggestions ? (
                <ErrorSkeleton itemsCount={skeletonItemsCount} />
            ) : (
                <ItemsContainer>
                    {suggestionsData?.items.map(({ id, title, pictureUrl }) => (
                        <Item key={id} onClick={() => toggleSelected(id)} $isSelected={selectedItemIds.has(id)}>
                            <ItemImage src={pictureUrl} alt={title} />
                            <Checkbox $checked={selectedItemIds.has(id)} />
                            <ItemTitle>{title}</ItemTitle>
                        </Item>
                    ))}
                </ItemsContainer>
            )}
            <ButtonContainer>
                <AddToClosetButton disabled={!selectedItemIds.size} onClick={addToCloset}>
                    {addToClosetButtonText}
                </AddToClosetButton>
            </ButtonContainer>
        </Container>
    );
};
