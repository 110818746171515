import './style.scss';

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import { FavoriteButton } from 'components';
import { getStylistProfileUrl } from 'services/utils/url-utils';
import { Stylist } from 'types/user';
import { useFavoriteStylistMutation, useUnfavoriteStylistMutation } from 'store/user-service/user-api-slice';

import content from 'content.json';
const { match: texts } = content;

interface StylistCardProps {
    stylist: Stylist;
    matchPercentage?: number;
    isFavorite?: boolean;
    onClick: (match: Stylist) => void;
}

export const StylistCard: FC<StylistCardProps> = ({ stylist, isFavorite = false, onClick, matchPercentage }) => {
    const [removeFromFavorites] = useUnfavoriteStylistMutation();
    const [addToFavorites] = useFavoriteStylistMutation();
    return (
        <Container id="match" className="match-item" fluid>
            <FavoriteButton
                isFavorite={isFavorite}
                onClick={() => {
                    isFavorite
                        ? removeFromFavorites({ stylistId: stylist.uuid })
                        : addToFavorites({ stylistId: stylist.uuid });
                }}
                expandable={true}
                filled={true}
            />
            <span>
                <Link
                    to={getStylistProfileUrl(stylist.uuid)}
                    state={{ from: window.location.pathname }}
                    onClick={(event) => {
                        event.preventDefault();
                        onClick(stylist);
                    }}
                    id={'matchItem'}
                    className="black"
                >
                    <Row className="moodboard">
                        <Col className="no-padding">
                            <Image src={stylist.moodboard} fluid />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="profileImage">
                            <Image src={stylist.profile_picture} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="name">{stylist.name}</Col>
                    </Row>

                    {stylist.location && (
                        <Row>
                            <Col className="location">{stylist.location}</Col>
                        </Row>
                    )}
                    {matchPercentage && (
                        <Row>
                            <Col className="percentage">{matchPercentage}% Match</Col>
                        </Row>
                    )}
                    <Row>
                        <Button variant="warning" id="meet" data-test-id="meet-stylist" onClick={() => {}}>
                            {texts.button} {stylist.first_name}
                        </Button>
                    </Row>
                </Link>
            </span>
        </Container>
    );
};
