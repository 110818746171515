import { FC } from 'react';
import { Col } from 'react-bootstrap';
import { StylistCard } from 'components';
import { MatchedStylist } from 'store/user-service/user-types';
import { Stylist } from 'types/user';
import { Tracking } from 'services';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { useNavigate } from 'react-router-dom';
import { getStylistProfileUrl } from 'services/utils/url-utils';

interface MatchCardProps {
    stylist: MatchedStylist;
    isFavorite: boolean;
}

export const MatchCard: FC<MatchCardProps> = ({ stylist, isFavorite }) => {
    const navigate = useNavigate();
    const onClick = ({ uuid, name }: Stylist) => {
        Tracking.tag({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Other Recommended Stylists' },
                    products: [
                        {
                            id: uuid,
                            name,
                            category: '/category/stylist/'
                        }
                    ]
                }
            }
        });
        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: {
                [MP_PROPS.STYLIST_NAME]: name,
                [MP_PROPS.STYLIST_UUID]: uuid,
                [MP_PROPS.IS_STYLIST_MATCH]: true,
                [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLIST_PROFILE_PAGE
            }
        });

        navigate(getStylistProfileUrl(uuid));
    };

    return (
        <Col className="match-item-container" xs={12} md={4} key={stylist.uuid}>
            <StylistCard
                stylist={stylist}
                matchPercentage={stylist.match_percentage}
                onClick={onClick}
                isFavorite={isFavorite}
            />
        </Col>
    );
};
