import { FC } from 'react';

import { Schedule as ScheduleComponent } from 'components';

import { Container } from './Schedule-styles';

export const Schedule: FC = () => {
    return (
        <Container>
            <ScheduleComponent />
        </Container>
    );
};
