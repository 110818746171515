import { Children, FC, isValidElement, ReactNode, useEffect, useRef, useState } from 'react';

import { handleViewPortScrolling, scrollToSlideElement } from './helpers';
import { Container, NavDot, NavDotsContainer, NavNext, NavPrev, Slide, ViewPort } from './PagingCarousel-styles';

interface PagingCarouselProps {
    children: ReactNode;
    className?: string;
    onSlideChange?: (slideIndex: number) => void;
    slidesToAdvanceOnPaging?: number;
    slideIndex?: number;
}

export const PagingCarousel: FC<PagingCarouselProps> = ({
    children,
    className,
    onSlideChange = () => {},
    slidesToAdvanceOnPaging = 1,
    slideIndex = 0
}) => {
    const viewPortRef = useRef<HTMLDivElement>(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(slideIndex);
    const slides = Children.toArray(children).filter((child) => isValidElement(child));

    const scrollToSlide = (slideIndex: number) => scrollToSlideElement(viewPortRef.current!, slideIndex);

    const prev = () => scrollToSlide(currentSlideIndex - slidesToAdvanceOnPaging);

    const next = () => scrollToSlide(currentSlideIndex + slidesToAdvanceOnPaging);

    const navigateToSlide = (slideIndex: number) => scrollToSlide(slideIndex);

    const handleScroll = () => {
        handleViewPortScrolling(viewPortRef.current!, (slideIndex) => {
            if (slideIndex !== currentSlideIndex) {
                setCurrentSlideIndex(slideIndex);
                onSlideChange(slideIndex);
            }
        });
    };

    useEffect(() => {
        viewPortRef?.current && scrollToSlide(slideIndex);
    }, [slideIndex]);

    return (
        <Container className={className}>
            <NavDotsContainer>
                {slides.map((child, index) => (
                    <NavDot
                        key={index}
                        $selected={index === currentSlideIndex}
                        onClick={() => navigateToSlide(index)}
                    />
                ))}
            </NavDotsContainer>
            <NavPrev onClick={prev} />
            <ViewPort ref={viewPortRef} onScroll={handleScroll}>
                {slides.map((child, index) => (
                    <Slide key={index}>{child}</Slide>
                ))}
            </ViewPort>
            <NavNext onClick={next} />
        </Container>
    );
};
