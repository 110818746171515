import { FC, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { plans } from 'consts';
import { PlanParams } from 'routes/Goals/types';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getMatchPageUrl, urlParamToJson } from 'services/utils/url-utils';
import { useCommonStore } from 'store/common/reducer';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';

import { Loader, OurServices, Page, PlansNew, Schedule, SessionCheckoutHeader } from 'components';

import { HeaderSection, OurServicesSection, ScheduleSection, SuggestionMessage } from './BookingPlans-styles';
import { goalSuggestionTexts } from './consts';

export const BookingPlans: FC = () => {
    const isInitialized = useRef(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { stylistId, goal, bookingSource } = urlParamToJson<PlanParams>(searchParams.get('booking'));
    const { data: stylist } = useGetStylistQuery(stylistId || '', { skip: !stylistId });
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);

    useEffect(() => {
        if (!isInitialized.current) {
            klaviyoTrack(KlaviyoEvent.PLAN_VIEW);

            if (stylistId) {
                trackEvent({
                    name: MP_EVENTS.PLAN_VIEWS,
                    properties: {
                        ...mixpanelStore.onboarding,
                        ...bookingSourceToProps(bookingSource)
                    }
                });
            } else {
                navigate(getMatchPageUrl());
            }

            isInitialized.current = true;
        }
    }, [stylistId, mixpanelStore, bookingSource, navigate]);

    if (!stylist) {
        return null;
    }

    return (
        <Page footer={false} header={false}>
            <HeaderSection>
                <SessionCheckoutHeader stylistId={stylistId} />
                <SuggestionMessage>
                    <span>
                        Got it! I recommend to choose <b>{plans[goal].title}</b>.&nbsp;
                    </span>
                    <span>{goalSuggestionTexts[goal]}</span>
                </SuggestionMessage>
                <PlansNew recommendedPlan={goal} availablePlans={goal === 'lux' ? ['major', 'lux'] : undefined} />
            </HeaderSection>
            <OurServicesSection>
                <OurServices />
            </OurServicesSection>
            <ScheduleSection>
                <Schedule />
            </ScheduleSection>
        </Page>
    );
};
