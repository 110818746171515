import checkIcon from 'assets/check.svg';
import styled, { css } from 'styled-components';
import { getBreakpoint, getColor, PrimaryButton, PrimaryButtonLink } from 'theme';

import { SkeletonLoader } from 'components';

export const Container = styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    max-width: 1035px;
    min-width: 823px;
    padding: 40px 60px 24px 60px;
    border: 1px solid ${getColor('gray200')};
    background: ${getColor('primaryLight')};
    border-radius: 12px;
    box-shadow:
        -31px 186px 76px rgba(0, 0, 0, 0.01),
        -17px 105px 64px rgba(0, 0, 0, 0.02),
        -8px 47px 47px rgba(0, 0, 0, 0.04),
        -2px 12px 26px rgba(0, 0, 0, 0.04);

    @media screen and (${getBreakpoint('smallMobile')}) {
        flex-grow: 1;
        min-width: auto;
        width: 100%;
        padding: 40px 16px 24px 16px;
        margin: 0;
        box-shadow: none;
        border-radius: 0;
    }
`;

export const Subtitle = styled.div`
    margin-top: 12px;
    text-align: center;
`;

export const ItemsContainer = styled.form`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
`;

export const Item = styled.div<{ $isSelected?: boolean }>`
    position: relative;
    width: 160px;
    height: 100%;
    padding: 10px;
    border: 1px solid ${({ $isSelected }) => getColor($isSelected ? 'primaryDark' : 'gray300')};
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
`;

export const ItemImage = styled.img`
    display: block;
    width: 100%;
    height: 186px;
    object-fit: contain;
    margin-bottom: 10px;
`;

export const ItemTitle = styled.div`
    line-height: 140%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    max-height: 46px;
`;

export const Checkbox = styled.div<{ $checked: boolean }>`
    position: absolute;
    right: 16px;
    top: 16px;
    width: 20px;
    height: 20px;
    border: 1px solid ${getColor('gray500')};
    background: ${getColor('gray100')};
    border-radius: 5px;

    ${({ $checked }) =>
        $checked &&
        css`
            background: ${getColor('gray500')} url(${checkIcon}) center no-repeat;
        `}
`;

export const ButtonContainer = styled.div`
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 0;
    background: ${getColor('primaryLight')};
`;
export const AddToClosetButton = styled(PrimaryButton)`
    width: 100%;
    max-width: 304px;
`;

export const GoToClosetButton = styled(PrimaryButtonLink)`
    width: 100%;
    max-width: 304px;
`;

export const Skeleton = styled(SkeletonLoader)`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    margin-top: 32px;

    div {
        width: 160px;
        height: 236px;
    }
`;

export const ErrorMessage = styled.div`
    color: ${getColor('error')};
    margin-top: 32px;
`;

export const ErrorSkeleton = styled(Skeleton)`
    position: relative;

    &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, ${getColor('primaryLight')}, transparent 50%);
        content: '';
    }

    div {
        animation: none;
        background-image: none;
    }
`;
