import { FC } from 'react';

import { Container, OutletLogo, Quote, Title } from './Press-styles';

export const Press: FC = () => {
    return (
        <Container>
            <Title>What Lux is Good For</Title>
            <Quote>
                <q>For people who are busy, who don’t want to think about what to wear...</q>
            </Quote>
            <OutletLogo src="//media-cf.wishi.me/react/landing-new/press-logos/vogue.webp" alt="Vogue" />
        </Container>
    );
};
