import { FC, useMemo } from 'react';
import { useMediaQuery } from 'hooks';
import { getBreakpoint } from 'theme';

import { DefaultView, MobileView } from './components';
import { extendedPlans } from './consts';
import { PlansProps } from './types';

export const Plans: FC<PlansProps> = ({ availablePlans, ...props }) => {
    const isMobile = useMediaQuery(getBreakpoint('tablet'));
    const View = isMobile ? MobileView : DefaultView;
    const plans = useMemo(
        () => (availablePlans ? extendedPlans.filter(({ planId }) => availablePlans.includes(planId)) : extendedPlans),
        [availablePlans]
    );

    return <View plans={plans} {...props} />;
};
