import styled from 'styled-components';
import { getColor } from 'theme';

import { PlansNew } from 'components';

export const Container = styled.div`
    background: ${getColor('background')};
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 16px;
    background-color: ${getColor('beige')};
`;

export const StyledPlans = styled(PlansNew)`
    margin-top: 40px;
`;

export const ScheduleSection = styled(Section)`
    background-color: ${getColor('primaryDark')};
`;

export const OurServicesSection = styled(Section)`
    padding: 0;
`;
