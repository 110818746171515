import { combineReducers } from 'redux';
import paymentsReducer from 'redux/reducers/payments';
import styleReducer from 'redux/reducers/style';
import { name as stylistsName, stylistsReducer } from 'redux/reducers/stylists';
import usersReducer from 'redux/reducers/users';

import authSlice from './auth-service/auth-slice';
import featuresSlice from './features-service/features-slice';
import uiSlice from './ui/ui-slice';
import { bookingReducer, name as bookingName } from './booking/reducer';
import { commonReducer, name as commonName } from './common/reducer';
import { errorReducer, name as errorName } from './error/reducer';
import { name as paymentName, paymentReducer } from './payments/reducer';
import { name as servicesName, servicesReducer } from './services/reducer';
import newStylistSlice from './stylist-service/stylist-slice';
import profileSlice from './profile';
import { name as userName, userReducer } from './user/reducer';
import { rootSplitApi } from './root-api-slice';

export const rootReducer = combineReducers({
    [commonName]: commonReducer,
    [userName]: userReducer,
    [bookingName]: bookingReducer,
    [errorName]: errorReducer,
    [paymentName]: paymentReducer,
    [servicesName]: servicesReducer,
    users: usersReducer,
    style: styleReducer,
    [stylistsName]: stylistsReducer,
    payments: paymentsReducer,
    [rootSplitApi.reducerPath]: rootSplitApi.reducer,
    newStylistSlice,
    profileSlice,
    authSlice,
    featuresSlice,
    uiSlice
});
