import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import content from 'content.json';
import { useWithDispatch } from 'hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Validator } from 'services';
import { LdEvent } from 'services/LaunchDarkly';
import { resetError } from 'store/error/actions';
import { useErrorStore } from 'store/error/reducer';
import { signup as signupAction } from 'store/user/actions';
import { ValidationError } from 'types/utils';

import { GoogleLogin, Loader } from 'components';

import './style.scss';

const { modal } = content;

interface SignupComponentProps {
    onSigninClick: () => void;
}

export const SignupComponent: React.FC<SignupComponentProps> = ({ onSigninClick }) => {
    const navigate = useNavigate();
    const isInitialized = useRef(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<ValidationError>({});
    const texts = modal.signup;
    const ldClient = useLDClient();
    const storedErrors = useErrorStore((store) => store.errors);
    const signup = useWithDispatch(signupAction);
    const resetErrors = useWithDispatch(resetError);

    const validationErrors = useMemo(() => {
        const result: Record<string, string> = {};
        storedErrors.forEach(({ field, message }) => {
            result[field] = message;
        });

        return result;
    }, [storedErrors]);

    const validate = () => {
        let errors = {};

        errors = {
            ...Validator.name(name),
            ...Validator.email(email),
            ...Validator.password(password)
        };

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    const validateOnBlur = (field: string, value: string) => {
        let errors = {};
        switch (field) {
            case 'name':
                errors = { ...Validator.name(value) };
                if (Object.keys(errors).length === 0) errors = { name: null, lastname: null };
                break;
            case 'email':
                errors = { ...Validator.email(value) };
                if (Object.keys(errors).length === 0) errors = { email: null };
                break;
            case 'password':
                errors = { ...Validator.password(value) };
                if (Object.keys(errors).length === 0) errors = { password: null };
                break;
        }

        setErrors((preErrors: ValidationError) => ({ ...preErrors, ...errors }));
    };

    const getCredentials = (fbData?: any) => {
        return fbData
            ? fbData
            : {
                  email,
                  full_name: name,
                  first_name: name.split(' ')[0],
                  last_name: name.split(' ')[1],
                  password
              };
    };
    const trackLdEvent = (event: LdEvent) => {
        if (ldClient) {
            ldClient.track(event);
        }
    };
    const submit = async (fbData?: any) => {
        if (!loading) {
            setLoading(true);
            await signup(getCredentials(fbData), trackLdEvent);
            setLoading(false);
        }
    };

    const signupMarkerClass = 'signup-modal-button';

    useEffect(() => {
        if (!isInitialized.current) {
            resetErrors();
            isInitialized.current = true;
        }
    }, [resetErrors]);

    return (
        <div className="signup-modal">
            <div className="logo" onClick={() => navigate('/')} />
            <Modal.Header closeButton={true}>
                <div className="title">{texts.title}</div>
                <div className="subtitle">{texts.subtitle}</div>
            </Modal.Header>

            <GoogleLogin disabled={loading} onSuccess={submit} markerClass={signupMarkerClass} />

            <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    if (validate()) {
                        submit();
                    }
                }}
            >
                <div className="input-container">
                    <input
                        data-test-id="signup-name"
                        name="name"
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => validateOnBlur('name', e.target.value)}
                        placeholder={texts.name}
                        required
                    />
                    <div className="validation-error">
                        {errors.name ||
                            errors.lastname ||
                            validationErrors.name ||
                            validationErrors.first_name ||
                            validationErrors.last_name}
                    </div>
                </div>

                <div className="input-container">
                    <input
                        data-test-id="signup-email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => validateOnBlur('email', e.target.value)}
                        placeholder={texts.email}
                        required
                    />
                    <div className="validation-error">{errors.email || validationErrors.email}</div>
                </div>

                <div className="input-container">
                    <input
                        data-test-id="signup-password"
                        name="password"
                        type="password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => validateOnBlur('password', e.target.value)}
                        placeholder={texts.password}
                        required
                    />
                    <div className="validation-error">{errors.password || validationErrors.password}</div>
                </div>

                {/*{error && error.message && <div className="validation-error">{error.message}</div>}*/}

                <div className="legal" dangerouslySetInnerHTML={{ __html: texts.terms }} />

                <button
                    id="submit"
                    type="submit"
                    className={`submit-button ${signupMarkerClass}`}
                    data-test-id="signup-button"
                    disabled={!name || !email || !password || loading}
                >
                    {texts.signup}
                </button>
            </form>

            <div className="existing-user-message">
                {texts.account}&nbsp;
                <strong onClick={onSigninClick}>{texts.signin}</strong>
            </div>
            {loading && <Loader />}
        </div>
    );
};
