import React from 'react';
import { Container } from 'react-bootstrap';
import { MessageComponent } from './message-component';
import { Message } from 'store/inbox-service/inbox-types';

export const MessagesComponent: React.FC<{ messages: Message[] }> = ({ messages }) => {
    return (
        <Container className="messages">
            {messages && messages.map((message) => <MessageComponent key={message.sid} message={message} />)}
        </Container>
    );
};
