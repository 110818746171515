import { connect } from 'react-redux';

import { stopLoading } from 'redux/reducers/users/actions';
import { ResetPassword } from './ResetPassword';

const mapStateToProps = ({ users: { error } }) => ({ error });

const mapDispatchToProps = (dispatch) => ({
    resetPasswordFailed: (err) => dispatch(stopLoading(err))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
