const env = process.env;

const conf = {
    appEnv: env.REACT_APP_ENV,
    'api-gw': env.REACT_APP_API_GW_PATH,
    apiroot2: env.REACT_APP_API_GW_PATH + 'proxy/',
    stripeKey: env.REACT_APP_STRIPE,
    mixpanelToken: env.REACT_APP_MIXPANEL_TOKEN,
    launchDarklyClientSideId: env.REACT_APP_LAUNCHDARKLEY_SDK_KEY,
    sentryDsn: env.REACT_APP_SENTRY_DSN,
    googleOAuthClientId: env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    gtmId: env.REACT_APP_GTM_ID,
    chatSdkVersion: env.REACT_APP_CHAT_SDK_VERSION,
    quizSdkVersion: env.REACT_APP_QUIZ_SDK_VERSION,
    appsFlyerClosetUrl: env.REACT_APP_APPSFLYER_CLOSET_URL
};

const Config = {
    get: (prop: keyof typeof conf) => conf[prop]!,
    getEnvironment: (): string => Config.get('appEnv'),
    isProd: () => Config.getEnvironment() === 'production',
    isDev: () => !Config.isProd(),
    // This function is not related to configuration and should be moved to a separate util
    isMobile: () => /android|webos|iphone|ipad/i.test(navigator.userAgent.toLowerCase()) || window.screen.width <= 768
};

export default Config;
