import { FC, useRef, useState } from 'react';

import { Item, ItemDescription, ItemTitle } from './styles';
import { AccordionItemProps } from './types';

export const AccordionItem: FC<AccordionItemProps> = ({ title, description }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const collapsableElementRef = useRef<HTMLDivElement>(null);
    const toggleCollapsed = () => {
        const element = collapsableElementRef.current!;

        if (isCollapsed) {
            element.style.height = 'auto';
            const expandedHeight = element.clientHeight;
            element.style.height = '0px';
            element.clientHeight;
            element.style.height = expandedHeight + 'px';
        } else {
            element.style.height = element.clientHeight + 'px';
            element.clientHeight;
            element.style.height = '0px';
        }

        setIsCollapsed(!isCollapsed);
    };

    const onCollapseTransitionEnd = () => {
        const element = collapsableElementRef.current!;

        if (!isCollapsed) {
            element.style.height = 'auto';
        }
    };

    return (
        <Item>
            <ItemTitle $isCollapsed={isCollapsed} onClick={toggleCollapsed}>
                <div dangerouslySetInnerHTML={{ __html: title }} />
            </ItemTitle>
            <ItemDescription onTransitionEnd={onCollapseTransitionEnd} ref={collapsableElementRef}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
            </ItemDescription>
        </Item>
    );
};
