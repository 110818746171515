import React, { useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useIsLoggedIn } from 'store/auth-service';
import { useGetStylistReviewsQuery } from 'store/stylist-service/stylist-api-slice';
import { Stylist } from 'types/user';

import { Pagination } from 'components';
import { useModal } from 'store/ui';
import { Review } from './Review';

import content from 'content.json';
const { stylist: stylistContent } = content;

interface ReviewsProps {
    stylist: Stylist;
}

export const Reviews: React.FC<ReviewsProps> = ({ stylist }) => {
    const { showModal } = useModal();
    const isLoggedIn = useIsLoggedIn();
    const stylistId = stylist.uuid;
    const REVIEWS_PER_PAGE = 5;
    const reviewsRef = useRef<HTMLDivElement>(null);
    const [pageNum, setPageNum] = React.useState(0);
    const { data: reviews = [] } = useGetStylistReviewsQuery({
        stylistId,
        from: pageNum * REVIEWS_PER_PAGE,
        count: REVIEWS_PER_PAGE
    });
    const canAddReview = isLoggedIn && stylist?.has_session;

    const onPageChange = (page: number) => {
        reviewsRef.current
            ? reviewsRef.current.offsetParent?.scrollIntoView({ behavior: 'smooth' })
            : window.scrollTo({ top: 0, behavior: 'smooth' });
        setPageNum(page);
    };

    const onAddReview = () => {
        showModal('AddReview', { stylist });
    };

    return (
        <Row className="stylist-reviews">
            {canAddReview && (
                <Col>
                    <Button className="add-review-btn" variant="outline-dark" onClick={onAddReview}>
                        {stylistContent.reviews.add}
                    </Button>
                </Col>
            )}

            {reviews.length > 0 && (
                <Col>
                    <div ref={reviewsRef}>
                        <div className="title">
                            {stylistContent.reviews.title.replace('%number%', stylist.reviews_count?.toString() ?? '')}
                        </div>

                        <Container className="reviews">
                            {reviews.map((review, i) => (
                                <Row key={i} className="review-row">
                                    <Col>
                                        <Review review={review} />
                                    </Col>
                                </Row>
                            ))}
                        </Container>

                        {stylist.reviews_count && stylist.reviews_count > 5 && (
                            <Pagination
                                total={stylist.reviews_count}
                                pageSize={REVIEWS_PER_PAGE}
                                onChange={onPageChange}
                                showNumbers={false}
                            />
                        )}
                        <hr />
                    </div>
                </Col>
            )}
        </Row>
    );
};
