import content from 'content.json';
import { FC } from 'react';
import { Row } from 'react-bootstrap';
import { MatchedStylist } from 'store/user-service/user-types';
import { MatchCard } from './MatchCard';
import { useGetFavoriteStylistsQuery } from 'store/user-service/user-api-slice';

export const OtherMatches: FC<{ matches: MatchedStylist[] }> = ({ matches }) => {
    const { data: favoriteStylists = [] } = useGetFavoriteStylistsQuery();
    const isFavoriteStylist = (stylistId: string) => favoriteStylists.find((favorite) => favorite.uuid === stylistId);

    return (
        <>
            <div className="title">{content.stylist.matches}</div>
            <Row className="matches">
                {matches.map((match) => (
                    <MatchCard key={match.uuid} stylist={match} isFavorite={isFavoriteStylist(match.uuid)} />
                ))}
            </Row>
        </>
    );
};
