import styled from 'styled-components';
import { getBreakpoint, getColor, Paragraph, TitleH1, TitleH2 } from 'theme';

import { Section } from './styles';

export const Container = styled(Section)`
    gap: 24px;
    padding-top: 24px;
    color: ${getColor('primaryLight')};
    background-color: ${getColor('primaryDark')};
`;

export const Title = styled(TitleH1)`
    @media screen and (${getBreakpoint('tablet')}) {
        max-width: 320px;
    }
`;

export const Features = styled.div`
    display: flex;
    width: 100%;

    @media screen and (${getBreakpoint('tablet')}) {
        flex-direction: column;
    }
`;

export const FeatureItem = styled.div<{ $image: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: calc(100% / 3);
    height: 640px;
    background:
        linear-gradient(180deg, transparent 0%, ${getColor('primaryDark')} 100%),
        url(${({ $image }) => $image}) center/cover no-repeat;

    @media screen and (${getBreakpoint('desktop')}) {
        height: 340px;
    }

    @media screen and (${getBreakpoint('tablet')}) {
        width: 100%;
    }
`;

export const ItemTitle = styled(TitleH2)`
    font-size: 40px;
    margin-top: 214px;
    @media screen and (${getBreakpoint('desktop')}) {
        margin-top: 110px;
    }
`;
export const Description = styled(Paragraph)`
    max-width: 470px;
    white-space: pre-wrap;
    margin-top: 92px;

    @media screen and (${getBreakpoint('desktop')}) {
        font-size: 16px;
        margin: 78px 18px 35px 18px;
    }
`;
