import './style.scss';
import { FC } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Loader, StylistCard } from 'components';
import { getStylistProfileUrl, getStylistSearchUrl } from 'services/utils/url-utils';
import { useBestMatchesQuery, useGetFavoriteStylistsQuery } from 'store/user-service/user-api-slice';
import { isMatchedStylist, MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { Stylist } from 'types/user';
import { useCommonStore } from 'store/common/reducer';

import content from 'content.json';
const { favorites: texts } = content;

export const FavoriteStylists: FC = () => {
    const navigate = useNavigate();
    const { data: favoriteStylists = [], isFetching: isFetchingStylists } = useGetFavoriteStylistsQuery();
    const { data: stylistsMatchs = [] } = useBestMatchesQuery();
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);

    const onStylistClick = (stylist: Stylist) => {
        const stylistName = `${stylist?.first_name ?? ''} ${stylist?.last_name ?? ''}`;
        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.STYLIST_NAME]: stylistName,
                [MP_PROPS.STYLIST_UUID]: stylist.uuid,
                [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(stylistsMatchs, stylist.uuid),
                [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLISTS_PAGE
            }
        });
        return navigate(
            getStylistProfileUrl(stylist.uuid, {
                bookingSource: { source: 'favorites' }
            })
        );
    };

    return (
        <Row className="stylists">
            {isFetchingStylists && <Loader />}
            {!favoriteStylists.length ? (
                <Col className="empty">
                    <p>{texts.empty}</p>
                    <Button variant="dark" onClick={() => navigate(getStylistSearchUrl())}>
                        {texts.browseStylists}
                    </Button>
                </Col>
            ) : (
                favoriteStylists.map((stylist) => (
                    <Col xs={12} md={4} key={stylist.uuid} className="stylist-item-container">
                        <StylistCard key={stylist.uuid} stylist={stylist} onClick={onStylistClick} isFavorite={true} />
                    </Col>
                ))
            )}
        </Row>
    );
};
