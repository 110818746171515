import './style.scss';

import { Button, Modal } from 'react-bootstrap';

import content from 'content.json';
import { useDispatch } from 'react-redux';
import { removePhoto } from 'redux/reducers/users/actions';
import { useModal } from 'store/ui';

const { modal } = content;

interface RemovePhotoData {
    photoId: string;
}

export const RemovePhoto = () => {
    const dispatch = useDispatch();
    const { data, hideModal } = useModal<RemovePhotoData>();

    const onSubmit = () => {
        dispatch(removePhoto(data.photoId));
        hideModal();
    };

    return (
        <span className="remove-photo">
            <Modal.Header>
                <p>{modal.removePhoto.title}</p>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="light" onClick={hideModal}>
                    {modal.removePhoto.cancel}
                </Button>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.removePhoto.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};
