import { FC } from 'react';
import { ReactComponent as Icon } from 'assets/schedule.svg';
import { TitleH1 } from 'theme';

import { Container, ScheduleButton, StyledSubtitle } from './Schedule-styles';

export const Schedule: FC = () => {
    const schedule = () => {
        window.open('https://calendly.com/ninane-wishi/wishi-consultation');
    };

    return (
        <Container>
            <TitleH1>Not Sure Which Plan Suits You Best?</TitleH1>
            <StyledSubtitle>
                <span>Schedule a free consultation to discuss your style goals. </span>
                <span>We’ll help you understand our plans and find the perfect fit for your needs.</span>
            </StyledSubtitle>
            <ScheduleButton onClick={schedule}>
                <Icon />
                Schedule your free consultation
            </ScheduleButton>
        </Container>
    );
};
