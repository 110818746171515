import AddClosetItem from './AddClosetItem';
import { AddReview } from './AddReview';
import { Contact } from './Contact';
import { DeactivateAccount } from './DeactivateAccount';
import { Error } from './Error';
import { Photo } from './Photo/Photo';
import { RemoveItem } from './RemoveItem';
import { RemoveLook } from './RemoveLook';
import { RemovePhoto } from './RemovePhoto';
import { ResetConfirmation } from './ResetConfirmation';
import ResetPassword from './ResetPassword';
import Signin from './Signin';
import { Signup } from './Signup';
import Tip from './Tip';
import { ReadReview } from './ReadReview';

const components = {
    Signin,
    Signup,
    Unlock: Signup,
    ResetPassword,
    ResetConfirmation,
    AddReview,
    Tip,
    Error,
    Contact,
    RemoveItem,
    RemoveLook,
    RemovePhoto,
    AddClosetItem,
    Photo,
    DeactivateAccount,
    ReadReview
};

export default components;
