import styled from 'styled-components';
import { getBreakpoint, getColor, SecondaryButton, SubTitle } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${getColor('primaryLight')};
`;

export const StyledSubtitle = styled(SubTitle)`
    span {
        display: block;

        @media screen and (${getBreakpoint('mobile')}) {
            display: inline;
        }
    }
`;

export const ScheduleButton = styled(SecondaryButton)`
    margin-top: 40px;
    background: ${getColor('primaryLight')};
    border: none;
`;
