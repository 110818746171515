import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getMatchPageUrl, urlParamToJson } from 'services/utils/url-utils';
import { useCommonStore } from 'store/common/reducer';
import { useGetStyleDataQuery } from 'store/user-service/user-api-slice';
import { SubTitle, TitleH1 } from 'theme';

import { Loader, OurServices, Page, Schedule } from 'components';

import { GoalParams } from '../Goals/types';

import { Container, OurServicesSection, ScheduleSection, Section, StyledPlans } from './MatchPlans-styles';

export const MatchPlans: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const bookingParam = searchParams.get('booking');
    const { stylistId, bookingSource } = urlParamToJson<GoalParams>(bookingParam);
    const { data: userStyleData, isFetching } = useGetStyleDataQuery();
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);

    const recommendedPlan = useMemo(() => {
        const { needs } = userStyleData || {};
        if (needs && (needs.length > 2 || needs.find(({ key }) => key === 'GOAL_CLOSET_CLEANOUT'))) {
            return 'lux';
        }
        return 'major';
    }, [userStyleData]);

    useEffect(() => {
        if (stylistId)
            trackEvent({
                name: MP_EVENTS.PLAN_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });
        klaviyoTrack(KlaviyoEvent.PLAN_VIEW);
    }, [stylistId, mixpanelStore]);

    if (!stylistId) {
        navigate(getMatchPageUrl());
    }

    return (
        <Page>
            {isFetching ? (
                <Loader />
            ) : (
                <Container>
                    <Section>
                        <TitleH1>Meet Our Plans</TitleH1>
                        <SubTitle>Choose The Right Plan for You!</SubTitle>
                        <StyledPlans recommendedPlan={recommendedPlan} availablePlans={['major', 'lux']} />
                    </Section>
                    <OurServicesSection>
                        <OurServices />
                    </OurServicesSection>
                    <ScheduleSection>
                        <Schedule />
                    </ScheduleSection>
                </Container>
            )}
        </Page>
    );
};
