import { FC } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import content from 'content.json';
import { Stylist } from 'types/user';
const {
    stylist: { work: sectionTitle }
} = content;

interface WorkProps {
    stylist: Stylist;
}

export const Work: FC<WorkProps> = ({ stylist: { looks = { data: [] } } }) => (
    <>
        <div className="title">{sectionTitle}</div>
        <Row className="work">
            <Col>
                <Container className="looks no-padding" fluid>
                    <Row>
                        {looks.data &&
                            looks.data.map((look) => (
                                <Col key={look.uuid} className="look">
                                    <div className="look-name">{look.style_tags.join(' ')}</div>
                                    <Image className="look-image" src={look.picture} />
                                </Col>
                            ))}
                    </Row>
                </Container>
            </Col>
        </Row>
    </>
);
