import './style.scss';

import { Image, Modal } from 'react-bootstrap';
import { useModal } from 'store/ui';

interface PhotoModalData {
    imgUrl: string;
}

export const Photo = () => {
    const { data } = useModal<PhotoModalData>();
    return (
        <span className="photo-modal">
            <Modal.Header closeButton />
            <Modal.Body>{data && <Image src={data.imgUrl} />}</Modal.Body>
        </span>
    );
};
