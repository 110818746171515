import { FC, useEffect, useState } from 'react';
import { TitleH1 } from 'theme';

import { testimonials } from '../content';

import { Container, CrossFadeContainer, Cta, Name, Quote, TestimonialItem } from './Testimonials-styles';

export const Testimonials: FC = () => {
    const [itemIndex, setItemIndex] = useState(0);

    useEffect(() => {
        const intervalHandle = setInterval(
            () => setItemIndex((itemIndex) => (itemIndex < testimonials.length - 1 ? itemIndex + 1 : 0)),
            7000
        );

        return () => clearInterval(intervalHandle);
    }, []);

    return (
        <Container>
            <TitleH1>#StyledbyWishi</TitleH1>
            <CrossFadeContainer>
                {testimonials.map(({ quote, name }, index) => (
                    <TestimonialItem key={index} $isShowing={index === itemIndex}>
                        <Quote>
                            <q>{quote}</q>
                        </Quote>
                        <Name>- {name}</Name>
                    </TestimonialItem>
                ))}
            </CrossFadeContainer>
            <Cta trackingElement="lux testimonials">Book a Stylist</Cta>
        </Container>
    );
};
