import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton, TitleH2 } from 'theme';

import { PagingCarousel, SkeletonLoader } from 'components';

import matchIcon from '../assets/match.svg';
import ratingIcon from '../assets/rating.svg';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 88px;
    max-width: 1182px;
    padding: 64px 84px;
    border: 1px solid #f2f4f7;
    margin-top: 40px;
    border-radius: 4px;
    box-shadow:
        218px 42px 133px rgba(0, 0, 0, 0.01),
        97px 19px 99px rgba(0, 0, 0, 0.02),
        24px 5px 54px rgba(0, 0, 0, 0.03);

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: column;
        gap: 24px;
        width: 100%;
        padding: 0 16px;
        border: none;
        box-shadow: none;
        margin-top: 24px;
    }
`;
export const StylistPane = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 477px;
    flex-shrink: 0;

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        align-items: center;
        text-align: center;
        width: auto;
    }
`;
export const BasicInfo = styled(Link)`
    display: flex;
    gap: 12px;
    align-items: center;
    color: inherit;
    max-width: 340px;

    &:hover {
        color: inherit;
    }

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: column;
        max-width: none;
    }
`;
export const Picture = styled.img`
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #e4e7ec;
`;
export const Name = styled(TitleH2)`
    white-space: nowrap;
    font-size: 30px;
`;
export const Location = styled.div`
    text-transform: uppercase;
    margin-top: 5px;
    color: #344054;
`;

export const ContactButton = styled.button`
    position: absolute;
    right: 0;
    top: 30px;
    text-decoration: underline;
    font-weight: 500;
    background: none;
    border: none;
    padding: 0;
    color: inherit;

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        display: none;
    }
`;

export const MobileContactButton = styled(ContactButton)`
    display: none;
    position: static;
    margin-top: 45px;

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        display: block;
    }
`;

export const Stats = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`;
export const Match = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;

    &:before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background: url(${matchIcon}) center no-repeat;
    }
`;

export const Rating = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;

    &:before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background: url(${ratingIcon}) center no-repeat;
    }

    a {
        font-weight: 500;
        text-decoration: underline;
    }
`;

export const AdditionalInfo = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 24px;

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #e4e7ec;
        padding: 16px;
        margin-top: 16px;
    }
`;

export const Why = styled.h3`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
`;

export const Description = styled.p`
    margin-top: 8px;
    line-height: 140%;
`;

export const RecommendationSkeleton = styled(SkeletonLoader)`
    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
        width: 100%;
        height: 16px;
        border-radius: 4px;

        &:first-child {
            width: 125px;
            height: 20px;
            margin-bottom: 4px;
        }
    }
`;

export const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        justify-content: center;
    }
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 29px;
    padding: 0 12px;
    font-size: 15px;
    color: white;
    border-radius: 30px;
    background: #b6a799;
    white-space: nowrap;
`;

export const BottomSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: auto;
    color: #344054;
`;

export const ButtonContainer = styled.div`
    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 12px;
        background: white;
        z-index: 1;
    }
`;

export const ContinueButton = styled(PrimaryButton)`
    width: 100%;
    max-width: 350px;
`;

export const StyleboardsCarousel = styled(PagingCarousel)`
    --navDotColor: black;
    --navDotsPosition: calc(100% + 24px);

    flex-shrink: 0;
    width: 468px;
    border-radius: 4px;
    box-shadow:
        0 21px 8px rgba(0, 0, 0, 0.02),
        0 12px 7px rgba(0, 0, 0, 0.07),
        0 5px 5px rgba(0, 0, 0, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.13);

    img {
        height: 468px;
        object-fit: contain;
    }

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        --navButtonWidth: 0;
        width: auto;

        img {
            height: 340px;
        }
    }
`;
