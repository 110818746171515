import styled from 'styled-components';
import { getColor } from 'theme';

import { Page } from 'components';

export const StyledPage = styled(Page)`
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    background: ${getColor('background')};
    color: ${getColor('primaryDark')};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`;
