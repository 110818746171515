import { useDispatch, useSelector } from 'react-redux';
import {
    selectModalData,
    selectToastData,
    showModal as showModalAction,
    hideModal as hideModalAction,
    showToast as showToastAction,
    hideToast as hideToastAction
} from './ui-slice';
import { ModalsKeys, ToastData } from './ui-slice-types';

export const useToastData = () => useSelector(selectToastData);
export const useModalData = () => useSelector(selectModalData);

export const useModal = <T>() => {
    const dispatch = useDispatch();
    const modalData = useModalData();

    const showModal = (type: ModalsKeys, data = {}) => {
        dispatch(showModalAction({ type, data }));
    };

    const hideModal = () => {
        dispatch(hideModalAction());
    };

    return {
        data: (modalData.data ?? {}) as T,
        type: modalData.type,
        isVisible: modalData.isVisible,
        showModal,
        hideModal
    };
};

export const useToast = () => {
    const dispatch = useDispatch();
    const toastData = useToastData();

    const showToast = (data: ToastData) => {
        dispatch(showToastAction(data));
    };

    const hideToast = () => {
        dispatch(hideToastAction());
    };

    return {
        data: {
            content: toastData.content,
            onUndo: toastData.onUndo
        },
        isVisible: toastData.isVisible,
        showToast,
        hideToast
    };
};
