import { FC } from 'react';
import { TitleH1 } from 'theme';

import { Container, StyledHowItWorksComponent } from './HowItWorks-styles';

export const HowItWorks: FC = () => {
    return (
        <Container>
            <TitleH1>How It Works</TitleH1>
            <StyledHowItWorksComponent />
        </Container>
    );
};
