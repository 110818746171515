import { FC, useEffect } from 'react';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';

import { Page } from 'components';

import { Benefits, Experience, Hero, PricingTable, Slideshow } from './components';

import './GiftCardsLanding.scss';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';

export const GiftCardsLanding: FC = () => {
    useEffect(() => {
        trackEvent({ name: MP_EVENTS.GIFT_CARDS_PAGE_VIEWS });
        klaviyoTrack(KlaviyoEvent.GIFTCARDS_PAGE_VIEW);
    }, []);

    return (
        <Page className="gift-cards-page">
            <Hero />
            <PricingTable />
            <Slideshow />
            <Benefits />
            <Experience />
        </Page>
    );
};
