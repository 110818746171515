import styled from 'styled-components';
import { getColor } from 'theme';

import { HowItWorks } from 'components';

import { Section } from '../components';

export const Container = styled(Section)`
    background-color: ${getColor('primaryLight')};
`;

export const StyledHowItWorksComponent = styled(HowItWorks)`
    margin-top: 40px;
`;
