import { FC } from 'react';
import { TitleH1 } from 'theme';

import { wishiForEveryStage } from '../content';

import { Container, Description, FeatureItem, Features, ItemTitle, Title } from './WishiForEveryStage-styles';

export const WishiForEveryStage: FC = () => {
    return (
        <Container>
            <Title> Wishi Is For Every Stage Of &nbsp;Your Life</Title>
            <Features>
                {wishiForEveryStage.map(({ image, title, description }, index) => (
                    <FeatureItem key={index} $image={image}>
                        <ItemTitle>{title}</ItemTitle>
                        <Description>{description}</Description>
                    </FeatureItem>
                ))}
            </Features>
        </Container>
    );
};
