import { connect } from 'react-redux';

import { toggleMobileCollapse } from 'redux/reducers/payments/actions';
import { loadCampaignFilters, loadCampaignItems } from 'redux/reducers/style/actions';
import _MobileFilters from './MobileFilters';
import Valentine from './Valentine';

const mapStateToProps = ({
    user: { user },
    payments: { mobileCollapse },
    style: { loading, campaignFilters, campaignItems }
}) => ({ loading, user, campaignFilters, campaignItems, mobileCollapse });

const mapDispatchToProps = (dispatch) => ({
    loadCampaignItems: (filters) => dispatch(loadCampaignItems(filters)),
    loadCampaignFilters: () => dispatch(loadCampaignFilters()),
    toggleMobileCollapse: (content) => dispatch(toggleMobileCollapse(content))
});

export default connect(mapStateToProps, mapDispatchToProps)(Valentine);
export const MobileFilters = connect(mapStateToProps, mapDispatchToProps)(_MobileFilters);
