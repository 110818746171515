import { PlanType } from '../types/plans';
import { getColor } from '../theme';

export interface PlanData {
    name: string;
    title: string;
    goal: string;
    color: string;
    price: number;
}

export const plans: Record<PlanType, PlanData> = {
    mini: {
        name: 'Mini',
        title: 'Wishi Mini',
        goal: 'Add new pieces to my closet',
        color: getColor('wishiMini'),
        price: 60
    },
    major: {
        name: 'Major',
        title: 'Wishi Major',
        goal: 'Add new looks for the season',
        color: getColor('wishiMajor'),
        price: 130
    },
    lux: {
        name: 'Lux',
        title: 'Wishi Lux',
        goal: 'Take my wardrobe to the next level',
        color: getColor('wishiLux'),
        price: 550
    }
};
