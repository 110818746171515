import content from 'content.json';
import { FC, useMemo, useState } from 'react';
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stylist as StylistService, Tracking, User } from 'services';

import { isMatchedStylist, MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getChatUrl, getGoalstUrl, urlParamToJson } from 'services/utils/url-utils';
import { useCommonStore } from 'store/common/reducer';

import { ProfilePhoto } from 'components';
import { useGetMessagesQuery } from 'store/inbox-service/inbox-api-slice';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';
import { useModal, useToast } from 'store/ui';
import { StylistParams } from 'routes/Goals/types';
import { Stylist } from 'types/user';

const texts = content.stylist;

interface BookBarProps {
    stylist: Stylist;
    userId?: string;
}

export const BookBar: FC<BookBarProps> = ({ stylist, userId }) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const { showModal } = useModal();
    const [searchParams, _] = useSearchParams();
    const { bookingSource } = urlParamToJson<StylistParams>(searchParams.get('booking'));
    const isActiveStylist = stylist.is_approved === '1' && stylist.is_active;
    const { data: matches = [] } = useBestMatchesQuery();
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const [waitListStatus, setWaitListStatus] = useState<boolean | undefined>(undefined);
    const [waitListActionLoading, setWaitListActionLoading] = useState(false);

    const { data: messages = [] } = useGetMessagesQuery();

    const canBook = useMemo(() => {
        const message = messages.find((msg) => msg.stylist.uuid === stylist.uuid);
        return message?.can_book ?? true;
    }, [messages]);

    const trackWaitListEvent = (joinWaitList: boolean) => {
        trackEvent({
            name: joinWaitList ? MP_EVENTS.JOIN_WAITLIST_CLICK : MP_EVENTS.LEAVE_WAITLIST_CLICK,
            properties: {
                ...mixpanelStore.onboarding,
                ...bookingSourceToProps(bookingSource),
                [MP_PROPS.STYLIST_NAME]: stylist.name,
                [MP_PROPS.STYLIST_UUID]: stylist.uuid,
                [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, stylist.uuid)
            }
        });
    };

    const onWaitListButtonClick = async (joinWaitList: boolean) => {
        if (!userId) {
            showModal('Signup', {
                source: MP_VALUES.STYLIST_PROFILE_PAGE,
                redirectUrl: `${window.location.pathname}${window.location.search}`,
                element: 'stylist join waitlist'
            });
        } else {
            setWaitListActionLoading(true);
            trackWaitListEvent(joinWaitList);

            if (joinWaitList) {
                await StylistService.joinWaitList({ stylistId: stylist.uuid, userId });

                showToast({
                    content: texts.waitListJoinMessage.replace('%name%', stylist.first_name ?? ''),
                    onUndo: () => onWaitListButtonClick(false)
                });
            } else {
                await User.leaveStylistWaitList({ userId, stylistId: stylist.uuid });

                showToast({
                    content: texts.waitListLeaveMessage.replace('%name%', stylist.first_name ?? '')
                });
            }

            setWaitListStatus(joinWaitList);
            setWaitListActionLoading(false);
        }
    };

    if (!isActiveStylist && waitListStatus === undefined && !waitListActionLoading) {
        if (userId) {
            setWaitListActionLoading(true);

            User.getStylistWaitListStatus({ userId })
                .then(({ data = [] }) => {
                    setWaitListStatus(data.includes(stylist.uuid));
                    setWaitListActionLoading(false);
                })
                .catch(() => {
                    setWaitListStatus(false);
                    setWaitListActionLoading(false);
                });
        } else {
            setWaitListStatus(false);
        }
    }

    const getBookBarCta = () => {
        if (isActiveStylist) {
            return canBook ? bookButton : goToSessionButton;
        } else if (waitListStatus !== undefined) {
            return getWaitlistCta(waitListStatus);
        }
        return <></>;
    };

    const onBookClick = async () => {
        trackEvent({
            name: MP_EVENTS.BOOK_STYLIST,
            properties: {
                ...mixpanelStore.onboarding,
                ...bookingSourceToProps(bookingSource)
            }
        });
        const targetUrl = getGoalstUrl({ stylistId: stylist.uuid, bookingSource });

        if (!userId) {
            showModal(searchParams.get('user_rmk') === 'true' ? 'Signin' : 'Signup', {
                redirectUrl: targetUrl,
                source: MP_VALUES.STYLIST_PROFILE_PAGE,
                element: MP_VALUES.BOOK_STYLIST_CLICK
            });
        } else {
            try {
                Tracking.facebook('trackCustom', 'selectStylist', {
                    id: stylist.uuid,
                    content_ids: [stylist.uuid],
                    content_type: 'product'
                });
                Tracking.google({
                    type: 'event',
                    event: 'Funnel',
                    data: { event_category: 'selectStylist' }
                });
                Tracking.google({
                    type: 'event',
                    event: 'conversion',
                    data: { send_to: 'AW-870964131/1rVICOWwnKcBEKO3p58D' }
                });
                Tracking.tag({ event: 'ws.selectStylist' });
            } catch (e) {
                sentryException(e as Error, 'Failed to track stylist booking');
            }

            navigate(targetUrl);
        }
    };

    const onGoToSessionClick = () => {
        navigate(getChatUrl(stylist.uuid, userId!));
    };

    const goToSessionButton = (
        <Button variant="warning" id="go-to-session" onClick={onGoToSessionClick}>
            <span>{texts.session}</span>
        </Button>
    );

    const bookButton = (
        <Button variant="warning" id="book" onClick={onBookClick}>
            <span>{`${texts.book} ${stylist.first_name}`}</span>
        </Button>
    );

    const getWaitlistCta = (inWaitlist: boolean) => (
        <Button
            id={inWaitlist ? 'leave-waitlist' : 'join-waitlist'}
            variant={inWaitlist ? 'light' : 'warning'}
            disabled={waitListActionLoading}
            onClick={() => onWaitListButtonClick(!inWaitlist)}
        >
            {inWaitlist ? texts.leaveWaitList : texts.joinWaitList}
        </Button>
    );

    return (
        <Navbar className="book-bar" fixed="bottom" expand={false}>
            <Container className="no-padding">
                <Nav className="d-none d-md-flex">
                    <Container>
                        <Row>
                            <Col className="profile-image">
                                <ProfilePhoto user={stylist} />
                            </Col>
                            {stylist && (
                                <Col className="stylist-details">
                                    <div className="name">{stylist.name}</div>
                                    <div className="response-time">
                                        {' '}
                                        {texts.response.replace('%time%', stylist.average_response_time.toString())}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Nav>
                <Nav className="justify-content-end booking-btn-container">{getBookBarCta()}</Nav>
            </Container>
        </Navbar>
    );
};
