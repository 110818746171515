import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import content from 'content.json';
import { Formatter, Session as SessionSrv, User } from 'services';
import { MP_PROPS, MP_VALUES, registerProperties } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { useUserId } from 'store/auth-service';
import { useLazyGetBillingPortalUrlQuery } from 'store/payments/paymentsApiSlice';

import { Loader, ProfilePhoto } from 'components';

import Breadcrumbs from './Breadcrumbs';
import { useUser } from 'store/user/reducer';

const texts = content.settings;

interface SessionInfo {
    uuid: string;
    created: string;
    status: string;
    paused: boolean;
    resumes_at: null;
    cancel_at_period_end: boolean;
    period_end: string;
    trial_end: null;
    canceled_at: string;
    stripe_id: string;
    id: null;
    last_paid_at: string | null;
    last4: string | null;
    stylist: {
        name: string;
        profile_picture: string;
    };
    plan: {
        amount: number;
        interval: string;
        name: string;
        interval_count: string;
    };
    payment_method: { last4: string };
}

interface ISession {
    session: SessionInfo;
    active: boolean;
    periodEnd: string;
    onClick: () => void;
}

const Membership: React.FC = () => {
    const user = useUser();
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState<SessionInfo[]>([]);

    useEffect(() => {
        if (user) getSessions();
    }, [user]);

    const getSessions = async () => {
        try {
            setLoading(true);
            const { data } = await User.sessions(user?.user_uuid);
            setSessions(data.items);
            setLoading(false);
        } catch (e: any) {
            setLoading(false);
            sentryException(e as Error, 'Cannot retrieve sessions data');
        }
    };

    const onButtonClick = async (session: SessionInfo, index: number) => {
        try {
            const sessionAction = isActive(session.status) && !session.cancel_at_period_end ? 'cancel' : 'reactivate';
            setLoading(true);
            const { data } = await SessionSrv[sessionAction](session.uuid);
            const items = [...sessions];
            items[index] = data.data;
            registerProperties({
                [MP_PROPS.ACTIVE_SUBSCRIPTION]: sessionAction === 'cancel' ? MP_VALUES.NO : MP_VALUES.YES
            });
            setSessions(items);
            setLoading(false);
        } catch (e: any) {
            setLoading(false);
            sentryException(e as Error, "Couldn't complete session action");
        }
    };

    const isActive = (status: string) => status === 'active' || status === 'trialing';

    const extractPeriodEnd = (periodEnd: string) => {
        const date = periodEnd.split(' ')[0];
        return date.split('-')[1] + ' /' + date.split('-')[2];
    };

    return (
        <Container className="settings-membership" fluid>
            {loading && <Loader />}
            <Row>
                <Col className="header">
                    <Breadcrumbs />
                    <label>{texts.membership.title}</label>
                    {!loading && user && (
                        <p>
                            {!sessions.length
                                ? texts.membership.empty.replace('%name%', user.first_name || '')
                                : texts.membership.description.replace('%name%', user.first_name || '')}
                        </p>
                    )}
                </Col>
            </Row>
            <div className="user-session">
                {sessions.map((session, index) => (
                    <Session
                        key={session.uuid}
                        session={session}
                        active={isActive(session.status)}
                        onClick={() => onButtonClick(session, index)}
                        periodEnd={extractPeriodEnd(session.period_end)}
                    />
                ))}
            </div>
        </Container>
    );
};

const Session: React.FC<ISession> = ({ session, active = true, periodEnd = '', onClick }) => {
    const userId = useUserId()!;
    const [getBillingPortalUrl, { data, isFetching }] = useLazyGetBillingPortalUrlQuery();

    const goToBillingPortal = async () => {
        const { data } = await getBillingPortalUrl({ userId, returnUrl: '/settings/membership' });
        const { url } = data || {};

        url && window.location.replace(url);
    };

    return (
        <Card className="session">
            {(isFetching || data) && <Loader />}
            {!active && !session.cancel_at_period_end && <div className="inactive">{texts.membership.inactive}</div>}
            {session.cancel_at_period_end && <div className="inactive">{texts.membership.periodEnd + periodEnd}</div>}
            <Card.Header>
                <ProfilePhoto user={session.stylist} />
                <p>{session.stylist.name}</p>
            </Card.Header>
            <Card.Body>
                <Container className="session-info" fluid>
                    <Row>
                        <Col>{texts.membership.plan}</Col>
                        <Col>{session.plan.name}</Col>
                    </Row>
                    <Row>
                        <Col>{texts.membership.payment}</Col>
                        <Col>{session.plan.amount ? `$${session.plan.amount}` : 'N/A'}</Col>
                    </Row>
                    <Row>
                        <Col>{texts.membership.date}</Col>
                        <Col>{Formatter.time(session.last_paid_at ?? '', 'MM/DD/YYYY')}</Col>
                    </Row>
                    <Row>
                        <Col>{texts.membership.method}</Col>
                        <Col>
                            {session.status === 'trialing'
                                ? texts.membership.trialing
                                : session.payment_method.last4
                                  ? `*${session.payment_method.last4}`
                                  : 'N/A'}
                            {(active || session.cancel_at_period_end) && (
                                <span className="edit-payment" onClick={goToBillingPortal}>
                                    Edit
                                </span>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            <Card.Footer>
                <Button variant={active && !session.cancel_at_period_end ? 'dark' : 'warning'} onClick={onClick}>
                    {texts.membership[active && !session.cancel_at_period_end ? 'deactivate' : 'reactivate']}
                </Button>
            </Card.Footer>
            {session.status === 'trialing' && <div className="trial">{texts.membership.trial}</div>}
        </Card>
    );
};

export default Membership;
