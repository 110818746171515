import { FC } from 'react';

import { PlansViewProps } from '../types';

import { Container } from './DefaultView-styles';
import { PlanItem } from './PlanItem';

export const DefaultView: FC<PlansViewProps> = ({ plans, className, recommendedPlan }) => {
    return (
        <Container className={className}>
            {plans.map((plan) => (
                <PlanItem key={plan.planId} recommendedPlan={recommendedPlan} plan={plan} />
            ))}
        </Container>
    );
};
