import styled from 'styled-components';
import { getColor } from 'theme';

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: ${getColor('beige')};
`;
