import './style.scss';

import { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

import content from 'content.json';
import { Loader, ProfilePhoto } from 'components';
import Custom from './Custom';
import Select from './Select';
import { useModal } from 'store/ui';

const tipTexts = content.modal.tip;

export const Tip = ({ result, error, loading, pay, clearResult, getPaymentHistory }) => {
    const { data, hideModal } = useModal();
    const stylist = data?.stylist ?? null;
    const channel_sid = stylist?.channel_sid ?? data?.channel_sid ?? null;

    const [state, setState] = useState('select');
    const [tip, setTip] = useState('0');
    const PANELS = {
        select: Select,
        custom: Custom
    };
    useEffect(() => {
        if (result) closeModal();
    }, [result]);

    const onSubmit = async () => {
        switch (state) {
            case 'custom':
                setState('select');
                break;
            case 'select':
                const amount = parseInt(tip);
                if (amount) {
                    const tipData = {
                        stylist,
                        channel_sid
                    };
                    await pay(amount, tipData);
                    getPaymentHistory();
                } else {
                    closeModal();
                }
                break;
            default:
                setState('select');
        }
    };

    const closeModal = () => {
        clearResult();
        hideModal();
    };

    const Component = PANELS[state];

    return (
        <span className="tip">
            {loading && <Loader />}
            <Modal.Header closeButton>
                <ProfilePhoto user={stylist} />
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <p className="title">{tipTexts.title}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text">{tipTexts.text}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Component onChange={state === 'payment' ? onSubmit : setTip} tip={tip} />
                        </Col>
                    </Row>
                    <Row>
                        {state === 'select' && (
                            <Col className="custom-btn" onClick={() => setState('custom')}>
                                {tipTexts.custom}
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col
                            className="error"
                            dangerouslySetInnerHTML={{
                                __html: error && error.payment ? tipTexts.error : ''
                            }}
                        />
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="warning" onClick={onSubmit}>
                    {state === 'custom' ? tipTexts.ok : tipTexts.done}
                </Button>
            </Modal.Footer>
        </span>
    );
};
