import './style.scss';

import { Loader, Masonry } from 'components';
import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { User } from 'services';
import { sentryException } from 'services/SentryLogging';
import { getUserId } from 'services/utils/user-utils';

import content from 'content.json';
import Empty from './Empty';
import { useGridSize } from 'store/profile/hooks';
import { useIsLoggedIn } from 'store/auth-service';
import { useModal } from 'store/ui';

const texts = content.profile;

interface PhotosProps {
    loading: any;
    photos: any;
    error: any;
    loadPhotos: any;
}

const Photos: React.FC<PhotosProps> = ({ loading, photos, error, loadPhotos }) => {
    const { showModal } = useModal();
    const [gridItems, setGridItems] = useState([]);
    const isMobile = useIsMobile();
    const isLoggedIn = useIsLoggedIn();
    const gridSize = useGridSize();

    useEffect(() => {
        if (isLoggedIn) fetchData({});
    }, [isLoggedIn]);

    useEffect(() => {
        if (error && error.profile) {
            showModal('Error', { error: texts.errors.photos });
        }
    }, [error, showModal]);

    useEffect(() => {
        if (!loading && photos) setGridItems(photos);
    }, [photos]);

    const fetchData = (params: any) =>
        loadPhotos({
            from: params ? params.from : photos ? photos.length : 0
        });

    const onPictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const userId = getUserId();
            const data = new FormData();
            e?.target?.files && data.append('picture', e?.target?.files[0]);
            await User.photo({
                user_uuid: userId,
                data
            });
            fetchData({ from: 0 });
        } catch (e) {
            sentryException(e as Error, "Cannot change user's picture");
        }
    };

    const onRemove = (photoId: string) => showModal('RemovePhoto', { photoId });
    const onShow = (imgUrl: string) => showModal('Photo', { imgUrl });
    return (
        <Container className="profile-photos" fluid>
            {loading && <Loader />}
            <Row>
                <Col id="results" className="results">
                    {gridItems.length ? (
                        <Masonry columns={isMobile ? 2 : gridSize === 'small' ? 5 : 3}>
                            {gridItems.map((item: any) => (
                                <PhotoItem
                                    key={item.photo_uuid}
                                    size={gridSize}
                                    item={item}
                                    onRemove={() => onRemove(item.photo_uuid)}
                                    onClick={() => onShow(item.photo_large)}
                                />
                            ))}
                        </Masonry>
                    ) : (
                        <Empty section="photos" />
                    )}

                    <div className="add-btn">
                        {!gridItems.length && (
                            <div className="empty-add-text">
                                <p>{texts.addPhoto}</p>
                                <Image src={texts.addArrow} />
                            </div>
                        )}
                        <label htmlFor="upload-image">+</label>
                        <input
                            type="file"
                            id="upload-image"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={onPictureChange}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const PhotoItem: React.FC<{
    item: any;
    onRemove: () => void;
    onClick: () => void;
    size: string;
}> = ({ item, onRemove, onClick, size }) => (
    <div className={`photo-item ${size}`}>
        <div className="trash" onClick={onRemove} />
        <Image src={item.photo_large} alt="" onClick={onClick} />
    </div>
);
export default Photos;
