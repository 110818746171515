import styled from 'styled-components';
import { getColor } from 'theme';

import { PagingCarousel } from 'components';

import { Section } from '../components';

export const Container = styled(Section)`
    background-color: ${getColor('beige')};
    padding-left: 0;
    padding-right: 0;
`;

export const ReviewsCarousel = styled(PagingCarousel)`
    --slideWidth: auto;
    --navButtonsDistance: -50px;
    --scrollPadding: 32px;
    --navDotsDisplay: none;
    --navButtonWidth: 60px;
    --navButtonsOpaicity: 0;
    --navButtonBackgorundColor: rgba(0, 0, 0, 0.2);

    width: 100%;
    margin-top: 80px;
`;
