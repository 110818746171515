import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string) => {
    const [isMatching, setIsMatching] = useState(matchMedia(`(${query})`).matches);

    useEffect(() => {
        const media = matchMedia(`(${query})`);
        const handler = () => setIsMatching(media.matches);

        media.matches !== isMatching && handler();
        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    }, [isMatching, query]);

    return isMatching;
};
