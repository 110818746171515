import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useIsLoggedIn } from 'store/auth-service';

export const ProtectedRoute: React.FC<{ redirectPath?: string }> = ({ redirectPath }) => {
    const isLoggedIn = useIsLoggedIn();
    const location = useLocation();

    return isLoggedIn ? (
        <Outlet />
    ) : (
        <Navigate
            to={redirectPath ?? `login?target=${location.pathname}${location.search}`}
            replace
            state={{ from: location }}
        />
    );
};
