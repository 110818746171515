import { FC } from 'react';

import { Container } from './SkeletonLoader-styles';

interface SkeletonLoaderProps {
    itemsCount: number;
    className?: string;
}

export const SkeletonLoader: FC<SkeletonLoaderProps> = ({ itemsCount, className }) => {
    const placeholders = [...Array(itemsCount).keys()];

    return (
        <Container className={className}>
            {placeholders.map((key) => (
                <div key={key} />
            ))}
        </Container>
    );
};
