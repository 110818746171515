import { FC, useEffect, useRef, useState } from 'react';
import { getStylistProfileUrl } from 'services/utils/url-utils';
import { useModal } from 'store/ui';
import { StylistId } from 'types/user';

import {
    ClientName,
    Container,
    ContinueReadingButton,
    Date,
    Rating,
    ReviewBody,
    ReviewHeader,
    StylistName
} from './ReviewItem-styles';

interface ReviewItemProps {
    clientName: string;
    stylistUuid: StylistId;
    stylistName: string;
    date: string;
    rate: string;
    description: string;
}

export const ReviewItem: FC<ReviewItemProps> = (props) => {
    const { clientName, stylistUuid, stylistName, date, rate, description } = props;
    const reviewBodyElementRef = useRef<HTMLDivElement>(null);
    const [isLongText, setIsLongText] = useState(false);
    const { showModal } = useModal();

    const continueReading = () => {
        showModal('ReadReview', { review: props });
    };

    useEffect(() => {
        const element = reviewBodyElementRef.current;

        if (element) {
            const maxHeight = element.style.maxHeight;
            const cappedHeight = element.clientHeight;
            element.style.maxHeight = 'none';
            const fullHeight = element.clientHeight;
            element.style.maxHeight = maxHeight;

            if (fullHeight > cappedHeight) {
                setIsLongText(true);
            }
        }
    }, []);

    return (
        <Container>
            <ReviewHeader>
                <div>
                    <ClientName>{clientName}</ClientName>
                    <div>
                        Stylist: <StylistName to={getStylistProfileUrl(stylistUuid)}>{stylistName}</StylistName>
                    </div>
                </div>
                <div>
                    <Date>{date}</Date>
                    <Rating $rating={rate} />
                </div>
            </ReviewHeader>
            <ReviewBody ref={reviewBodyElementRef}>{description}</ReviewBody>
            {isLongText && <ContinueReadingButton onClick={continueReading}>Continue Reading</ContinueReadingButton>}
        </Container>
    );
};
