import { FC } from 'react';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { MP_EVENTS, MP_EXP_VARIANTS, MP_PROPS, registerProperties, trackEvent } from 'services/mixpanel';
import { getOnboardingUrl } from 'services/utils/url-utils';
import { useNewOnboardingFlag } from 'store/features-service/features-hooks';
import { PrimaryButtonLink } from 'theme';

import { ButtonVariantProps } from '../types';

export const OnboardingFlowButton: FC<ButtonVariantProps> = ({
    children,
    className,
    plan,
    trackingSource,
    trackingElement
}) => {
    const isNewOnboarding = useNewOnboardingFlag();
    const onboardingVariant = isNewOnboarding ? MP_EXP_VARIANTS.NEW : MP_EXP_VARIANTS.CONTROL;
    const navPath = getOnboardingUrl();

    const track = () => {
        registerProperties({
            [MP_PROPS.EXP_NEW_ONBOARDING]: onboardingVariant
        });
        trackEvent({
            name: MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: trackingSource,
                [MP_PROPS.ELEMENT]: trackingElement
            }
        });

        plan &&
            klaviyoTrack(KlaviyoEvent.PLAN_CLICK, {
                plan
            });
    };

    return (
        <PrimaryButtonLink to={navPath} state={{ mpSource: trackingSource }} onClick={track} className={className}>
            {children}
        </PrimaryButtonLink>
    );
};
