import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getBreakpoint, getColor, getFont } from 'theme';

import { Accordion, StylingButton } from 'components';

export const Container = styled.div<{ $color: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 424px;
    width: 100%;
    border: 1px solid ${getColor('gray200')};
    border-top: 7px solid ${({ $color }) => $color};
    background: ${getColor('primaryLight')};
    padding: 33px 24px 24px 24px;
    border-radius: 8px;
    box-shadow:
        0 35px 21px rgba(39, 43, 63, 0.01),
        0 20px 18px rgba(39, 43, 63, 0.04),
        0 9px 13px rgba(39, 43, 63, 0.06),
        0 2px 7px rgba(39, 43, 63, 0.07);

    @media screen and (${getBreakpoint('tablet')}) {
        max-width: none;
    }
`;

export const Title = styled.div`
    font-family: ${getFont('title')};
    font-size: 30px;
`;

export const Subtitle = styled.div`
    font-size: 18px;
    margin-top: 12px;
`;

export const BestMatchLabel = styled.div<{ $color: string }>`
    position: absolute;
    right: 0;
    top: 0;
    width: 136px;
    height: 136px;
    overflow: hidden;

    &:after {
        content: 'Best Match';
        position: absolute;
        right: -45px;
        top: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 45px;
        color: ${getColor('primaryLight')};
        background-color: ${({ $color }) => $color};
        transform: rotate(45deg);
    }
`;

export const Price = styled.div`
    font-family: ${getFont('title')};
    font-size: 30px;
    margin-top: 24px;
`;

export const LearnMore = styled(Link)`
    font-weight: 500;
    text-decoration: underline;
    margin: 12px 0 -30px 0;
`;

export const Cta = styled(StylingButton)`
    margin-top: 12px;
    width: 100%;
`;

export const StyledAccordion = styled(Accordion)`
    margin-top: 65px;

    li > div:last-child {
        font-size: 15px;
    }
`;
