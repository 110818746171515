import './style.scss';

import { Button, Modal } from 'react-bootstrap';

import content from 'content.json';
import { useModal } from 'store/ui';

const { modal } = content;

interface ErrorModalData {
    error: string;
}

export const Error = () => {
    const { data, hideModal } = useModal<ErrorModalData>();

    return (
        <span className="photo">
            <Modal.Header closeButton>
                <p>{modal.error.title}</p>
            </Modal.Header>

            <Modal.Body>
                <p>{data && data.error}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="dark" onClick={hideModal}>
                    {modal.error.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};
