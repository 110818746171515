import { Col, Container, Image, Row } from 'react-bootstrap';
import { StylistReview } from 'store/stylist-service/stylist-api-types';
import dayjs from 'dayjs';
import { FC } from 'react';

interface ReviewProps {
    review: StylistReview;
}

export const Review: FC<ReviewProps> = ({ review }) => (
    <Container className="review">
        <Row>
            <Col className="reviewer">
                <div className="profile-image">
                    {review.user?.pictureMedium ? (
                        <Image src={review.user.pictureMedium} />
                    ) : (
                        <div className="default-image">
                            {review.user?.firstName?.[0]}
                            {review.user?.lastName?.[0]}
                        </div>
                    )}
                </div>
                <div className="info">
                    <div className="name">{review.user?.firstName}</div>
                    <div className="date">{dayjs(review.created).format('MMMM YYYY')}</div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="description">
                {review.description}
                {review.picture && <Image src={review.picture} fluid />}
            </Col>
        </Row>
    </Container>
);
