import styled from 'styled-components';
import { getBreakpoint, getColor, TitleH1 } from 'theme';

export const Container = styled.div`
    display: flex;
    gap: 160px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 100px 24px;
    background-color: ${getColor('beige')};

    @media screen and (${getBreakpoint('tablet')}) {
        gap: 64px;
        padding: 40px 24px;
    }

    @media screen and (${getBreakpoint('mobile')}) {
        flex-direction: column;
        gap: 40px;
        padding: 40px 16px;
    }
`;

export const MobileTitle = styled(TitleH1)`
    display: none;

    @media screen and (${getBreakpoint('mobile')}) {
        display: block;
    }
`;

export const VideoContainer = styled.div`
    --videoHeight: 638px;
    --videoWidth: calc(var(--videoHeight) * (9 / 16));
    width: var(--videoWidth);
    height: var(--videoHeight);
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
    box-shadow:
        0 176px 76px rgba(0, 0, 0, 0.02),
        0 99px 64px rgba(0, 0, 0, 0.08),
        0 44px 48px rgba(0, 0, 0, 0.13),
        0 11px 26px rgba(0, 0, 0, 0.15);

    @media screen and (${getBreakpoint('mobile')}) {
        --videoWidth: 100%;
    }

    iframe {
        position: relative;
        border: none;
        width: 100%;
        height: 100%;
    }
`;

export const Text = styled.div`
    max-width: 620px;

    @media screen and (${getBreakpoint('mobile')}) {
        max-width: none;
    }
`;

export const Title = styled(TitleH1)`
    text-align: left;

    @media screen and (${getBreakpoint('mobile')}) {
        display: none;
    }
`;

export const Description = styled.div`
    font-size: 18px;
    line-height: 140%;

    @media screen and (${getBreakpoint('mobile')}) {
        font-size: 15px;
    }
`;
