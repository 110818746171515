export const theme = {
    breakpoints: {
        smallMobile: 'max-width: 576px',
        mobile: 'max-width: 768px',
        tablet: 'max-width: 1024px',
        desktop: 'max-width: 1440px'
    },
    fonts: {
        primary: 'UntitledSans',
        title: 'LinotypeDidotRoman'
    },
    colors: {
        primaryLight: 'white',
        primaryDark: '#161616',
        background: '#F4F4F4',
        beige: '#FFFBF5',
        darkBeige: '#F0EEE4',
        lightBrown: '#72665B',
        error: '#F04438',
        gray100: '#F2F4F7',
        gray200: '#E4E7EC',
        gray300: '#D0D5DD',
        gray400: '#98A2B3',
        gray500: '#667085',
        gray600: '#475467',
        gray700: '#344054',
        gray800: '#1D2939',
        gray900: '#101828',
        wishiMini: '#161616',
        wishiMajor: '#7D1818',
        wishiLux: '#D7B56E'
    }
};

export const getBreakpoint = (breakpoint: keyof typeof theme.breakpoints) => theme.breakpoints[breakpoint];
export const getFont = (font: keyof typeof theme.fonts) => theme.fonts[font];
export const getColor = (color: keyof typeof theme.colors) => theme.colors[color];
