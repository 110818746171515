import React, { useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import content from 'content.json';
import { sentryException } from 'services/SentryLogging';
import { useAddStylistReviewMutation } from 'store/stylist-service/stylist-api-slice';
import { Stylist } from 'types/user';

import { Rating } from 'components';

import './style.scss';
import { useModal } from 'store/ui';
import { useUserId } from 'store/auth-service';

const { modal } = content;

interface AddReviewData {
    stylist: Stylist;
}

export const AddReview = () => {
    const { data: modalData, showModal } = useModal<AddReviewData>();
    const { stylist } = modalData;
    const userId = useUserId();
    const [rating, setRating] = useState({ score: -1 });
    const [picture, setPicture] = useState('');
    const [description, setDescription] = useState('');

    const [addReview, { isLoading }] = useAddStylistReviewMutation();

    const onPictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];
        const reader = new FileReader();
        reader.onload = function () {
            const result = reader.result as string;
            setPicture(result.substring(result.indexOf(',') + 1));
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const onSubmit = async () => {
        try {
            await addReview({
                stylistId: stylist.uuid,
                review: {
                    user_uuid: userId!,
                    rate: rating.score,
                    description,
                    picture
                }
            }).unwrap();
            showModal('Tip', { stylist, channel_sid: stylist?.channel_sid ?? null });
        } catch (e) {
            sentryException(e as Error, "Couldn't submit a review");
        }
    };

    if (!stylist || !userId) {
        return <></>;
    }

    return (
        <span className="add-review">
            {isLoading && <div className="loader" />}
            <Modal.Header closeButton>
                <Image className="profile-picture" src={stylist.profile_picture} />
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <p>{modal.review.question.replace('%stylist%', stylist.first_name ?? '')}</p>
                            <Rating
                                ratings={modal.review.ratings.map((rating) => ({
                                    ...rating,
                                    label: rating.label.replace('%stylist%', stylist.first_name ?? '')
                                }))}
                                onSelect={setRating}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control
                                as="textarea"
                                placeholder={modal.review.placeholder}
                                onChange={(e) => setDescription(e.target.value)}
                                disabled={!(rating.score >= 0)}
                            />
                        </Col>
                    </Row>
                    <Row className="upload-section">
                        {rating.score >= 0 && !picture.length && (
                            <Col className="upload-btn">
                                <label htmlFor="review-image">
                                    <FontAwesomeIcon icon={faCamera} className="d-none d-sm-flex" />
                                    {modal.review.upload}
                                </label>
                                <input type="file" id="review-image" accept="image/jpeg" onChange={onPictureChange} />
                            </Col>
                        )}
                        {picture.length > 0 && (
                            <Col key={picture} className="upload-image">
                                <div className="remove" onClick={() => setPicture('')}>
                                    <span className="close" />
                                </div>
                                <Image src={`data:image/jpeg;base64,${picture}`} fluid />
                            </Col>
                        )}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" disabled={!(rating.score >= 0)} onClick={onSubmit}>
                    {modal.review.submit}
                </Button>
                <p>{modal.review.public}</p>
            </Modal.Footer>
        </span>
    );
};
