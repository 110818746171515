import { FC } from 'react';

import { StyledAccordion } from './FaqAccordion-styles';

const questions = [
    {
        title: 'How does this service work?',
        description:
            'Wishi matches you with a personal stylist that will help you reach your style goals no matter your budget, size, event, etc. Your session will start off with your stylist sending an inspirational mood board to set the tone of the session. From there, your stylist will send over your shoppable style boards where you can buy what you love!'
    },
    {
        title: 'What brands and budgets do you work with?',
        description:
            'The beauty of Wishi is the ability to be styled from any brand or retailer, at any price point, across the internet. Your stylist can curate pieces from your favorite go-to brands while also introducing you to emerging designers and brands you may not have discovered. This personalized approach ensures your wardrobe feels fresh, unique, and perfectly tailored to your style and budget.'
    },
    {
        title: 'Is Wishi a subscription service?',
        description:
            "Wishi is not a box subscription service. However, we do offer one-time  or recurring monthly services where our stylists build looks for you to shop from. You can take a look at what the single or subscription includes <a href='/pricing'>here</a>"
    },
    {
        title: 'What if I don’t like what my stylists suggest?',
        description:
            "In an unlikely event where this happens, you can always email us at <a href='mailto:hello@wishi.me'>hello@wishi.me</a> and we can switch stylists for you! Just let us know who you’d like to work with and we will take care of the rest. If you need help choosing who to book, we can most definitely help with that as well!"
    },
    {
        title: 'Which styling plan should I choose?',
        description:
            'Start with the Major or Lux plan. The Major includes 5 personalized style boards to help build your wardrobe or style for specific occasions. The Lux offers 8 boards, 2 capsules, and a Zoom call with your stylist for a truly personalized experience.'
    },
    {
        title: 'How will the stylist know what I look like?',
        description:
            'Your style preferences help your stylist get to know you and your preferences better. But if you’d like to upload a picture of yourself, that is also helpful as well! You can do so by heading to “Me”>Photos> “+”'
    },
    {
        title: 'Is the Wishi service international?',
        description:
            'Since Wishi is an entirely virtual experience, we can style anyone from anywhere. Wishi stylists know the fit & fabric of every collection from couture to capsule. We’ll help you shop from labels you already love, introduce you to up-and-coming designers, premium denim brands, bespoke suits and bring you vintage pieces you won’t find anywhere else. With Wishi, the whole world is your closet. Just make sure you let your stylist know where you’re based so they style you from retailers available to ship to your region!'
    }
];

interface FaqAccordionProps {
    className?: string;
}

export const FaqAccordion: FC<FaqAccordionProps> = ({ className }) => {
    return <StyledAccordion className={className} items={questions} />;
};
