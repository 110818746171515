import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { ReviewItem } from 'types/utils';

import content from 'content.json';
import { getStylistProfileUrl } from 'services/utils/url-utils';
import { useModal } from 'store/ui';

const { howItWorks } = content;

type parentComponentType = 'reviewCard' | 'reviewModal';

interface ReviewBodyProps {
    review: ReviewItem;
    index?: number;
    type?: parentComponentType;
}

interface INameDate {
    parentType: parentComponentType;
}

const ReviewBody: React.FC<ReviewBodyProps> = ({ review, index = 0, type = 'reviewCard' }) => {
    const isMobile = useIsMobile();
    const { showModal } = useModal();

    const clickOnStylistEvent = (stylistName: string, stylistUuid: string) => {
        trackEvent({
            name: MP_EVENTS.HOW_IT_WORKS_STYLIST_CLICK,
            properties: {
                [MP_PROPS.STYLIST_NAME]: stylistName,
                [MP_PROPS.STYLIST_UUID]: stylistUuid
            }
        });
    };
    const changeModalHeight = (modalClass: string, classToAdd: string) => {
        const modal = document.querySelector(modalClass);
        if (modal) {
            const modalElement = modal as HTMLElement;
            modalElement.classList.add(classToAdd);
        }
    };
    const isTextClamped = (elements: NodeListOf<Element>) => {
        elements.forEach((item: Element) => {
            const elem = item as HTMLElement;
            if (elem.scrollHeight > elem.clientHeight) {
                const readMoreElements = document.querySelectorAll('#readMore' + elem.id);
                readMoreElements.forEach((readMoreItem: Element) => {
                    const readMoreElement = readMoreItem as HTMLElement;
                    if (readMoreElement) {
                        readMoreElement.style.visibility = 'visible';
                    }
                });
            }
        });
    };
    const islargeModalComponent = (type: parentComponentType, isMobile: boolean) => type !== 'reviewCard' && !isMobile;

    const readMoreClick = () => {
        showModal('ReadReview', { review });
    };

    const ReviewDateNameDetails: React.FC = () => (
        <>
            <span className={'reviewDate'}>{review.date}</span>
            <div className={'name-wrapper'}>
                <span className={'clientName'}>{review.clientName}</span>
            </div>
        </>
    );

    const ReviewNameDateDetails: React.FC = () => (
        <>
            <div className={'name-wrapper'}>
                <span className={'clientName'}>{review.clientName}</span>
            </div>
            <span className={'reviewDate'}>{review.date}</span>
        </>
    );

    const NameDateComponent: React.FC<INameDate> = ({ parentType = 'reviewCard' }) =>
        islargeModalComponent(parentType, isMobile) ? <ReviewNameDateDetails /> : <ReviewDateNameDetails />;

    useEffect(() => {
        if (type === 'reviewCard') {
            const elements = document.querySelectorAll('.cardText');
            isTextClamped(elements);
        } else changeModalHeight('.modal-dialog', 'reviewReader-modal');
    }, []);

    const getStylistUrl = getStylistProfileUrl(review.stylistUuid, {
        bookingSource: { source: 'how it works' }
    });

    return (
        <span className={`${type}`}>
            <div className={'client-date-wrapper'}>
                <NameDateComponent parentType={type} />
            </div>
            <span className="stylistName">
                {howItWorks.reviewsSection.stylistPlaceholder}
                <Link
                    className="black"
                    to={getStylistUrl}
                    state={{ from: window.location.pathname }}
                    onClick={() => {
                        clickOnStylistEvent(review.stylistName, review.stylistUuid);
                    }}
                >
                    {review.stylistName}
                </Link>
            </span>
            <span
                className="ratingStar"
                style={{
                    width: parseInt(review.rate) * (type === 'reviewCard' && isMobile ? 28 : 35)
                }}
            ></span>
            <p id={JSON.stringify(index)} className="cardText">
                {review.description}
            </p>
            {type === 'reviewCard' && (
                <span
                    key={index}
                    id={'readMore' + JSON.stringify(index)}
                    onClick={readMoreClick}
                    className="reviewCard readMore"
                >
                    Continue reading
                </span>
            )}
        </span>
    );
};

export default ReviewBody;
