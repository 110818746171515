import { FC, useEffect } from 'react';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';

import { Page } from 'components';

import {
    BuyWhatYouLove,
    Faq,
    Gift,
    Hero,
    Press,
    Schedule,
    StartYourJourney,
    StylingProcess,
    Testimonials,
    WishiForEveryStage
} from './sections';

export const Lux: FC = () => {
    useEffect(() => {
        trackEvent({
            name: MP_EVENTS.LUX_PAGE_VIEWS
        });
    }, []);

    return (
        <Page>
            <Hero />
            <StartYourJourney />
            <StylingProcess />
            <BuyWhatYouLove />
            <Gift />
            <Press />
            <Testimonials />
            <WishiForEveryStage />
            <Schedule />
            <Faq />
        </Page>
    );
};
